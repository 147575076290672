import { httpClient } from "./httpClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
const prefix = "/customer";

export const createCustomer = createAsyncThunk(
  "createCustomer",
  async (values) => {
    return (await httpClient.post(`${prefix}/from-zero`, values)).data;
  }
);

export const getCustomerByUserId = createAsyncThunk(
  "getCustomerByUserId",
  async (id) => {
    return (await httpClient.get(`${prefix}/by-user/${id}`)).data;
  }
);

export const getCustomer = createAsyncThunk("getCustomer", async (id) => {
  return (await httpClient.get(`${prefix}/${id}`)).data;
});

export const getPaginatedCustomer = createAsyncThunk(
  "getPaginatedCustomer",
  async ({ limit, offset }) => {
    return (await httpClient.get(`${prefix}?limit=${limit}&offset=${offset}`))
      .data;
  }
);

export const getPaginatedCustomerByState = createAsyncThunk(
  "getPaginatedCustomerByState",
  async ({ limit, offset, state }) => {
    return (
      await httpClient.post(
        `${prefix}/find-by-state?limit=${limit}&offset=${offset}`,
        state
      )
    ).data;
  }
);

export const getPaginatedCustomerByAny = createAsyncThunk(
  "getPaginatedCustomerByAny",
  async ({ limit, offset, term }) => {
    return (
      await httpClient.post(
        `${prefix}/find-by-any?limit=${limit}&offset=${offset}`,
        term
      )
    ).data;
  }
);

export const updateCustomer = createAsyncThunk(
  "updateCustomer",
  async ({ id, values }) => {
    return (await httpClient.patch(`${prefix}/${id}`, values)).data;
  }
);

export const downloadCustomerExcel = createAsyncThunk(
  "downloadCustomerExcel",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`${prefix}/excel`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "customers.xlsx");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCustomerFromZero = createAsyncThunk(
  "updateCustomerFromZero",
  async ({ id, values }) => {
    return (await httpClient.put(`${prefix}/${id}`, values)).data;
  }
);

export const generateToken = createAsyncThunk(
  "generate-token",
  async (email) => {
    return (await httpClient.post(`${prefix}/generate-token`, email)).data;
  }
);

export const validateToken = createAsyncThunk(
  "validate-token",
  async (token) => {
    return (await httpClient.post(`${prefix}/validate-token`, token)).data;
  }
);

export async function validatePhone(phone) {
  return (await httpClient.get(prefix + "/validate-phone/" + phone)).data;
}

export function useValidatePhone(phone) {
  return useQuery({
    queryKey: ["validatePhone"],
    queryFn: () => validatePhone(phone),
    enabled: false,
  });
}

export async function validateEmail(email) {
  return (await httpClient.get(prefix + "/validate-email/" + email)).data;
}

export function useValidateEmail(email) {
  return useQuery({
    queryKey: ["validateEmail"],
    queryFn: () => validateEmail(email),
    enabled: false,
  });
}

export async function getUsersByname(name) {
  return (await httpClient.get(prefix + "/find-by-name/" + name)).data;
}

export function useGetUserByName(name) {
  return useQuery({
    queryKey: ["getUsersByname"],
    queryFn: () => getUsersByname(name),
    enabled: false,
  });
}

export async function createCustomerModal(values) {
  return (await httpClient.post(`${prefix}/from-zero`, values)).data;
}

export function useCreateCustomerModal() {
  return useMutation({
    mutationKey: ["createCustomerModal"],
    mutationFn: createCustomerModal,
  });
}
