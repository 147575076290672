import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useMutation, useQuery } from "@tanstack/react-query";

const prefix = "/space";

export const getAllSpaces = createAsyncThunk("getAllSpaces", async () => {
  return (await httpClient.get(`${prefix}`)).data;
});

async function readAllQuery() {
  return (await httpClient.get(prefix)).data;
}

export function useFetchAllSpaces() {
  return useQuery({
    queryKey: ["fetchAllSpaces"],
    queryFn: () => readAllQuery(),
    enabled: true,
  });
}
