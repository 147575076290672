import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createPhase } from "../../../services/PhaseService";

const MaterialSchema = Yup.object().shape({
  description: Yup.string().required("La descripción es obligatoria"),
});

const MaterialSelection = () => {
  const { currentProject } = useSelector((state) => state.project);
  const dispatch = useDispatch();

  const updateProject = (values) => {
    dispatch(
      createPhase({
        id_project: currentProject.id,
        name: "Selección de materiales",
        description: values.description,
      })
    );
    window.location.reload();
  };

  return (
    <div className="timeline-body">
      <div className="mb-3">
        <div className="row gx-1" style={{ display: "flex" }}></div>
        <div className="col-10" style={{ display: "flex", width: "100%" }}>
          <h5>Selección de materiales</h5>
        </div>

        <Formik
          initialValues={{
            description: "",
          }}
          validationSchema={MaterialSchema}
          onSubmit={(values, actions) => {
            updateProject(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="mb-2" style={{ marginTop: "2%" }}>
                <Field
                  name="description"
                  as="textarea"
                  // className="form-control"
                  className={`form-control ${
                    errors.description && touched.description
                      ? "border-danger form-control is-invalid"
                      : ""
                  }`}
                  rows="3"
                  placeholder="Coloca los materiales que se van a ocupar"
                />
                {errors.description && touched.description ? (
                  <div style={{ color: "red" }}>{errors.description}</div>
                ) : null}
              </div>

              <div className="row mt-3 justify-content-center">
                <button
                  style={{ maxWidth: "40%" }}
                  type="submit"
                  className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                >
                  Asignar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MaterialSelection;
