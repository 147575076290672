import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getPaginatedByCustomer } from "../../services/ProjectService";
import TabTable from "./table/TabTable";
import DynamicSkeletonTable from "../../components/skeleton/DynamicSkeletonTable";
import RowProject from "./table/RowProject";
import Pagination from "./table/Pagination";
import { getCustomer } from "../../services/CustomerService";

const ProjectCustomer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const { projectList, loading, error, total } = useSelector(
    (state) => state.project
  );
  const { currentCustomer, loading: loadingCustomer } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    dispatch(getCustomer(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      getPaginatedByCustomer({
        limit: 10,
        offset: 0,
        customer_id: { customer_id: id },
      })
    );
  }, [limit, offset, dispatch]);

  const pagesCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;

  const handlePageClick = (pageNum) => {
    setOffset((pageNum - 1) * limit);
  };

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setOffset(offset + limit);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">Proyectos</a>
            </li>
          </ul>
          <h1 className="page-header mb-0">Proyectos</h1>
          <small className="text-muted">
            {currentCustomer?.user?.name} {currentCustomer?.user?.last_name}{" "}
            {currentCustomer?.user?.second_last_name}
          </small>
        </div>
        <div className="ms-auto">
          <Link to="/proyectos/nuevo" className="fw-bold">
            <a
              href="#/"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
              Crear proyecto
            </a>
          </Link>
        </div>
      </div>

      <div className="mb-3 d-md-flex fw-bold">
        <div className="mt-md-0 mt-2">
          <a
            href="#/"
            className="text-dark text-decoration-none"
            // onClick={() => dispatch(downloadProjectExcel())}
          >
            <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Exportar
          </a>
        </div>
      </div>

      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <TabTable disabledTab={true} />

            {loading && loadingCustomer && projectList.length === 0 ? (
              <DynamicSkeletonTable
                columns={[
                  "No",
                  "Nombre",
                  "Correo",
                  "Número de teléfono",
                  "Domicilio",
                  "Contactado por",
                ]}
              />
            ) : (
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Espacio</th>
                      <th>Cliente</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Encargado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectList &&
                      projectList.map((project) => (
                        <RowProject key={project.id} project={project} />
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            <Pagination
              total={total}
              currentPage={currentPage}
              pagesCount={pagesCount}
              handlePageClick={handlePageClick}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCustomer;
