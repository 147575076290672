import { createSlice } from "@reduxjs/toolkit";
import { createMaterials } from "../services/MaterialService";

const initialState = {
  loading: null,
  error: null,
  currentMaterial: null,
};

const materialSlice = createSlice({
  name: "material",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createMaterials.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(createMaterials.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createMaterials.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      });
  },
});

export default materialSlice.reducer;
