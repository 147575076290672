import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomer,
  useValidatePhone,
  validateEmail,
  validatePhone,
} from "../../services/CustomerService.js";
import MapPicker from "../../components/Map/MapPicker.js";
import {
  createContactMedium,
  createCustomerContactMedium,
  getContactMedium,
} from "../../services/ContactMediumService.js";
import toast, { Toaster } from "react-hot-toast";
import LoadingPage from "../LoadingPage.js";

const validationSchema = Yup.object({
  name: Yup.string().required("Coloca tu nombre"),
  last_name: Yup.string(),
  second_last_name: Yup.string(),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Ingresa un numero valdo")
    .required("Coloca tu teléfono"),
  email: Yup.string()
    .email("Correo inválido")
    .required("El email es obligatorio"),
  medioContacto: Yup.string(),
  street: Yup.string(),
  number: Yup.string(),
  neighborhood: Yup.string(),
  postalCode: Yup.number(),
  state: Yup.string(),
});

const CreateCustomer = () => {
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [client, setClient] = useState(false);
  const { contactMediumList } = useSelector((state) => state.contactMedium);
  const { loading, error, createdCustomer } = useSelector(
    (state) => state.customer
  );

  const [loadingCreate, setLoading] = useState(false);

  const [cords, setCords] = useState({ lat: "17.05931", lng: "-96.72709" });
  const [loadingCord, setLoadingCord] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const debounceTimer = useRef(null);

  useEffect(() => {
    dispatch(getContactMedium());
  }, []);

  useEffect(() => {
    if (!loading && !error && createdCustomer) {
      navigate("/clientes");
    }
  }, [loading, error, createdCustomer]);

  const formik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      second_last_name: "",
      email: "",
      phone: "",
      medioContacto: "",
      nuevoMedio: "",
      street: "",
      number: "",
      neighborhood: "",
      postalCode: "",
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      // return;
      const mediodecontacto = {};

      mediodecontacto.id_contact_medium = values.medioContacto;
      if (client) {
        if (values.nuevoMedio === "") {
          return showErrorToast("Coloca el medio de contacto");
        }
      }

      if (client) {
        const response = await dispatch(
          createCustomerContactMedium({
            customerContactName: values.nuevoMedio,
          })
        );
        console.log(response);
        mediodecontacto.id_contact_medium = values.medioContacto;
        mediodecontacto.contactName = response.payload.id;
      }

      if (values.medioContacto === "") {
        delete mediodecontacto.id_contact_medium;
      }

      dispatch(
        createCustomer({
          name: values.name,
          last_name: values.last_name,
          second_last_name: values.second_last_name,
          email: values.email,
          // address: values.address,
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          postalCode: values.postalCode,
          state: values.state,

          rol: "Cliente",
          phone: values.phone,
          latitude: Number(cords.lat),
          longitude: Number(cords.lng),
          ...mediodecontacto,
        })
      );

      setLoading(false);
    },
  });

  const showErrorToast = (message) => {
    toast.error(<b>{message}</b>, {
      duration: 4000,
      position: "top-right",
      icon: "⚠️",
    });
  };

  async function fetchCoordinatesFromGeocode(address) {
    const api_key = process.env.REACT_APP_API_MAPS;
    setLoadingCord(true);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${api_key}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        console.error(
          "Error:",
          data.error_message || "Unknown error from Google Maps API"
        );
        setCords({ lat: "17.05931", lng: "-96.72709" });
        return showErrorToast("Ocurrió un error al obtener la ubicación");
      }

      if (!data.results || data.results.length === 0) {
        console.error("No results found");
        setCords({ lat: "17.05931", lng: "-96.72709" });
        return showErrorToast("No se encontró la ubicación");
      }

      const location = data.results[0].geometry.location;
      console.log(location);

      return {
        lat: location.lat,
        lng: location.lng,
      };
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setCords({ lat: "17.05931", lng: "-96.72709" });
      return showErrorToast("Ocurrió un error al obtener la ubicación");
    } finally {
      setLoadingCord(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const newAddress = `${formik.values.street} ${formik.values.number}, ${formik.values.neighborhood}, ${formik.values.postalCode}, ${formik.values.state}`;

    setFullAddress(newAddress);

    if (
      formik.values.street &&
      formik.values.number &&
      formik.values.neighborhood &&
      formik.values.postalCode &&
      formik.values.state
    ) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      console.log(fullAddress);
      debounceTimer.current = setTimeout(async () => {
        const coords = await fetchCoordinatesFromGeocode(newAddress);
        if (coords) {
          setCords(coords);
          console.log(coords);
        }
      }, 500);
    }
  };

  const handlePhoneChange = async (e) => {
    formik.handleChange(e);
    setErrorPhone(false);
    if (e.target.value.length >= 10) {
      try {
        const response = await validatePhone(e.target.value);
      } catch (error) {
        console.log(error);
        setErrorPhone(error.message);
      }
    }
  };

  const handleEmailChange = async (e) => {
    formik.handleChange(e);
    setErrorEmail(false);
    if (/^\S+@\S+\.\S+$/.test(e.target.value)) {
      try {
        const response = await validateEmail(e.target.value);
      } catch (error) {
        console.log(error);
        setErrorEmail(error.message);
      }
    }
  };

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: "91vh" }}>
      {loadingCreate && <LoadingPage />}
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/clientes">Clientes</Link>
          </li>
          <li className="breadcrumb-item active">Nuevo</li>
        </ol>
        <h1 className="page-header">Registrar un nuevo cliente</h1>
        <Panel>
          <PanelHeader>Formulario de registro</PanelHeader>
          <PanelBody>
            <h6>
              Rellena el siguiente formulario para poder registrar al nuevo
              cliente
            </h6>
          </PanelBody>
          <div className="row" style={{ margin: "1%" }}>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label">
                  Nombre completo :
                </label>
                <div className="col-lg-3">
                  <input
                    className={`form-control ${
                      formik.touched.name && formik.errors.name
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Nombre completo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>
                <div className="col-lg-3">
                  <input
                    className={`form-control ${
                      formik.touched.last_name && formik.errors.last_name
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Apellido paterno"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="text-danger">{formik.errors.last_name}</div>
                  ) : null}
                </div>
                <div className="col-lg-3">
                  <input
                    className={`form-control ${
                      formik.touched.second_last_name &&
                      formik.errors.second_last_name
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="second_last_name"
                    name="second_last_name"
                    placeholder="Apellido materno"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.second_last_name}
                  />
                  {formik.touched.second_last_name &&
                  formik.errors.second_last_name ? (
                    <div className="text-danger">
                      {formik.errors.second_last_name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label">
                  Correo electrónico :
                </label>
                <div className="col-lg-9 ">
                  <input
                    className={`form-control ${
                      (formik.touched.email && formik.errors.email) ||
                      errorEmail
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Correo electrónico"
                    // onChange={formik.handleChange}
                    onChange={handleEmailChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                  {errorEmail ? (
                    <div className="text-danger">{errorEmail}</div>
                  ) : null}
                </div>
              </div>
              <Toaster />

              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label">
                  Número de teléfono :
                </label>
                <div className="col-lg-9">
                  <input
                    className={`form-control ${
                      (formik.touched.phone && formik.errors.phone) ||
                      errorPhone
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="phone"
                    maxLength="10"
                    name="phone"
                    placeholder="Número de teléfono. ej 951213987"
                    onChange={handlePhoneChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger">{formik.errors.phone}</div>
                  ) : null}
                  {errorPhone ? (
                    <div className="text-danger">{errorPhone}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label">
                  ¿Cómo nos contactó?
                </label>
                <div className="col-lg-9">
                  <select
                    className={`form-control ${
                      formik.touched.medioContacto &&
                      formik.errors.medioContacto
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    id="medioContacto"
                    name="medioContacto"
                    onChange={(e) => {
                      formik.handleChange(e);
                      const selectedIndex = e.target.selectedIndex;
                      const value = e.target.options[selectedIndex].text;

                      if (value === "Otro cliente") {
                        setClient(true);
                      } else {
                        setClient(false);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.medioContacto}
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>
                    {contactMediumList &&
                      contactMediumList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.medioContacto &&
                  formik.errors.medioContacto ? (
                    <div className="text-danger">
                      {formik.errors.medioContacto}
                    </div>
                  ) : null}

                  {client && (
                    <div className="form-group row mt-4">
                      <label className="col-lg-4 col-form-label form-label">
                        Nombre del cliente:
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className={`form-control `}
                          id="nuevoMedio"
                          name="nuevoMedio"
                          placeholder="Introduce el nombre del cliente"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.nuevoMedio}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label">
                  Ubicación :
                </label>
                <div className="col-lg-4">
                  <input
                    className={`form-control ${
                      formik.touched.street && formik.errors.street
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="street"
                    name="street"
                    placeholder="Calle de la libertad"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);

                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                  />
                  {formik.touched.street && formik.errors.street ? (
                    <div className="text-danger">{formik.errors.street}</div>
                  ) : null}
                </div>

                <div className="col-lg-3">
                  <input
                    className={`form-control ${
                      formik.touched.number && formik.errors.number
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="number"
                    name="number"
                    placeholder="Número 982"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <div className="text-danger">{formik.errors.number}</div>
                  ) : null}
                </div>

                <div className="col-lg-2">
                  <input
                    className={`form-control ${
                      formik.touched.postalCode && formik.errors.postalCode
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Codigo postal"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);

                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.postalCode}
                  />
                  {formik.touched.postalCode && formik.errors.postalCode ? (
                    <div className="text-danger">
                      {formik.errors.postalCode}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-3">
                <label className="col-lg-3 col-form-label form-label"></label>

                <div className="col-lg-5">
                  <input
                    className={`form-control ${
                      formik.touched.neighborhood && formik.errors.neighborhood
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="neighborhood"
                    name="neighborhood"
                    placeholder="Colonia"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);

                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.neighborhood}
                  />
                  {formik.touched.neighborhood && formik.errors.neighborhood ? (
                    <div className="text-danger">
                      {formik.errors.neighborhood}
                    </div>
                  ) : null}
                </div>

                <div className="col-lg-4">
                  <input
                    className={`form-control ${
                      formik.touched.state && formik.errors.state
                        ? " border-danger form-control is-invalid"
                        : ""
                    }`}
                    type="text"
                    id="state"
                    name="state"
                    placeholder="Municipio, ej: Xoxocotlan, Santa Cruz Amilpas"
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);

                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger">{formik.errors.state}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label form-label"></label>
                <div className="col-lg-9">
                  {cords && (
                    <MapPicker onChange={setCords} initialPosition={cords} />
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label form-label">
                  &nbsp;
                </label>
                <div className="col-lg-9 d-flex justify-content-between">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={() => fetchCoordinatesFromGeocode()}
                  >
                    Buscar domicilio
                  </button>
                  <span></span>
                  {/* <Link to="/agendar-cita"> */}

                  <button
                    className="btn btn-success"
                    type="submit"
                    disabled={
                      loadingCord ||
                      loading ||
                      !cords ||
                      errorPhone ||
                      errorEmail
                    }
                  >
                    Guardar
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </form>
          </div>
        </Panel>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; Karimnot Todos los derechos reservados 2023
      </div>
    </div>
  );
};

export default CreateCustomer;
