import { Radio, RadioGroup } from "@mui/material";

const RadioField = ({ name, value, onChange, error, options, label, big }) => {
  return (
    <>
      <label
        className="col-lg-2 col-form-label form-label textoresponsive "
        
      >
        {label}
      </label>
      <div className={`col-lg-${big ? "10" : "4"} d-flex flex-column`}>
        <RadioGroup
          name={name}
          value={value}
          onChange={onChange}
          className={`ml-2 ${error ? " border-danger is-invalid" : ""}`}
        >
          <div>
            {options.map((option) => (
              <>
                <Radio value={option.value} /> {option.label}
              </>
            ))}
          </div>
        </RadioGroup>
        {error && <div className="text-danger">{error}</div>}
      </div>
    </>
  );
};

export default RadioField;
