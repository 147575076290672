import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { useMutation, useQuery } from "@tanstack/react-query";

const prefix = "/project";

export const createProjectFromZero = createAsyncThunk(
  "createprojectFromZero",
  async (values) => {
    return (await httpClient.post(`${prefix}/zero`, values)).data;
  }
);

export const getPaginatedProject = createAsyncThunk(
  "getPaginatedProject",
  async ({ limit, offset }) => {
    return (await httpClient.get(`${prefix}?limit=${limit}&offset=${offset}`))
      .data;
  }
);

export const getProjectById = createAsyncThunk("getProjectById", async (id) => {
  return (await httpClient.get(`${prefix}/${id}`)).data;
});

export const updateProject = createAsyncThunk(
  "updateProject",
  async ({ id, body }) => {
    return (await httpClient.patch(`${prefix}/${id}`, body)).data;
  }
);

export const getPaginatedByCustomer = createAsyncThunk(
  "getPaginatedByCustomer",
  async ({ limit, offset, customer_id }) => {
    return (
      await httpClient.post(
        `${prefix}/find-by-customer?limit=${limit}&offset=${offset}`,
        customer_id
      )
    ).data;
  }
);

export const getPaginatedProjectsByAny = createAsyncThunk(
  "getPaginatedProjectsByAny",
  async ({ limit, offset, term }) => {
    return (
      await httpClient.post(
        `${prefix}/find-by-any?limit=${limit}&offset=${offset}`,
        term
      )
    ).data;
  }
);

export const downloadProjectExcel = createAsyncThunk(
  "downloadProjectExcel",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(`${prefix}/excel`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "projects.xlsx");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

async function readOneQuery(id) {
  return (await httpClient.get(prefix + "/" + id)).data;
}

export function useGetProject(id) {
  return useQuery({
    queryKey: ["fetchProject"],
    queryFn: () => readOneQuery(id),
    enabled: true,
  });
}
