import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

const prefix = "/user";

export const createUser = createAsyncThunk("createUser", async (values) => {
  return (await httpClient.post(`${prefix}`, values)).data;
});

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, values }) => {
    return (await httpClient.patch(`${prefix}/${id}`, values)).data;
  }
);
