import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";

const Requisitos = () => {
  const [espacios, setEspacios] = useState([
    {
      requerimientos: [""],
    },
  ]);

  const handleAddRequerimiento = (index) => {
    const newEspacios = [...espacios];
    newEspacios[index].requerimientos.push("");
    setEspacios(newEspacios);
  };

  const handleRemoveRequerimiento = (espacioIndex, reqIndex) => {
    const newEspacios = [...espacios];
    newEspacios[espacioIndex].requerimientos.splice(reqIndex, 1);
    setEspacios(newEspacios);
  };

  const handleAddEspacio = () => {
    setEspacios([...espacios, { requerimientos: [""] }]);
  };

  const handleRemoveEspacio = (index) => {
    const newEspacios = [...espacios];
    newEspacios.splice(index, 1);
    setEspacios(newEspacios);
  };

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: "92vh" }}>
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Nuevo proyecto</Link>
          </li>
        </ol>
        <h1 className="page-header">Nuevo proyecto</h1>
        <Panel>
          <PanelHeader>Cuentanos que ideas tienes en mente</PanelHeader>
          <PanelBody>
            <div className="row">
              <div id="formContainer">
                {espacios.map((espacio, espacioIndex) => (
                  <div key={espacioIndex}>
                    <div className="form-group row mb-3">
                      <label className="col-lg-4 col-form-label form-label">
                        Espacio :
                      </label>
                      <div className="col-lg-8 d-flex">
                        {espacioIndex === 0 ? (
                          <button
                            className="btn btn-success mr-2"
                            onClick={handleAddEspacio}
                            style={{ marginRight: "2%" }}
                          >
                            Nuevo
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger mr-2"
                            style={{ marginRight: "2%" }}
                            onClick={() => handleRemoveEspacio(espacioIndex)}
                          >
                            Eliminar
                          </button>
                        )}
                        <select
                          className="form-select ml-2"
                          style={{ marginRight: "2%" }}
                          name="selectBox"
                        >
                          <option value="" selected disabled>
                            Seleccione un espacio
                          </option>
                          <option value="foo">Cocina</option>
                          <option value="foo">Comedor</option>
                          <option value="foo">Dormitorio</option>
                          <option value="foo">Sala</option>
                          <option value="foo">Cochera</option>
                        </select>
                      </div>
                    </div>

                    {espacio.requerimientos.map((requerimiento, reqIndex) => (
                      <div className="form-group row mb-3" key={reqIndex}>
                        <label className="col-lg-4 col-form-label form-label">
                          Requerimientos * :
                        </label>
                        <div className="col-lg-8 d-flex">
                          {reqIndex === 0 ? (
                            <button
                              className="btn btn-success mr-2"
                              style={{ marginRight: "2%" }}
                              onClick={() =>
                                handleAddRequerimiento(espacioIndex)
                              }
                            >
                              +
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger mr-2"
                              style={{ marginRight: "2%" }}
                              onClick={() =>
                                handleRemoveRequerimiento(
                                  espacioIndex,
                                  reqIndex
                                )
                              }
                            >
                              -
                            </button>
                          )}
                          <input
                            className="form-control ml-2"
                            type="text"
                            placeholder="Nuevo requerimiento"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="theme-panel-divider" />
              <div className="form-group row mb-3">
                <label
                  className="col-lg-4 col-form-label form-label"
                  htmlFor="message"
                >
                  Informacion adicional
                </label>
                <div className="col-lg-8">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows={3}
                    data-parsley-minlength={20}
                    data-parsley-maxlength={100}
                    placeholder="Algun dato importante que debamos conocer? ej: Solo se puede entrar caminando"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label form-label">
                  &nbsp;
                </label>
                <div className="col-lg-8 d-flex justify-content-between">
                  <label className="col-lg-4 col-form-label form-label">
                    &nbsp;
                  </label>
                  <Link to="/mi-proyecto">
                    <button className="btn btn-success">Enviar</button>
                  </Link>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; Karimnot Todos los derechos reservados 2023
      </div>
    </div>
  );
};

export default Requisitos;
