import { createSlice } from "@reduxjs/toolkit";
import {
  createCustomer,
  downloadCustomerExcel,
  generateToken,
  getCustomer,
  getCustomerByUserId,
  getPaginatedCustomer,
  getPaginatedCustomerByAny,
  getPaginatedCustomerByState,
  updateCustomer,
  updateCustomerFromZero,
  validateToken,
} from "../services/CustomerService";
import { addNotification } from "../components/Notification/Notification";

const initialState = {
  customerList: [],
  total: 0,
  currentCustomer: null,
  loading: true,
  error: false,
  createdCustomer: null, // Nuevo campo.
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      state.currentCustomer = action.payload;
    },
    setCreatedCustomer: (state) => {
      state.createdCustomer = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.rejected, (state, action) => {
        addNotification(
          "warning",
          "Error al crear el cliente",
          action.error.message,
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.createdCustomer = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        addNotification(
          "success",
          "Cliente creado ",
          "Ya hemos enviado un correo al cliente para que acomple su registro",
          "top-right"
        );
        state.createdCustomer = action.payload;
      });

    builder
      .addCase(getCustomer.rejected, (state, action) => {
        addNotification(
          "warning",
          "Error al recuperar los datos",
          action.error.message,
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(getCustomer.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
        state.loading = false;
        state.error = false;
      });
    builder
      .addCase(getCustomerByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getCustomerByUserId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCustomerByUserId.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
        state.loading = false;
        state.error = false;
      });

    builder
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        addNotification(
          "success",

          "Datos actualizados ✅",
          "Hemos actualizado tus datos",
          "top-right"
        );

        state.currentCustomer = action.payload;
        state.loading = false;
        state.error = false;
      });

    builder
      .addCase(getPaginatedCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdCustomer = null;
      })
      .addCase(getPaginatedCustomer.fulfilled, (state, action) => {
        state.customerList = action.payload.data;
        state.total = action.payload.total;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPaginatedCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getPaginatedCustomerByState.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdCustomer = null;
      })
      .addCase(getPaginatedCustomerByState.fulfilled, (state, action) => {
        state.customerList = action.payload.data;
        state.total = action.payload.total;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPaginatedCustomerByState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getPaginatedCustomerByAny.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdCustomer = null;
      })
      .addCase(getPaginatedCustomerByAny.fulfilled, (state, action) => {
        state.customerList = action.payload.data;
        state.total = action.payload.total;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPaginatedCustomerByAny.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(downloadCustomerExcel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadCustomerExcel.fulfilled, (state) => {
        state.loading = false;
        addNotification(
          "success",
          "Excel descargado ✅",
          "Hemos descargado el Excel con éxito",
          "top-right"
        );
      })
      .addCase(downloadCustomerExcel.rejected, (state, action) => {
        state.loading = false;
        addNotification(
          "error",
          "Error al descargar",
          action.error.message,
          "top-right"
        );
      });

    builder
      .addCase(updateCustomerFromZero.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateCustomerFromZero.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateCustomerFromZero.fulfilled, (state, action) => {
        addNotification(
          "success",

          "Datos actualizados ✅",
          "Hemos actualizado tus datos",
          "top-right"
        );
        state.loading = false;
        state.error = false;
        state.currentCustomer = action.payload;
        state.createdCustomer = action.payload;
      });
  },
});

export default customerSlice.reducer;

export const { setCustomer, setCreatedCustomer } = customerSlice.actions;
