import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerByUserId } from "../../services/CustomerService";

const GetCustomerByUser = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getCustomerByUserId(id));
  }, []);

  return <div>Cargando ...</div>;
};

export default GetCustomerByUser;
