import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpaces } from "../../../services/SpacesService";

const Requirements = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { spacesName } = useSelector((state) => state.spacesName);

  const [espacios, setEspacios] = useState([
    {
      requirement: "",
    },
  ]);
  const [isOtroSelected, setIsOtroSelected] = useState([false]);

  const [otrosValores, setOtrosValores] = useState([]);

  const [espaciosValores, setEspaciosValores] = useState([
    {
      space: "",
      requirement: "",
    },
  ]);

  const handleAddEspacio = () => {
    setEspacios([...espacios, { requirement: "" }]);
    setIsOtroSelected([...isOtroSelected, false]);
    setEspaciosValores([...espaciosValores, { space: "", requirement: "" }]);
    setOtrosValores([...otrosValores, ""]);
  };

  const handleRemoveEspacio = (index) => {
    const newEspacios = [...espacios];
    newEspacios.splice(index, 1);
    setEspacios(newEspacios);

    const newIsOtroSelected = [...isOtroSelected];
    newIsOtroSelected.splice(index, 1);
    setIsOtroSelected(newIsOtroSelected);

    const newEspaciosValores = [...espaciosValores];
    newEspaciosValores.splice(index, 1);
    setEspaciosValores(newEspaciosValores);

    const newOtrosValores = [...otrosValores];
    newOtrosValores.splice(index, 1);
    setOtrosValores(newOtrosValores);
  };

  const handleSelectChange = (index, e) => {
    const newIsOtroSelected = [...isOtroSelected];
    if (e.target.value === "otro") {
      newIsOtroSelected[index] = true;
    } else {
      newIsOtroSelected[index] = false;
    }
    setIsOtroSelected(newIsOtroSelected);
  };

  const handleRequerimientoChange = (espacioIndex, e) => {
    const newEspaciosValores = [...espaciosValores];

    if (!newEspaciosValores[espacioIndex]) {
      newEspaciosValores[espacioIndex] = { space: "", requirement: "" };
    }

    newEspaciosValores[espacioIndex].requirement = e.target.value;

    setEspaciosValores(newEspaciosValores);
  };

  const handleSend = () => {
    const isValid = espaciosValores.every((espacioVal) => {
      // Verifica si el space está definido y no está vacío
      const validEspacio = espacioVal.space && espacioVal.space.trim() !== "";

      // Verifica si el requerimiento está definido y no está vacío
      const validRequerimiento =
        espacioVal.requirement && espacioVal.requirement.trim() !== "";

      return validEspacio && validRequerimiento;
    });

    const output = espaciosValores.map((espacioVal, index) => ({
      space: isOtroSelected[index] ? otrosValores[index] : espacioVal.space,
      new_space: isOtroSelected[index],
      requirement: espacioVal.requirement,
    }));

    return isValid ? output : false;
  };

  useImperativeHandle(ref, () => ({
    validate: handleSend,
  }));

  useEffect(() => {
    dispatch(getAllSpaces());
  }, []);

  return (
    <div className="mt-4">
      <div>
        <h4 className="mb-2">Requisitos del proyecto</h4>
        <em>Selecciona el espacio e indica tus requisitos </em>
        <div className="row gx-1 mt-3">
          <div className="row">
            <div id="formContainer">
              {espacios.map((space, espacioIndex) => (
                <div
                  key={espacioIndex}
                  style={{
                    backgroundColor:
                      espacioIndex % 2 === 0
                        ? "white"
                        : "rgb(230 230 230 / 46%)",
                    padding: "20px",
                    border: "1px solid #0000002e",
                    margin: "5px",
                  }}
                >
                  <div className="d-flex">
                    <div className="col-lg-11">
                      <div className="form-group row mb-3">
                        <label
                          className="col-lg-2 col-form-label form-label textoresponsive"
                          
                        >
                          Espacio:
                        </label>
                        <div className="col-lg-10 d-flex">
                          <select
                            className="form-select ml-2"
                            value={espaciosValores[espacioIndex]?.space || ""}
                            name="selectBox"
                            onChange={(e) => {
                              handleSelectChange(espacioIndex, e);
                              const updatedEspaciosValores = [
                                ...espaciosValores,
                              ];
                              updatedEspaciosValores[espacioIndex] = {
                                ...updatedEspaciosValores[espacioIndex],
                                space: e.target.value,
                              };
                              setEspaciosValores(updatedEspaciosValores);
                            }}
                          >
                            <option value="" selected disabled>
                              Seleccione un espacio
                            </option>

                            {spacesName &&
                              spacesName.map((space, index) => (
                                <option value={space.name}>{space.name}</option>
                              ))}
                            <option value="otro">Otro espacio</option>
                          </select>

                          {isOtroSelected[espacioIndex] && (
                            <input
                              style={{ marginLeft: "2%" }}
                              className="form-control ml-2"
                              type="text"
                              value={otrosValores[espacioIndex] || ""}
                              onChange={(e) => {
                                const updatedOtros = [...otrosValores];
                                updatedOtros[espacioIndex] = e.target.value;
                                setOtrosValores(updatedOtros);
                              }}
                              placeholder="¿Qué espacio?"
                            />
                          )}
                        </div>
                      </div>

                      <div className="form-group row mb-3">
                        <label
                          className="col-lg-2 col-form-label form-label textoresponsive"
                          
                        >
                          Observaciones:{" "}
                        </label>
                        <div className="col-lg-10 d-flex">
                          <textarea
                            className="form-control ml-2"
                            type="text"
                            value={
                              espaciosValores[espacioIndex]?.requirement || ""
                            }
                            onChange={(e) => {
                              handleRequerimientoChange(espacioIndex, e);
                            }}
                            placeholder="Observaciones"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-1 d-flex align-items-center justify-content-center">
                      {espacioIndex === 0 ? (
                        <Tooltip title="Nuevo espacio">
                          <IconButton
                            size="large"
                            onClick={handleAddEspacio}
                            color="success"
                          >
                            <AddIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Eliminar espacio">
                          <IconButton
                            size="large"
                            onClick={() => handleRemoveEspacio(espacioIndex)}
                            color="error"
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="theme-panel-divider" />

            <div className="form-group row">
              <label className="col-lg-4 col-form-label form-label">
                &nbsp;
              </label>
              <div className="col-lg-8 d-flex justify-content-between">
                <label className="col-lg-4 col-form-label form-label">
                  &nbsp;
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Requirements;
