import { createTheme, ThemeProvider } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            // borderColor: "#998d8d", // Color rojo cuando pasas el mouse por encima
          },
        },
        notchedOutline: {},
        disabled: {},
        focused: {},
        error: {},
      },
    },
  },
});
