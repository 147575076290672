import React, { useEffect } from "react";
import "photoswipe/style.css";
import "lity";
import "lity/dist/lity.min.css";
import NewProject from "./tabs/NewProject";
import HeaderCustomer from "../CustomerDashboard/header/HeaderCustomer.js";
import { useDispatch } from "react-redux";
import { setCreatedCustomer } from "../../features/customerSlice";

function CustomerDashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCreatedCustomer());
  }, []);

  return (
    <div>
      <HeaderCustomer active={"NUEVO"} />
      <div
        className="profile-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="tab-content p-0" style={{ width: "80%" }}>
          <NewProject />
        </div>
      </div>
    </div>
  );
}

export default CustomerDashboard;
