import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { addNotification } from "../components/Notification/Notification";
import { getAllSpaces } from "../services/SpacesService";

const initialState = {
  spacesName: [],
  loading: true,
  error: false,
};

const spaceNameSlice = createSlice({
  name: "spaceName",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSpaces.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllSpaces.rejected, (state, action) => {
        state.error = true;
        state.loading = false;

        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getAllSpaces.fulfilled, (state, action) => {
        state.loading = false;
        state.spacesName = action.payload;
        state.total = action.payload.total;
      });
  },
});

export default spaceNameSlice.reducer;
