import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerFromZero } from "../../services/CustomerService.js";
import MapPicker from "../../components/Map/MapPicker.js";
import {
  createContactMedium,
  getContactMedium,
} from "../../services/ContactMediumService.js";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getCustomerByUserId } from "../../services/CustomerService";

const validationSchema = Yup.object({
  name: Yup.string().required("Coloca tu nombre"),
  last_name: Yup.string(),
  second_last_name: Yup.string(),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Ingresa un numero valdo")
    .required("Coloca tu teléfono"),
  email: Yup.string()
    .email("Correo inválido")
    .required("El email es obligatorio"),
  medioContacto: Yup.string(),
  street: Yup.string(),
  number: Yup.string(),
  neighborhood: Yup.string(),
  postalCode: Yup.number(),
  state: Yup.string(),
});

const EditCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, createdCustomer, currentCustomer } = useSelector(
    (state) => state.customer
  );
  const { contactMediumList } = useSelector((state) => state.contactMedium);

  const [cords, setCords] = useState(null);
  const [loadingCord, setLoadingCord] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const debounceTimer = useRef(null);

  useEffect(() => {
    if (!loading && !error && createdCustomer) {
      navigate("/clientes");
    }
  }, [loading, error, createdCustomer]);

  useEffect(() => {
    dispatch(getCustomerByUserId(id));
    dispatch(getContactMedium());
  }, []);

  useEffect(() => {
    if (currentCustomer) {
      formik.setValues({
        name: currentCustomer.user.name || "",
        last_name: currentCustomer.user.last_name || "",
        second_last_name: currentCustomer.user.second_last_name || "",
        email: currentCustomer.user.email || "",
        phone: currentCustomer.phone || "",
        medioContacto: currentCustomer?.contact_medium?.id || "",
        street: currentCustomer.street || "",
        number: currentCustomer.number || "",
        neighborhood: currentCustomer.neighborhood || "",
        postalCode: currentCustomer.postalCode || "",
        state: currentCustomer.state || "",
      });
      setCords({
        lat: currentCustomer.latitude,
        lng: currentCustomer.longitude,
      });
    }
  }, [currentCustomer]);

  const formik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      second_last_name: "",
      email: "",
      phone: "",
      medioContacto: "",
      nuevoMedio: "",
      street: "",
      number: "",
      neighborhood: "",
      postalCode: "",
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const mediodecontacto = {};

      mediodecontacto.id_contact_medium = values.medioContacto;
      if (values.medioContacto === "crearuninput") {
        const response = await dispatch(
          createContactMedium({ name: values.nuevoMedio })
        );
        console.log(response);
        mediodecontacto.id_contact_medium = response.payload.id;
      }

      if (values.medioContacto === "crearuninput") {
        if (values.nuevoMedio === "") {
          return showErrorToast("Coloca el medio de contacto");
        }
      }

      if (values.medioContacto === "") {
        delete mediodecontacto.id_contact_medium;
      }

      dispatch(
        updateCustomerFromZero({
          id: currentCustomer.id,
          values: {
            last_name: values.last_name,
            second_last_name: values.second_last_name,
            street: values.street,
            number: values.number,
            neighborhood: values.neighborhood,
            postalCode: values.postalCode,
            state: values.state,
            latitude: Number(cords.lat),
            longitude: Number(cords.lng),
            ...mediodecontacto,
          },
        })
      );
    },
  });

  const showErrorToast = (message) => {
    toast.error(<b>{message}</b>, {
      duration: 4000,
      position: "top-right",
      icon: "⚠️",
    });
  };

  async function fetchCoordinatesFromGeocode(address) {
    const api_key = process.env.REACT_APP_API_MAPS;
    setLoadingCord(true);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${api_key}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        console.error(
          "Error:",
          data.error_message || "Unknown error from Google Maps API"
        );
        setCords({ lat: "17.05931", lng: "-96.72709" });
        return showErrorToast("Ocurrió un error al obtener la ubicación");
      }

      if (!data.results || data.results.length === 0) {
        console.error("No results found");
        setCords({ lat: "17.05931", lng: "-96.72709" });
        return showErrorToast("No se encontró la ubicación");
      }

      const location = data.results[0].geometry.location;
      console.log(location);

      return {
        lat: location.lat,
        lng: location.lng,
      };
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setCords({ lat: "17.05931", lng: "-96.72709" });
      return showErrorToast("Ocurrió un error al obtener la ubicación");
    } finally {
      setLoadingCord(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const newAddress = `${formik.values.street} ${formik.values.number}, ${formik.values.neighborhood}, ${formik.values.postalCode}, ${formik.values.state}`;

    setFullAddress(newAddress);

    if (
      formik.values.street &&
      formik.values.number &&
      formik.values.neighborhood &&
      formik.values.postalCode &&
      formik.values.state
    ) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      console.log(fullAddress);
      debounceTimer.current = setTimeout(async () => {
        const coords = await fetchCoordinatesFromGeocode(newAddress);
        if (coords) {
          setCords(coords);
          console.log(coords);
        }
      }, 500);
    }
  };

  return (
    <>
      {currentCustomer && (
        <div className="h-100 d-flex flex-column" style={{ minHeight: "91vh" }}>
          <PerfectScrollbar
            className="flex-grow-1 overflow-hidden"
            options={{ suppressScrollX: true }}
          >
            <ol className="breadcrumb float-xl-end">
              <li className="breadcrumb-item">
                <Link to="/">Inicio</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/clientes">Clientes</Link>
              </li>
              <li className="breadcrumb-item active">Actualizar</li>
            </ol>
            <h1 className="page-header">Editar los datos del cliente</h1>
            <Panel>
              <PanelHeader>Formulario de actualización</PanelHeader>
              <PanelBody>
                <h6>
                  Rellena el siguiente formulario para poder actualizar los
                  datos
                </h6>
              </PanelBody>
              <div className="row" style={{ margin: "1%" }}>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label">
                      Nombre Completo :
                    </label>
                    <div className="col-lg-3">
                      <input
                        disabled
                        className={`form-control ${
                          formik.touched.name && formik.errors.name
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nombre completo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-3">
                      <input
                        className={`form-control ${
                          formik.touched.last_name && formik.errors.last_name
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Apellido paterno"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                      />
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="text-danger">
                          {formik.errors.last_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-3">
                      <input
                        className={`form-control ${
                          formik.touched.second_last_name &&
                          formik.errors.second_last_name
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="second_last_name"
                        name="second_last_name"
                        placeholder="Apellido materno"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.second_last_name}
                      />
                      {formik.touched.second_last_name &&
                      formik.errors.second_last_name ? (
                        <div className="text-danger">
                          {formik.errors.second_last_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label">
                      Correo electrónico :
                    </label>
                    <div className="col-lg-9 ">
                      <input
                        disabled
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Correo electrónico"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label">
                      Número de teléfono :
                    </label>
                    <div className="col-lg-9">
                      <input
                        disabled
                        className={`form-control ${
                          formik.touched.phone && formik.errors.phone
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="phone"
                        maxLength="10"
                        name="phone"
                        placeholder="Número de teléfono. ej 951213987"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-danger">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label">
                      ¿Como nos contacto?
                    </label>
                    <div className="col-lg-9">
                      <select
                        className={`form-control ${
                          formik.touched.medioContacto &&
                          formik.errors.medioContacto
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        id="medioContacto"
                        name="medioContacto"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.medioContacto}
                      >
                        <option value="" disabled>
                          Seleccione una opción
                        </option>
                        {contactMediumList &&
                          contactMediumList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        <option value="crearuninput">Otro medio</option>
                      </select>
                      {formik.touched.medioContacto &&
                      formik.errors.medioContacto ? (
                        <div className="text-danger">
                          {formik.errors.medioContacto}
                        </div>
                      ) : null}

                      {formik.values.medioContacto === "crearuninput" && (
                        <div className="form-group row mt-4">
                          <label className="col-lg-4 col-form-label form-label">
                            Nuevo medio:
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className={`form-control `}
                              id="nuevoMedio"
                              name="nuevoMedio"
                              placeholder="Introduce el nuevo medio"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.nuevoMedio}
                            />
                            {/* {formik.touched.nuevoMedio &&
                      formik.errors.nuevoMedio ? (
                        <div className="text-danger">
                          {formik.errors.nuevoMedio}
                        </div>
                      ) : null} */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label">
                      Ubicación :
                    </label>
                    <div className="col-lg-4">
                      <input
                        className={`form-control ${
                          formik.touched.street && formik.errors.street
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="street"
                        name="street"
                        placeholder="Calle de la libertad"
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.handleChange(e);

                          handleInputChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.street}
                      />
                      {formik.touched.street && formik.errors.street ? (
                        <div className="text-danger">
                          {formik.errors.street}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-lg-3">
                      <input
                        className={`form-control ${
                          formik.touched.number && formik.errors.number
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="number"
                        name="number"
                        placeholder="Número 982"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.number}
                      />
                      {formik.touched.number && formik.errors.number ? (
                        <div className="text-danger">
                          {formik.errors.number}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-lg-2">
                      <input
                        className={`form-control ${
                          formik.touched.postalCode && formik.errors.postalCode
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        placeholder="Codigo postal"
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.handleChange(e);

                          handleInputChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.postalCode}
                      />
                      {formik.touched.postalCode && formik.errors.postalCode ? (
                        <div className="text-danger">
                          {formik.errors.postalCode}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label className="col-lg-3 col-form-label form-label"></label>

                    <div className="col-lg-5">
                      <input
                        className={`form-control ${
                          formik.touched.neighborhood &&
                          formik.errors.neighborhood
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        placeholder="Colonia"
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.handleChange(e);

                          handleInputChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.neighborhood}
                      />
                      {formik.touched.neighborhood &&
                      formik.errors.neighborhood ? (
                        <div className="text-danger">
                          {formik.errors.neighborhood}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-lg-4">
                      <input
                        className={`form-control ${
                          formik.touched.state && formik.errors.state
                            ? " border-danger form-control is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="state"
                        name="state"
                        placeholder="Municipio, ej: Xoxocotlan, Santa Cruz Amilpas"
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.handleChange(e);

                          handleInputChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <div className="text-danger">{formik.errors.state}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label form-label"></label>
                    <div className="col-lg-9">
                      {cords && (
                        <MapPicker
                          onChange={setCords}
                          initialPosition={cords}
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label form-label">
                      &nbsp;
                    </label>
                    <div className="col-lg-9 d-flex justify-content-between">
                      <button
                        className="btn btn-info"
                        type="button"
                        onClick={() => fetchCoordinatesFromGeocode()}
                      >
                        Buscar domicilio
                      </button>
                      <span></span>
                      {/* <Link to="/agendar-cita"> */}

                      <button
                        className="btn btn-success"
                        type="submit"
                        disabled={loadingCord || loading || !cords}
                      >
                        Guardar
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </form>
              </div>
            </Panel>
          </PerfectScrollbar>

          <div id="footer" className="app-footer m-0">
            &copy; Karimnot Todos los derechos reservados 2023
          </div>
        </div>
      )}
    </>
  );
};

export default EditCustomer;
