import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { useGetProject } from "../../services/ProjectService";
import SpacesEditProject from "./editProject/SpacesEditProject";
import CalendarEditProject from "./editProject/CalendarEditProject.js";

const EditProject = () => {
  const { id } = useParams();
  const [spaces, setSpaces] = useState([]);
  const [appoinment, setAppoinment] = useState({ date: null, hour: "" });
  const [block, setBlock] = useState(false);

  const { data, isLoading, isError, error } = useGetProject(id);

  useEffect(() => {
    if (data?.spaces) {
      const result = data.spaces.map((space) => ({
        space: space.space_name.name,
        requirement: space.requirement,
        new_space: false,
      }));
      setSpaces(result);
    }
    if (data?.appointment && data?.hour) {
      setAppoinment({
        ...appoinment,
        date: data?.appointment,
        hour: data.hour,
      });
    }
  }, [data]);

  const handleRemoveSpace = (index) => {
    setSpaces((prevSpaces) => prevSpaces.filter((_, i) => i !== index));
  };

  const handleAddSpace = () => {
    setSpaces((prevSpaces) => [...prevSpaces, { name: "", requirement: "" }]);
  };

  const updateProject = async () => {
    console.log({ spaces, appoinment });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error occurred: {error.message}</div>;
  }

  return (
    <>
      {/* {new Date() < new Date(data.appointment) ? "true" : "false"} */}

      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/">Inicio</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/proyectos">Proyectos</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/proyectos${data.id}`}>
            Proyecto de {data.customer.user.name}
          </Link>
        </li>
        <li className="breadcrumb-item active">Editar</li>
      </ol>
      <h1 className="page-header">Actualiza la información del proyecto</h1>

      <div className="row">
        <div className="col-xl-6">
          <div className="h-100">
            <Panel style={{ marginTop: "2%", height: "100%" }}>
              <PanelHeader>Espacios</PanelHeader>

              <PanelBody>
                <SpacesEditProject
                  spaces={spaces}
                  onRemove={(index) => handleRemoveSpace(index)}
                  onAdd={handleAddSpace}
                  setSpaces={setSpaces}
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
        <br />
        <div className="col-xl-6">
          <div className="h-100">
            <Panel style={{ marginTop: "2%", height: "100%" }}>
              <PanelHeader>Calendario</PanelHeader>

              <PanelBody>
                <CalendarEditProject
                  user_id={data.customer.id}
                  appoinment_date={data.appointment}
                  appoinment_hour={data.hour}
                  props={data}
                  state={appoinment}
                  setAppoinment={setAppoinment}
                  setBlock={setBlock}
                />
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <br />
      <div>
        <button
          className="btn btn-success"
          disabled={block}
          onClick={() => updateProject()}
        >
          {block ? "Selecciona otro día" : "Actualizar"}
        </button>
      </div>
    </>
  );
};

export default EditProject;
