// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Definir el icono rojo
// const redIcon = new L.Icon({
//   iconUrl:
//     "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
//   shadowUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowSize: [41, 41],
// });

// function LocationMarker({ func, initialPosition = {} }) {
//   const { lat = 17.0594, lng = -96.7216 } = initialPosition;

//   const [position, setPosition] = useState({ lat, lng });
//   const map = useMapEvents({
//     click(e) {
//       func(e.latlng);
//       setPosition(e.latlng);
//     },
//   });

//   useEffect(() => {
//     // Solicitar la ubicación del usuario al cargar el componente
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setPosition({ lat: latitude, lng: longitude });
//       },
//       (error) => {
//         console.error("Error obteniendo la ubicación", error);
//       }
//     );
//   }, []);

//   useEffect(() => {
//     if (initialPosition.lat && initialPosition.lng) {
//       setPosition(initialPosition);
//     }
//   }, [initialPosition]);

//   return position === null ? null : (
//     <Marker icon={redIcon} position={position}>
//       {/* Aquí puedes agregar un Popup si lo deseas */}
//     </Marker>
//   );
// }

// function MapPicker({ onChange, initialPosition }) {
//   const [center, setCenter] = useState({ lat: 17.0594, lng: -96.7216 });
//   const mapRef = useRef(null); // Agregar un ref para el mapa

//   // useEffect(() => {
//   //   if (mapRef.current) {
//   //     mapRef.current.flyTo([initialPosition.lat, initialPosition.lng], 13);
//   //   }
//   // }, [initialPosition]);

//   useEffect(() => {
//     console.log(initialPosition);
//     if (initialPosition.lat && initialPosition.lng) {
//       setCenter(initialPosition);
//     }
//   }, [initialPosition]);

//   useEffect(() => {
//     if (initialPosition.lat && initialPosition.lng && mapRef.current) {
//       mapRef.current.flyTo([initialPosition.lat, initialPosition.lng], 20);
//     }
//   }, [initialPosition]);

//   return (
//     <div>
//       <MapContainer
//         // center={center}
//         zoom={20}
//         style={{ width: "100%", height: "400px" }}
//         whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <LocationMarker func={onChange} initialPosition={initialPosition} />
//       </MapContainer>
//       {center && (
//         <div>
//           {" "}
//           <p className="mt-2">Indica donde esta tu domicilio</p>{" "}
//         </div>
//       )}
//     </div>
//   );
// }

// // export default MapPicker;

// export default MapPicker;

import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMap } from "react-leaflet";

const redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function LocationMarker({ func, initialPosition = {}, disabled }) {
  const { lat = 17.0594, lng = -96.7216 } = initialPosition;
  const [position, setPosition] = useState({ lat, lng });
  const map = useMap();

  useMapEvents({
    click(e) {
      if (!disabled) {
        func(e.latlng);
        setPosition(e.latlng);
      }
    },
  });

  useEffect(() => {
    if (initialPosition.lat && initialPosition.lng) {
      setPosition(initialPosition);
      map.setView(initialPosition, 20); // Centrar el mapa en la posición inicial
    }
  }, [initialPosition, map]);

  return position === null ? null : (
    <Marker icon={redIcon} position={position} />
  );
}

function MapPicker({ onChange, initialPosition, disabled = false, ...props }) {
  const [center, setCenter] = useState();

  useEffect(() => {
    if (initialPosition.lat && initialPosition.lng) {
      setCenter(initialPosition);
    }
  }, [initialPosition]);

  return (
    <div className="mt-3">
      {center && (
        <>
          {" "}
          <MapContainer
            center={center}
            zoom={20}
            style={{ width: "100%", height: "400px" }}
            {...props}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker
              disabled={disabled}
              func={onChange}
              initialPosition={initialPosition}
            />
          </MapContainer>
          {center && !disabled && (
            <div>
              <p className="mt-2">Indica donde esta tu domicilio</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MapPicker;
