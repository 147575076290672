import { httpClient, httpFormDataClient } from "./httpClient";

const prefix = "/file";

export default class S3Service {
  static async upload(file) {
    return (await httpFormDataClient.post(`${prefix}`, file)).data;
  }

  static async uploadFiles({ id, files }) {
    return (await httpFormDataClient.post(`${prefix}/phase/${id}`, files)).data;
  }

  static async getFile(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }
}
