import React, { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "../../../config/app-settings.js";
import PhotoSwipeLightbox from "photoswipe/lightbox";

const HeaderCustomer = ({ active }) => {
  const { rol } = useSelector((state) => state.user);

  useEffect(() => {
    if (rol === "Cliente") {
      require("./headerCustomer.css");
    }
  }, [rol]);

  const navigate = useNavigate();

  const { currentCustomer } = useSelector((state) => state.customer);

  const context = useContext(AppSettings);
  let lightboxRef = useRef(null);

  useEffect(() => {
    context.handleSetAppContentClass("p-0");

    if (!lightboxRef.current) {
      lightboxRef.current = new PhotoSwipeLightbox({
        gallery: ".gallery-v2",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightboxRef.current.init();
    }

    return () => {
      context.handleSetAppContentClass("");
      if (lightboxRef.current) {
        lightboxRef.current.destroy();
        lightboxRef.current = null;
      }
    };
  }, []);

  return (
    <div className="profile">
      <div className="profile-header">
        <div className="profile-header-cover"></div>
        <div className="profile-header-content">
          <div className="profile-header-img">
            <img src="/assets/img/user/user-13.jpg" alt="" />
          </div>
          <div className="profile-header-info">
            <h4 className="mt-0 mb-1">
              {currentCustomer?.user?.name} {currentCustomer?.user?.last_name}{" "}
              {currentCustomer?.user?.second_last_name}
            </h4>
            <p className="mb-2">
              {currentCustomer?.street} {currentCustomer?.number},{" "}
              {currentCustomer?.neighborhood}, {currentCustomer?.postalCode},{" "}
              {currentCustomer?.state}
            </p>
            <a
              onClick={() => navigate("/mis-datos")}
              className="btn btn-xs btn-yellow"
            >
              Editar perfil
            </a>
          </div>
        </div>
        <ul className="profile-header-tab nav nav-tabs">
          <li className="nav-item">
            <a
              href="#profile-post"
              className={`nav-link ${active === "NUEVO" ? "active" : ""}`}
              data-bs-toggle="tab"
              onClick={() => navigate("/")}
            >
              NUEVO
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#profile-about"
              className={`nav-link ${active === "PROYECTOS" ? "active" : ""}`}
              data-bs-toggle="tab"
              onClick={() => navigate("/proyectos")}
            >
              PROYECTOS
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#profile-photos"
              className={`nav-link ${active === "MIS DATOS" ? "active" : ""}`}
              data-bs-toggle="tab"
              onClick={() => navigate("/mis-datos")}
            >
              MIS DATOS
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderCustomer;
