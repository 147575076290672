import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { useGetAppoinmentsMonth } from "../../services/AppointmentService";

function countAppointmentsByDay(appointments) {
  const counts = {};

  appointments.forEach((appointment) => {
    const day = new Date(appointment.date).getDate(); // Obtiene el día del mes de la cita
    if (!counts[day]) {
      counts[day] = 0;
    }
    counts[day]++;
  });

  return Object.keys(counts).map((day) => ({
    dia: parseInt(day),
    citas: counts[day],
  }));
}

const initialValue = dayjs();
const DateCalendarServerRequest = ({
  setData,
  setMarkers,
  setCenter,
  setDate: setFecha,
}) => {
  const checkAvailabilityMutation = useGetAppoinmentsMonth();

  const [date, setDate] = React.useState(new Date());
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

  const getAppoinments = async () => {
    setIsLoading(true);
    try {
      const result = await checkAvailabilityMutation.mutateAsync({ date });

      const appointmentCounts = countAppointmentsByDay(result);

      const newMarkers = result.map((record) => ({
        id: record?.project[0]?.id,
        lat: record?.customer?.latitude,
        lng: record?.customer?.longitude,
        description: `${record?.customer?.user?.name} ${
          record?.customer?.user?.last_name || ""
        } ${record?.customer?.user?.second_last_name || ""}`,
        color: record?.color,
        date: record?.date,
      }));

      setMarkers(newMarkers);

      setCenter({
        lat: result[0]?.customer?.latitude,
        lng: result[0]?.customer?.longitude,
      });

      setHighlightedDays(appointmentCounts);

      setData({ appointments: result });
    } catch (error) {
      console.log(error);
      setData({
        error: true,
        message: error.message || "Ah ocurrido un error, intentalo mas tarde",
      });
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    getAppoinments();
  }, [date]);

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const fecha = new Date(day.$d);
    const dia = fecha.getDate();

    const numberEmojis = {
      1: "1️⃣",
      2: "2️⃣",
      3: "3️⃣",
      4: "4️⃣",
      5: "5️⃣",
      6: "6️⃣",
      7: "7️⃣",
      8: "8️⃣",
      9: "9️⃣",
      10: "🔟",
    };

    const badgeContent = highlightedDays.find((item) => item.dia === dia);

    const emoji = badgeContent?.citas
      ? badgeContent.citas > 10
        ? "🔟+"
        : numberEmojis[badgeContent.citas]
      : undefined;

    let dayStyle = {};
    if (badgeContent) {
      if (badgeContent.citas === 1) {
        dayStyle = { backgroundColor: "#00ff0059" }; // Verde 1 cita
      } else if (badgeContent.citas === 2) {
        dayStyle = { backgroundColor: "#ffff0075" }; // Amariilo 2 citas
      } else if (badgeContent.citas >= 3) {
        dayStyle = { backgroundColor: "#ff000069" }; // Rojo 3 o mas citas
      }
    }

    return (
      <Badge key={props.day.toString()} overlap="circular" /* badgeContent={emoji} */>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          style={dayStyle}
        />
      </Badge>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        views={["day"]}
        defaultValue={dayjs(initialValue)}
        loading={isLoading}
        onMonthChange={(e) => {
          setDate(e.$d);
          setFecha(e.$d);
        }}
        onChange={(e) => {
          // const newDate = e.$d;
          // newDate.setDate(newDate.getDate() - 1);
          // console.log(newDate)
          setDate(e.$d);
          setFecha(e.$d);
        }}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
        style={{ width: "100%", height: "100%" }}
      />
    </LocalizationProvider>
  );
};

export default DateCalendarServerRequest;
