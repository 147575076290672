import React, { useEffect } from "react";
import "lity";
import "lity/dist/lity.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerByUserId } from "../../services/CustomerService";
import { useNavigate } from "react-router-dom";

function Timeline() {
  const navigate = useNavigate();
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerByUserId(id));
  }, []);

  return (
    <div>
      {/* BEGIN breadcrumb */}
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <a href="javascript:;">Inicio</a>
        </li>
        <li className="breadcrumb-item">
          <a href="javascript:;">Proyectos</a>
        </li>
      </ol>
      {/* END breadcrumb */}
      {/* BEGIN page-header */}
      <h1 className="page-header">
        Proyectos <small>#1LKQ0 Recamara Juan</small>
      </h1>
      {/* END page-header */}
      {/* BEGIN timeline */}
      <div className="timeline">
        {/* BEGIN timeline-item */}
        <div className="timeline-item">
          {/* BEGIN timeline-time */}
          <div className="timeline-time">
            <span className="date">Miercoles</span>
            <span className="time">09:20</span>
          </div>
          {/* END timeline-time */}
          {/* BEGIN timeline-icon */}
          <div className="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          {/* END timeline-icon */}
          {/* BEGIN timeline-content */}
          <div className="timeline-content">
            {/* BEGIN timeline-header */}
            <div className="timeline-header">
              <div className="userimage">
                <img src="assets/img/user/user-1.jpg" alt="" />
              </div>
              <div className="username">
                <a href="javascript:;">
                  Pepe Perez <i className="fa fa-check-circle text-blue ms-1" />
                </a>
                <div className="text-muted fs-12px">
                  Agendo una cita en nuestro portal
                </div>
              </div>
            </div>
            {/* END timeline-header */}
            {/* BEGIN timeline-body */}
            <div className="timeline-body">
              {/* timeline-post */}
              <div className="mb-3">
                <div className="mb-2">
                  Necesito remodelar la habitacion de mi hijo <br />
                  Quiero que tengan en cuenta que necesito
                  <ul>
                    <li>1 Pantalla de 80 in</li>
                    <li>Espacio para 3 consolas</li>
                  </ul>
                  Aqui esta mi casa
                </div>
                <div className="ratio ratio-21x9">
                  <iframe
                    title="location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.5242683789525!2d-84.36843278503017!3d33.772957280682846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50410c04ff6d9%3A0x2710fc547d72ec83!2sTwitter%20Headquarters!5e0!3m2!1sen!2smy!4v1623766084359!5m2!1sen!2smy"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            {/* END timeline-body */}
          </div>
          {/* END timeline-content */}
        </div>
        {/* END timeline-item */}
        {/* BEGIN timeline-item */}
        <div className="timeline-item">
          {/* BEGIN timeline-time */}
          <div className="timeline-time">
            <span className="date">Jueves</span>
            <span className="time">20:17</span>
          </div>
          {/* END timeline-time */}
          {/* BEGIN timeline-icon */}
          <div className="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          {/* END timeline-icon */}
          {/* BEGIN timeline-content */}
          <div className="timeline-content">
            {/* BEGIN timeline-header */}
            <div className="timeline-header">
              <div className="userimage">
                <img src="assets/img/user/user-2.jpg" alt="" />
              </div>
              <div className="username">
                <a href="javascript:;">Akim Aguilar</a>
                <div className="text-muted fs-12px">
                  Levantamiento de requerimientos
                </div>
              </div>
            </div>
            {/* END timeline-header */}
            {/* BEGIN timeline-body */}
            <div className="timeline-body">
              {/* timeline-post */}
              <div className="mb-3">
                <div className="mb-2">
                  De la visita el dia de hoy a la casa de Pepe, se recabaron los
                  siguientes requerimientos
                </div>
                <div className="row gx-1">
                  <div className="col-6">
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://i.pinimg.com/550x/a5/19/19/a51919403adebc08390c570be633e461.jpg"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://i.pinimg.com/550x/a5/19/19/a51919403adebc08390c570be633e461.jpg)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="ratio ratio-4x3 mb-3px">
                      <a
                        href="https://www.decidetucasa.com/wp-content/webp-express/webp-images/uploads/2022/04/11-4.jpg.webp"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://www.decidetucasa.com/wp-content/webp-express/webp-images/uploads/2022/04/11-4.jpg.webp)",
                        }}
                      />
                    </div>
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-816865957933770667/original/8cfca769-3184-4f87-8212-eacbfbb68920.jpeg?im_w=960"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-816865957933770667/original/8cfca769-3184-4f87-8212-eacbfbb68920.jpeg?im_w=960)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="ratio ratio-4x3 mb-3px">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-816865957933770667/original/11d1b24c-0e67-4b56-ae81-bee3d6ab2bcc.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-816865957933770667/original/11d1b24c-0e67-4b56-ae81-bee3d6ab2bcc.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-884735294654965585/original/3ff407e2-b182-4785-a098-e835c3ec6ff5.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-884735294654965585/original/3ff407e2-b182-4785-a098-e835c3ec6ff5.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END timeline-body */}
          </div>
          {/* END timeline-content */}
        </div>
        {/* END timeline-item */}
        {/* BEGIN timeline-item */}
        <div className="timeline-item">
          {/* BEGIN timeline-time */}
          <div className="timeline-time">
            <span className="date">Viernes</span>
            <span className="time">08:17</span>
          </div>
          {/* END timeline-time */}
          {/* BEGIN timeline-icon */}
          <div className="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          {/* END timeline-icon */}
          {/* BEGIN timeline-content */}
          <div className="timeline-content">
            {/* BEGIN timeline-header */}
            <div className="timeline-header">
              <div className="userimage">
                <img src="assets/img/user/user-4.jpg" alt="" />
              </div>
              <div className="username">
                <a href="javascript:;">
                  Lelouch Wong
                  <i className="fa fa-check-circle text-blue ms-1" />
                </a>
                <div className="text-muted fs-12px">
                  Arquitecto encargado del proyecto
                </div>
              </div>
            </div>
            {/* END timeline-header */}
            {/* BEGIN timeline-body */}
            <div className="timeline-body">
              {/* timeline-post */}
              <div className="mb-3">
                <div className="mb-2">
                  Con los requerimientos obtenidos por mi compañero de trabajo,
                  se logro hacer el siguiente boceto
                </div>
                <div className="row gx-1">
                  <div className="col-6">
                    <a
                      href="https://i.pinimg.com/736x/04/ac/c6/04acc6e2cada8b5dcb5bbe1a04e4506b.jpg"
                      data-lity=""
                    >
                      <img
                        src="https://i.pinimg.com/736x/04/ac/c6/04acc6e2cada8b5dcb5bbe1a04e4506b.jpg"
                        alt=""
                        className="mw-100 d-block"
                      />
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://i.pinimg.com/originals/b9/3a/c1/b93ac108518e279fb21a7b692da2d98c.jpg"
                      data-lity=""
                    >
                      <img
                        src="https://i.pinimg.com/originals/b9/3a/c1/b93ac108518e279fb21a7b692da2d98c.jpg"
                        alt=""
                        className="mw-100 d-block"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <hr className="bg-gray-500 mt-10px mb-3" />
            </div>
            {/* END timeline-body */}
          </div>
          {/* END timeline-content */}
        </div>
        {/* END timeline-item */}
        {/* BEGIN timeline-item */}
        <div className="timeline-item">
          {/* BEGIN timeline-time */}
          <div className="timeline-time">
            <span className="date">Domingo</span>
            <span className="time">20:43</span>
          </div>
          {/* END timeline-time */}
          {/* BEGIN timeline-icon */}
          <div className="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          {/* END timeline-icon */}
          {/* BEGIN timeline-content */}
          <div className="timeline-content">
            {/* BEGIN timeline-header */}
            <div className="timeline-header">
              <div className="userimage">
                <img src="assets/img/user/user-4.jpg" alt="" />
              </div>
              <div className="username">
                <a href="javascript:;">
                  Lelouch Wong
                  <i className="fa fa-check-circle text-blue ms-1" />
                </a>
                <div className="text-muted fs-12px">Entrega del proyecto</div>
              </div>
            </div>
            {/* END timeline-header */}
            {/* BEGIN timeline-body */}
            <div className="timeline-body">
              {/* timeline-post */}
              <div className="mb-3">
                <h4 className="mb-1">Proyecto finalizado</h4>
                <div className="mb-2">
                  El cliente esta muy contento con el proyecto, esta recamara
                  quedo muy bien y se puede apreciar toda la vista de la ciudad
                </div>
                <div className="row gx-1">
                  <div className="col-6">
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/343d611f-13de-4b94-830d-c74d88f1d4f7.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/343d611f-13de-4b94-830d-c74d88f1d4f7.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="ratio ratio-4x3 mb-3px">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/0eb88c08-5558-48c5-8581-8034a75c5971.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/0eb88c08-5558-48c5-8581-8034a75c5971.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/8e109a35-3e6e-4346-ac1b-915e9b6c9a93.jpeg?im_w=960"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/8e109a35-3e6e-4346-ac1b-915e9b6c9a93.jpeg?im_w=960)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="ratio ratio-4x3 mb-3px">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/94f7fc32-8bbf-47db-a820-893d8d42186b.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/94f7fc32-8bbf-47db-a820-893d8d42186b.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                    <div className="ratio ratio-4x3">
                      <a
                        href="https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/6d108309-5ab8-493a-8b8d-b41ca22d437f.jpeg?im_w=1200"
                        data-lity=""
                        className="bg-size-cover bg-position-center"
                        style={{
                          backgroundImage:
                            "url(https://a0.muscache.com/im/pictures/miso/Hosting-966716532744146158/original/6d108309-5ab8-493a-8b8d-b41ca22d437f.jpeg?im_w=1200)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END timeline-body */}
          </div>
          {/* END timeline-content */}
        </div>
        {/* END timeline-item */}
        {/* BEGIN timeline-item */}
        {/* 
    
   */}
        {/* END timeline-item */}
      </div>
      {/* END timeline */}
    </div>
  );
}

export default Timeline;
