import { httpClient } from "./httpClient";
import { useMutation } from "@tanstack/react-query";

const prefix = "/appointment";

export async function createAppoinment({ customer_id, date }) {
  return (await httpClient.post(`${prefix}`, { customer_id, date })).data;
}

export async function getAppoinment({ date }) {
  return (await httpClient.get(`${prefix}/${date}`)).data;
}

export async function checkAvailability({ date, customer_id }) {
  return (
    await httpClient.post(`${prefix}/read-by-date`, { date, customer_id })
  ).data;
}

export async function checkAvailabilityFirst({ date, customer_id }) {
  return (
    await httpClient.post(`${prefix}/read-by-date-first`, { date, customer_id })
  ).data;
}

export async function getAppoinments({ date }) {
  return (await httpClient.post(`${prefix}/read-by-date-admin`, { date })).data;
}

export async function getAppoinmentsMonth({ date }) {
  return (await httpClient.post(`${prefix}/read-by-month-admin`, { date }))
    .data;
}

export function useCreateAppoinment() {
  return useMutation({
    mutationKey: ["createAppoinment"],
    mutationFn: createAppoinment,
  });
}

export function useCheckAvailability() {
  return useMutation({
    mutationKey: ["checkAvailability"],
    mutationFn: checkAvailability,
  });
}

export function useCheckAvailabilityFirst() {
  return useMutation({
    mutationKey: ["checkAvailabilityFirst"],
    mutationFn: checkAvailabilityFirst,
  });
}

export function useGetAppoinments() {
  return useMutation({
    mutationKey: ["getAppoinments"],
    mutationFn: getAppoinments,
  });
}

export function useGetAppoinmentsMonth() {
  return useMutation({
    mutationKey: ["getAppoinmentsMonth"],
    mutationFn: getAppoinmentsMonth,
  });
}

export function useGetAppoinment(date) {
  return useMutation({
    mutationKey: ["getAppoinment"],
    mutationFn: () => getAppoinment({ date }),
    enabled: false,
  });
}
