import React from "react";

function CustomerDetails() {
  return (
    <div>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">Cliente</a>
            </li>
            <li className="breadcrumb-item active">Detalles</li>
          </ol>
          <h1 className="page-header">Detalle del cliente</h1>
        </div>
      </div>
      <div className="mb-3 d-md-flex fw-bold">
        <div className="mt-md-0 mt-2">
          <a href="#/" className="text-decoration-none text-dark">
            <i className="fa fa-print fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Imprimir
          </a>
        </div>

        <div className="ms-md-4 mt-md-0 mt-2">
          <a href="#/" className="text-decoration-none text-dark">
            <i className="fa fa-pen fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Editar
          </a>
        </div>
        <div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            className="text-decoration-none text-dark text-opacity-75"
          >
            <i className="fa fa-cog fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Mas acciones <b className="caret"></b>
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              Eliminar
            </a>
          </div>
        </div>
      </div>
      <div className="row gx-4">
        <div className="col-xl-8 mb-xl-0 mb-4">
          <div className="card border-0">
            <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
              <i className="fa fa-credit-card fa-lg me-2 text-gray text-opacity-50"></i>
              Proyectos
              <a
                href="#/"
                className="ms-auto text-decoration-none text-gray-500"
              >
                {/* <i className="fab fa-paypal me-1 fa-lg"></i> View paypal records */}
              </a>
            </div>
            <div className="card-body">
              <table className="table table-borderless table-sm fw-bold m-0">
                <tbody>
                  <tr>
                    <td className="w-150px">Espacio trabajados</td>
                    <td>3 espacio</td>
                    <td className="text-end">$3,496.00</td>
                  </tr>
                  <tr>
                    <td>Mano de obra</td>
                    <td>3 Obreros</td>
                    <td className="text-end">$174.80</td>
                  </tr>
                  <tr>
                    <td>Materiales</td>
                    <td>Electronicos, Muebles, Materia prima</td>
                    <td className="text-end">$120.00</td>
                  </tr>
                  <tr>
                    <td className="pb-2" colSpan="2">
                      <b>Total</b>
                    </td>
                    <td className="text-end pb-2 text-decoration-underline">
                      <b>$3670.80</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr className="m-0" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-borderless table-sm fw-bold m-0">
                <tbody>
                  <tr>
                    <td className="w-150px">Espacio trabajados</td>
                    <td>3 espacio</td>
                    <td className="text-end">$3,496.00</td>
                  </tr>
                  <tr>
                    <td>Mano de obra</td>
                    <td>3 Obreros</td>
                    <td className="text-end">$174.80</td>
                  </tr>
                  <tr>
                    <td>Materiales</td>
                    <td>Electronicos, Muebles, Materia prima</td>
                    <td className="text-end">$120.00</td>
                  </tr>
                  <tr>
                    <td className="pb-2" colSpan="2">
                      <b>Total</b>
                    </td>
                    <td className="text-end pb-2 text-decoration-underline">
                      <b>$3670.80</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr className="m-0" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card border-0 mb-4">
            <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
              Cliente
              <a
                href="#/"
                className="ms-auto text-decoration-none text-gray-500"
              >
                Editar
              </a>
            </div>
            <div className="card-body fw-bold">
              <div className="d-flex align-items-center">
                <a href="#/" className="d-block">
                  <img
                    src="/assets/img/user/user-1.jpg"
                    alt=""
                    width="45"
                    className="rounded-pill"
                  />
                </a>
                <div className="flex-1 ps-3">
                  <a href="#/" className="d-block text-decoration-none">
                    John Smith
                  </a>
                  johnsmith@gmail.com
                </div>
              </div>
            </div>
          </div>
          <div className="card border-0 mb-4">
            <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
              Informacion de contacto
              <a
                href="#/"
                className="ms-auto text-decoration-none text-gray-500"
              >
                Editar
              </a>
            </div>
            <div className="card-body fw-bold">
              <i className="fa fa-phone fa-fw"></i> +52 951-663-4289
              <br />
              <br />
              Av. de la Independencia 909
              <br />
              Oaxaca de Juarez Oax.
              <br />
              <br />
              68030
              <br />
              <br />
              <a href="#/" className="text-decoration-none text-gray-600">
                <i className="fa fa-location-dot fa-fw"></i> Ver mapa
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
