import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { addNotification } from "../components/Notification/Notification";
import {
  createEmployee,
  getEmployees,
  getPaginatedEmployee,
} from "../services/EmployeeService";

const initialState = {
  employeeList: [],
  total: 0,
  loading: true,
  error: false,
  createdEmployee: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedEmployee.pending, (state) => {
        state.loading = true;
        state.createdEmployee = null;
        state.error = null;
      })
      .addCase(getPaginatedEmployee.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getPaginatedEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload.data;
        state.total = action.payload.total;
      });
    builder
      .addCase(getEmployees.pending, (state) => {
        state.loading = true;
        state.createdEmployee = null;
        state.error = null;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload;
      });

    builder
      .addCase(createEmployee.rejected, (state, action) => {
        addNotification(
          "warning",
          "Error al crear el empleado",
          action.error.message,
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(createEmployee.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.createdEmployee = null;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        addNotification(
          "success",
          "Empleado creado ",
          "Ya hemos enviado un correo al trabajador",
          "top-right"
        );
        state.createdEmployee = action.payload;
      });
  },
});

export default employeeSlice.reducer;
