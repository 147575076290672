import React from "react";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const addNotification = (
  notificationType,
  notificationTitle,
  notificationMessage,
  notificationPosition,
  notificationContent
) => {
  Store.addNotification({
    title: notificationTitle,
    message: notificationMessage,
    type: notificationType,
    insert: "top",
    container: notificationPosition,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: { duration: 2000 },
    dismissable: { click: true },
    content: notificationContent,
  });
};
