import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import {
  AppRoute,
  PublicRoutes,
  UserFirstTime,
  UserRoute,
} from "./config/app-route.jsx";
import QueryProvider from "./providers/QueryProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// bootstrap
import "bootstrap";

// css
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";
import "bootstrap-social/bootstrap-social.css";
import "react-tooltip/dist/react-tooltip.css";

import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css

import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./app/store.js";
import { PersistGate } from "redux-persist/integration/react";
import GetCustomerByUser from "./pages/user/GetCustomerByUser.js";

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  const { signedIn, rol } = useSelector((state) => state.user);
  const { currentCustomer } = useSelector((state) => state.customer);

  const privateRoutes = useRoutes(AppRoute);
  const userRoute = useRoutes(UserRoute);
  const userFirtTime = useRoutes(UserFirstTime);
  const publicRoutes = useRoutes(PublicRoutes);

  if (!signedIn) return publicRoutes;

  if (rol === "Cliente") {
    // require("./cssCustomer.css");

    if (!currentCustomer) return <GetCustomerByUser />;

    if (currentCustomer?.first_access) return userFirtTime;
    return userRoute;
  }

  return privateRoutes;
}

root.render(
  <QueryProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
          <ReactQueryDevtools />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </QueryProvider>
);
