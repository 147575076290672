import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

const prefix = "/contact-medium";

export const createContactMedium = createAsyncThunk(
  "createContactMedium",
  async (values) => {
    return (await httpClient.post(`${prefix}`, values)).data;
  }
);

export const createCustomerContactMedium = createAsyncThunk(
  "createCustomerContactMedium",
  async (values) => {
    return (await httpClient.post(`${prefix}/customer-contact-name`, values))
      .data;
  }
);

export const getContactMedium = createAsyncThunk(
  "getContactMedium",
  async () => {
    return (await httpClient.get(`${prefix}`)).data;
  }
);
