import axios from "axios";
import deepParseJson from "../utils/deepParseJson";

// const baseURL = "http://192.168.0.146:8080";
const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = "https://ardemaaaa.software4all.dev";

export const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(
  async function (config) {
    config.headers = {
      "Content-Type": "application/json",
    };

    const rawPersistData = localStorage.getItem("persist:root");
    const persistData = deepParseJson(rawPersistData);
    const accessToken = persistData?.user?.token;

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        localStorage.clear();

        // window.location.reload();
        window.location.href = "/";
      }
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({
        status: 500,
        message: "Error de conexión con el servidor.",
      });
    }
  }
);

const httpFormDataClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

httpFormDataClient.interceptors.request.use(
  function (config) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };

    const rawPersistData = localStorage.getItem("persist:root");
    const persistData = deepParseJson(rawPersistData);
    const accessToken = persistData.user.token;

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { httpFormDataClient };
