import React from "react";
import RadioField from "./RadioField";
import InputField from "./InputField";
import { Chip, Divider } from "@mui/material";

const KitchenForm = ({ formik, spaceName }) => {
  return (
    <>
      <Divider>
        <Chip label={"Materiales para la cocina"} />
      </Divider>

      <div className="row">
        <RadioField
          label={"Cubierta"}
          name={`${spaceName}.cubierta`}
          value={formik.values[`${spaceName}.cubierta`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.cubierta}
          options={[
            { label: "Sileston", value: "Sileston" },
            { label: "Dekton", value: "Dekton" },
            { label: "Xtine", value: "Xtine" },
          ]}
        />
        <RadioField
          label={"Bisagras"}
          name={`${spaceName}.bisagra`}
          value={formik.values[`${spaceName}.bisagra`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.bisagra}
          options={[
            { label: "Hafele", value: "Hafele" },
            { label: "Blum", value: "Blum" },
          ]}
        />
      </div>
      {(formik.values[`${spaceName}`]?.cubierta === "Sileston" ||
        formik.values[`${spaceName}`]?.cubierta === "Dekton" ||
        formik.values[`${spaceName}`]?.cubierta === "Xtine") && (
        <InputField
          name={`${spaceName}.tipo_cubierta`}
          value={formik.values[`${spaceName}.tipo_cubierta`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_cubierta}
          label="Tipo de cubierta"
        />
      )}

      <div className="row">
        <RadioField
          label={"Correderas"}
          name={`${spaceName}.corredera`}
          value={formik.values[`${spaceName}.corredera`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.corredera}
          options={[
            { label: "Hafele", value: "Hafele" },
            { label: "Blum", value: "Blum" },
          ]}
        />
        <RadioField
          label={"Jaladera"}
          name={`${spaceName}.jaladera`}
          value={formik.values[`${spaceName}.jaladera`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.jaladera}
          options={[
            { label: "Debajo", value: "Debajo" },
            { label: "Otro", value: "Otro" },
          ]}
        />
      </div>
      {formik.values[`${spaceName}`]?.jaladera === "Otro" && (
        <InputField
          name={`${spaceName}.tipo_jaladera`}
          value={formik.values[`${spaceName}.tipo_jaladera`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_jaladera}
          label="Tipo de jaladera"
        />
      )}

      <InputField
        name={`${spaceName}.iluminacion`}
        value={formik.values[`${spaceName}.iluminacion`]}
        onChange={formik.handleChange}
        error={formik.errors[spaceName]?.iluminacion}
        placeholder="En que parte va la iluminación"
        label="Ilumincaión"
      />

      <div className="row">
        <RadioField
          label={"Garrafon de agua"}
          name={`${spaceName}.garrafon_agua`}
          value={formik.values[`${spaceName}.garrafon_agua`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.garrafon_agua}
          options={[
            { label: "Si", value: "Si" },
            { label: "No", value: "No" },
          ]}
        />
        <RadioField
          label={"Basurero"}
          name={`${spaceName}.basurero`}
          value={formik.values[`${spaceName}.basurero`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.basurero}
          options={[
            { label: "Si", value: "Si" },
            { label: "No", value: "No" },
          ]}
        />
      </div>
      {formik.values[`${spaceName}`]?.basurero === "Si" && (
        <InputField
          name={`${spaceName}.tipo_basurero`}
          value={formik.values[`${spaceName}.tipo_basurero`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_basurero}
          label="Tipo de basurero"
        />
      )}

      <div className="row">
        <RadioField
          label={"Botellero"}
          name={`${spaceName}.botellero`}
          value={formik.values[`${spaceName}.botellero`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.botellero}
          options={[
            { label: "Premium", value: "Premium" },
            { label: "Standard", value: "Standard" },
          ]}
        />
        <RadioField
          label={"Esquinero"}
          name={`${spaceName}.esquinero`}
          value={formik.values[`${spaceName}.esquinero`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.esquinero}
          options={[
            { label: "Premium", value: "Premium" },
            { label: "Standard", value: "Standard" },
          ]}
        />
      </div>

      <div className="row">
        <RadioField
          label={"Bajo tarja"}
          name={`${spaceName}.botellero`}
          value={formik.values[`${spaceName}.botellero`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.botellero}
          options={[
            { label: "Premium", value: "Premium" },
            { label: "Standard", value: "Standard" },
          ]}
        />
        <RadioField
          label={"Alacena"}
          name={`${spaceName}.alacena`}
          value={formik.values[`${spaceName}.alacena`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.alacena}
          options={[
            { label: "Premium", value: "Premium" },
            { label: "Standard", value: "Standard" },
          ]}
        />
      </div>
      {formik.values[`${spaceName}`]?.alacena === "Premium" && (
        <InputField
          name={`${spaceName}.tipo_alacena`}
          value={formik.values[`${spaceName}.tipo_alacena`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_basurero}
          label="Tipo de alacena"
        />
      )}

      <div className="row">
        <RadioField
          label={"Accesorios de cubiertos"}
          name={`${spaceName}.accesorios_cubiertos`}
          value={formik.values[`${spaceName}.accesorios_cubiertos`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.accesorios_cubiertos}
          options={[
            { label: "Si", value: "Si" },
            { label: "No", value: "No" },
          ]}
        />
      </div>

      <Divider>
        <Chip label={"Materiales para el interior"} />
      </Divider>

      <div className="row">
        <RadioField
          label={"Interior"}
          name={`${spaceName}.interior`}
          value={formik.values[`${spaceName}.interior`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.interior}
          options={[
            { label: "MDF Melaminico", value: "MDF Melaminico" },
            { label: "Triplay", value: "Triplay" },
            { label: "MDF Enchapado", value: "MDF Enchapado" },
            { label: "Madera", value: "Madera" },
            { label: "Otro", value: "Otro" },
          ]}
          big={true}
        />
      </div>
      {(formik.values[`${spaceName}`]?.interior === "Triplay" ||
        formik.values[`${spaceName}`]?.interior === "MDF Enchapado" ||
        formik.values[`${spaceName}`]?.interior === "Otro" ||
        formik.values[`${spaceName}`]?.interior === "Madera") && (
        <InputField
          name={`${spaceName}.tipo_interior`}
          value={formik.values[`${spaceName}.tipo_interior`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_interior}
          label="Tipo de material"
        />
      )}

      <Divider>
        <Chip label={"Materiales para la puerta"} />
      </Divider>

      <div className="row">
        <RadioField
          label={"Puertas"}
          name={`${spaceName}.puerta`}
          value={formik.values[`${spaceName}.puerta`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.puerta}
          options={[
            { label: "MDF edger", value: "MDF edger" },
            { label: "MDF matt", value: "MDF matt" },
            { label: "Madera", value: "Madera" },
            { label: "MDF natural", value: "MDF natural" },
            { label: "MDF melaminado", value: "MDF melaminado" },
            { label: "Otro", value: "Otro" },
          ]}
          big={true}
        />
      </div>
      {(formik.values[`${spaceName}`]?.puerta === "MDF edger" ||
        formik.values[`${spaceName}`]?.puerta === "MDF matt" ||
        formik.values[`${spaceName}`]?.puerta === "Madera" ||
        formik.values[`${spaceName}`]?.puerta === "MDF natural" ||
        formik.values[`${spaceName}`]?.puerta === "MDF melaminado" ||
        formik.values[`${spaceName}`]?.puerta === "Otro") && (
        <InputField
          name={`${spaceName}.tipo_puerta`}
          value={formik.values[`${spaceName}.tipo_puerta`]}
          onChange={formik.handleChange}
          placeholder={"Especifica color o material"}
          error={formik.errors[spaceName]?.tipo_puerta}
          label="Especifica"
        />
      )}

      {/* +++++++++++ */}
    </>
  );
};

export default KitchenForm;
