import React from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";

import "react-html5-camera-photo/build/css/index.css";

const CameraField = ({ onFilesAdded }) => {
  const handleTakePhoto = (dataUri) => {
    // Convert dataUri to a file blob
    const blob = dataURItoBlob(dataUri);
    const filename = `foto-${getCurrentTimeFormatted()}.jpg`;

    const file = new File([blob], filename, {
      type: "image/jpeg",
      lastModified: new Date(),
    });

    // Use the callback
    onFilesAdded([file]);
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  function getCurrentTimeFormatted() {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <div
    // style={{maxWidth: "100px"}}
    >
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: 640, height: 480 }}
        imageType={IMAGE_TYPES.JPG}
      />
    </div>
  );
};

export default CameraField;
