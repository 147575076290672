import React from "react";

const Pagination = ({
  currentPage,
  pagesCount,
  handlePageClick,
  handlePrevClick,
  handleNextClick,
  total,
}) => {
  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Proyectos totales {total}
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li
          className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePrevClick}
        >
          <a href="#/" className="page-link">
            Anterior
          </a>
        </li>
        {[...Array(pagesCount)].map((_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? "" : ""}`}
            onClick={() => handlePageClick(index + 1)}
          >
            <a
              className="page-link"
              href="#/"
              style={{
                backgroundColor: currentPage === index + 1 ? "#348fe2" : "",
                color: currentPage === index + 1 ? "#fff" : "",
              }}
            >
              {index + 1}
            </a>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === pagesCount ? "disabled" : ""
          }`}
          onClick={handleNextClick}
        >
          <a className="page-link" href="#/">
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
