import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadProjectExcel,
  getPaginatedProject,
  getPaginatedProjectsByAny,
} from "../../services/ProjectService";
import { Link, useNavigate } from "react-router-dom";
import DynamicSkeletonTable from "../../components/skeleton/DynamicSkeletonTable";
import TabTable from "./table/TabTable";
import Pagination from "./table/Pagination";
import RowProject from "./table/RowProject";
import { setCustomer } from "../../features/customerSlice";

const ProjectTable = () => {
  const dispatch = useDispatch();
  const debounceTimeout = useRef(null);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Filtrar");
  const [filter, setFilter] = useState("");

  const { projectList, loading, error, total } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    dispatch(setCustomer(null));
    dispatch(getPaginatedProject({ limit, offset }));
  }, []);

  useEffect(() => {
    dispatch(getPaginatedProject({ limit, offset }));
  }, []);

  useEffect(() => {
    if (currentFilter) {
      // dispatch(
      //   getPaginatedProjectByState({
      //     limit,
      //     offset,
      //     state: {
      //       state: currentFilter,
      //     },
      //   })
      // );
    } else if (filter) {
    } else {
      dispatch(getPaginatedProject({ limit, offset }));
    }
  }, [limit, offset, currentFilter, dispatch]);

  const handleFilterChange = (filterValue, name) => {
    if (name) {
      setSelectedFilter(name);
    } else {
      setSelectedFilter("Filtrar");
    }
    setFilter("");
    setCurrentFilter(filterValue);
    setOffset(0);
  };

  const filterChange = (value) => {
    setFilter(value); // Esto actualizará el valor del input instantáneamente

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // Solo realiza la acción después de esperar.
      if (value) {
        console.log(value);

        setCurrentFilter(null);
        setOffset(0);
        dispatch(
          getPaginatedProjectsByAny({ limit, offset, term: { term: value } })
        );
      } else {
        setCurrentFilter(null);
        setOffset(0);
        dispatch(getPaginatedProject({ limit, offset }));
      }
    }, 1000);
  };

  const pagesCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;

  const handlePageClick = (pageNum) => {
    setOffset((pageNum - 1) * limit);
  };

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setOffset(offset + limit);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">Proyectos</a>
            </li>
          </ul>
          <h1 className="page-header mb-0">Proyectos</h1>
        </div>
        <div className="ms-auto">
          <Link to="/proyectos/nuevo" className="fw-bold">
            <a
              href="#/"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
              Crear proyecto
            </a>
          </Link>
        </div>
      </div>

      <div className="mb-3 d-md-flex fw-bold">
        <div className="mt-md-0 mt-2">
          <a
            href="#/"
            className="text-dark text-decoration-none"
            onClick={() => dispatch(downloadProjectExcel())}
          >
            <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Exportar
          </a>
        </div>
        <div className="ms-md-4 mt-md-0 mt-2 ">
          <a
            href="#/"
            className="text-dark text-decoration-none"
            onClick={handlePrint}
          >
            <i className="fa fa-print fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Imprimir
          </a>
        </div>
      </div>

      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <TabTable
              selectedFilter={selectedFilter}
              handleFilterChange={handleFilterChange}
              filter={filter}
              filterChange={filterChange}
            />

            {loading && projectList.length === 0 ? (
              <DynamicSkeletonTable
                columns={[
                  "No",
                  "Nombre",
                  "Correo",
                  "Número de teléfono",
                  "Domicilio",
                  "Contactado por",
                ]}
              />
            ) : (
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Espacio</th>
                      <th>Cliente</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Fase</th>
                      <th>Encargado</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {projectList.map((project) => (
                      <RowProject key={project.id} project={project} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <Pagination
              total={total}
              currentPage={currentPage}
              pagesCount={pagesCount}
              handlePageClick={handlePageClick}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTable;
