import { useEffect, useState } from "react";
import { RequirementRow } from "./UploadGatheringRequirements";
import ModalDataCustomer from "./forms/ModalDataCustomer";
import { Chip, Divider } from "@mui/material";
import { useParams } from "react-router-dom";

const RenderSpace = ({ space, project }) => {
  const { id } = useParams();
  const spaceName = space.space_name.name;
  const requirement = space.requirements;
  const [openLevantamiento, setOpenLevantamiento] = useState(false);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [materiales, setMateriales] = useState({});

  const materials = project.phases[0].materials;

  const getMaterials = (materialList) => {
    const material = materialList.map((material) => {
      console.log(material);
      return material.space.space_name.name === spaceName;
    });
    const selectedIndex = material.findIndex((value) => value === true);

    return selectedIndex !== -1 ? materialList[selectedIndex] : null;
  };

  useEffect(() => {
    setMateriales(getMaterials(materials));
    console.log(getMaterials(materials));
  }, [project]);

  return (
    <>
      <RequirementRow space={space} name={spaceName} />
      {/* {/* <Divider textAlign="left"> */} 
        <h5>Datos de la visita</h5>
       <Divider/> 
      <div
        className="form-group row "
        style={{
          paddingLeft: "20px",
        }}
      >
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          
        >
          Datos del levantamiento:
        </label>

        <label
          className="col-lg-10 col-form-label form-label"
          onClick={() =>
            window.open(
              `/requerimientos-detalle/${id}`,
              "_blank",
              "width=1000,height=600"
            )
          }
        >
          Presiona aquí para ver los datos del levantamiento
        </label>
        <ModalDataCustomer
          open={openLevantamiento}
          handleClose={() => setOpenLevantamiento(false)}
          title={spaceName}
          data={requirement}
        />
      </div>

      {/* <div
        className="form-group row "
        style={{
          paddingLeft: "20px",
        }}
      >
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          style={{  textAlign: "end", color: "gray", }}
        >
          Selección de materiales:
        </label>

        <label
          className="col-lg-10 col-form-label form-label"
          onClick={() => {
            // setOpenMaterial(true);
            window.open(
              `/requerimientos-detalle/${id}`,
              "_blank",
              "width=1000,height=600"
            );
          }}
        >
          Presiona aquí para ver los materiales seleccionados por el cliente
        </label>
        <ModalDataCustomer
          open={openMaterial}
          type={1}
          handleClose={() => setOpenMaterial(false)}
          title={`Materiales ${spaceName}`}
          data={materiales || {}}
        />
      </div> */}
    </>
  );
};

export default RenderSpace;
