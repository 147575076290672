import { createSlice } from "@reduxjs/toolkit";
import {
  createContactMedium,
  createCustomerContactMedium,
  getContactMedium,
} from "../services/ContactMediumService";
import { addNotification } from "../components/Notification/Notification";

const initialState = {
  contactMediumList: [],
  loading: null,
  error: null,
};

const contactMediumSlice = createSlice({
  name: "contactMedium",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createContactMedium.rejected, (state, action) => {
        console.log({ errorAlCrear: action.error.message });
        addNotification(
          "warning",
          "Ah ocurrido un error",
          `Intentalo mas tarde ${action.error.message}`,
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(createContactMedium.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createContactMedium.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      });

    builder
      .addCase(getContactMedium.rejected, (state, action) => {
        console.log({ errorAlRecuperar: action.error.message });
        addNotification(
          "warning",
          "Ah ocurrido un error",
          "Intentalo mas tarde",
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(getContactMedium.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getContactMedium.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.contactMediumList = action.payload;
      });

    builder
      .addCase(createCustomerContactMedium.rejected, (state, action) => {
        addNotification(
          "warning",
          "Ah ocurrido un error",
          "Intentalo mas tarde",
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(createCustomerContactMedium.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createCustomerContactMedium.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      });
  },
});

export default contactMediumSlice.reducer;
