import React, { useEffect, useState } from "react";
import { useCheckAvailability } from "../../../services/AppointmentService";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const CalendarEditProject = ({
  user_id,
  appoinment_date,
  appoinment_hour,
  state,
  setAppoinment,
  setBlock,
}) => {
  require("./table.css");

  const checkAvailabilityMutation = useCheckAvailability();

  const [selectedHour, setSelectedHour] = useState(null);
  const [customer_id, setCustomer_id] = useState(null);
  const [dates, setDates] = useState({ list: [], message: "", error: false });
  const [loading, setLoading] = useState(true);
  const [initialDate, setInitialDate] = useState(new Date());

  const handleCheckAvailability = async () => {
    setLoading(true);
    setBlock(false);
    try {
      const result = await checkAvailabilityMutation.mutateAsync({
        date,
        customer_id,
      });

      setDates({ list: result.citas });
    } catch (error) {
      setDates({ message: error.message, error: true });
      setBlock(true);
      setSelectedHour(null);
    } finally {
      setLoading(false);
    }
  };

  const cambiarHora = (hora) => {
    setSelectedHour(hora);
    setAppoinment({ ...state, hour: hora });
  };

  const renderButton = (hour) => {
    let variant = "outlined";

    let partes = hour.split(":");
    let fecha = new Date();
    fecha.setHours(parseInt(partes[0]), parseInt(partes[1]), 0);
    fecha.setHours(fecha.getHours() + 1);
    function pad(number) {
      return number < 10 ? "0" + number : number;
    }
    let tiempoActualizado =
      pad(fecha.getHours()) + ":" + pad(fecha.getMinutes());

    const horaString = `${hour} - ${tiempoActualizado}`;

    if (selectedHour === horaString) {
      variant = "seleccionado";
    }

    return (
      <button
        variant={variant}
        className="btn btn-sm btn-white"
        style={{
          backgroundColor: `${variant === "seleccionado" ? "#348fe2" : ""}`,
        }}
        onClick={() => cambiarHora(horaString)}
      >
        {horaString}
      </button>
    );
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const [date, setDate] = useState(tomorrow);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const obtenerFechaPorDefecto = (date = new Date()) => {
    const manana = new Date(date);
    manana.setDate(manana.getDate() + 1);

    if (manana.getDay() === 6) {
      manana.setDate(manana.getDate() + 2);
    }

    return manana;
  };

  const esFechaHabilitada = (date) => {
    return date.getDay() !== 0;
  };
  const fechaPorDefecto = obtenerFechaPorDefecto();

  useEffect(() => {
    if (customer_id) {
      handleCheckAvailability();
    }
  }, [customer_id, date]);

  useEffect(() => {
    if (user_id) {
      setCustomer_id(user_id);
    }

    if (appoinment_date) {
      setDate(new Date(appoinment_date));
    }
  }, [user_id]);

  useEffect(() => {
    const originalDate = new Date(appoinment_date);
    originalDate.setHours(0, 0, 0, 0);
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    const originalDateString = originalDate.toISOString().split("T")[0];
    const newDateString = newDate.toISOString().split("T")[0];

    if (originalDateString === newDateString) {
      setSelectedHour(appoinment_hour);
    } else {
      setSelectedHour(null);
    }
  }, [dates]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DatePicker
              inline
              filterDate={esFechaHabilitada}
              minDate={fechaPorDefecto}
              selected={date}
              onChange={(date) => {
                setDate(new Date(date));
                setAppoinment({ ...state, date: date });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table table-striped mb-0 align-middle">
              <tbody>
                {loading ? (
                  <>Cargando ...</>
                ) : (
                  <>
                    {dates.error ? (
                      <>{dates.message} </>
                    ) : (
                      <>
                        {dates.list.length > 0 &&
                          dates.list.map((record, index) => (
                            <tr key={index}>
                              {/* {renderButton(record.time)} */}

                              {dates.list
                                .slice(index * 4, index * 4 + 4)
                                .map((record) => (
                                  <td key={record.time}>
                                    {renderButton(record.time)}
                                  </td>
                                ))}
                            </tr>
                          ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarEditProject;
