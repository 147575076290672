import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const QueryProvider = ({ children }) => {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        mutations: { networkMode: "offlineFirst" },
        queries: { staleTime: 1500, networkMode: "offlineFirst" },
      },
    })
  );

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default QueryProvider;
