import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPaginatedEmployee } from "../../services/EmployeeService.js";
import DynamicSkeletonTable from "../../components/skeleton/DynamicSkeletonTable.js";

const EmployeeTable = () => {
  const dispatch = useDispatch();

  const debounceTimeout = useRef(null);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("");

  const { employeeList, total, loading, error } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    dispatch(getPaginatedEmployee({ limit, offset }));
  }, [limit, offset, currentFilter, dispatch]);

  const pagesCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;

  const handlePageClick = (pageNum) => {
    setOffset((pageNum - 1) * limit);
  };

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setOffset(offset + limit);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">Inicio</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#/">Trabajadores</a>
              </li>
            </ul>
            <h1 className="page-header mb-0">Trabajadores</h1>
          </div>
          <div className="ms-auto">
            <Link to="/trabajadores/nuevo" className="fw-bold">
              <a
                href="#/"
                className="btn btn-success btn-rounded px-4 rounded-pill"
              >
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
                Crear trabajador
              </a>
            </Link>
          </div>
        </div>

        <div className="mb-3 d-md-flex fw-bold">
          <div className="mt-md-0 mt-2">
            <a href="#/" className="text-dark text-decoration-none">
              <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
              Exportar
            </a>
          </div>
          <div className="ms-md-4 mt-md-0 mt-2 ">
            <a href="#/" className="text-dark text-decoration-none">
              <i className="fa fa-print fa-fw me-1 text-dark text-opacity-50"></i>{" "}
              Imprimir
            </a>
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="input-group mb-3">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                      style={{ zIndex: 10 }}
                    >
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control px-35px bg-light"
                      placeholder="Buscar trabajador..."
                    />
                  </div>
                </div>
              </div>

              {loading ? (
                <DynamicSkeletonTable
                  columns={[
                    "No",
                    "Nombre",
                    "Correo",
                    "Número de teléfono",
                    "Domicilio",
                    "Contactado por",
                  ]}
                />
              ) : (
                <div className="table-responsive mb-3">
                  <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Cargo</th>
                        <th>Email</th>
                        <th>Foto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeList &&
                        employeeList.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <Link to="/trabajadores" className="fw-bold">
                                  #{item.id.substring(0, 4).toUpperCase()}
                                </Link>
                              </td>
                              <td>
                                {item.user.name} {item.user.last_name}{" "}
                                {item.user.second_last_name}
                              </td>

                              <td>
                                {item.user.rol.name === "Administrador"
                                  ? "Administrador"
                                  : item.title_job}
                              </td>
                              <td>{item.user.email}</td>
                              <td>
                                {" "}
                                <img
                                  src={
                                    item.user.avatar
                                      ? item.user.avatar
                                      : "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
                                  }
                                  style={{ width: "50px", height: "50px" }}
                                />{" "}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  Trabajadores totales {total}
                </div>
                <ul className="pagination mb-0 justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={handlePrevClick}
                  >
                    <a href="#/" className="page-link">
                      Anterior
                    </a>
                  </li>
                  {[...Array(pagesCount)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "" : ""
                      }`}
                      onClick={() => handlePageClick(index + 1)}
                    >
                      <a
                        className="page-link"
                        href="#/"
                        style={{
                          backgroundColor:
                            currentPage === index + 1 ? "#348fe2" : "",
                          color: currentPage === index + 1 ? "#fff" : "",
                        }}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === pagesCount ? "disabled" : ""
                    }`}
                    onClick={handleNextClick}
                  >
                    <a className="page-link" href="#/">
                      Siguiente
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeTable;
