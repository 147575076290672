import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

const prefix = "/material";

export const createMaterials = createAsyncThunk(
  "createMaterials",
  async (values) => {
    return (await httpClient.post(`${prefix}`, values)).data;
  }
);

export const getMaterialById = createAsyncThunk(
  "getMaterialById",
  async (id) => {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }
);

export default class MaterialService {
  static async create(requirement) {
    return (await httpClient.post(`${prefix}/`, requirement)).data;
  }
}
