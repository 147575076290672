import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  createProjectFromZero,
  downloadProjectExcel,
  getPaginatedByCustomer,
  getPaginatedProject,
  getPaginatedProjectsByAny,
  getProjectById,
  updateProject,
} from "../services/ProjectService";
import { addNotification } from "../components/Notification/Notification";

const initialState = {
  projectList: [],
  total: 0,
  loading: true,
  error: false,
  currentProject: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedProject.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPaginatedProject.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getPaginatedProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projectList = action.payload.data;
        state.total = action.payload.total;
      });

    builder
      .addCase(createProjectFromZero.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createProjectFromZero.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(createProjectFromZero.fulfilled, (state, action) => {
        state.loading = false;
      });
    builder
      .addCase(getProjectById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProjectById.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProject = action.payload;
      });

    builder
      .addCase(updateProject.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProject = action.payload;
        console.log(action.payload);
      });

    builder
      .addCase(getPaginatedByCustomer.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPaginatedByCustomer.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getPaginatedByCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.projectList = action.payload.data;
        state.total = action.payload.total;
      });

    builder
      .addCase(getPaginatedProjectsByAny.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getPaginatedProjectsByAny.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        console.log(action);
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(getPaginatedProjectsByAny.fulfilled, (state, action) => {
        state.loading = false;
        state.projectList = action.payload.data;
        state.total = action.payload.total;
      });

    builder
      .addCase(downloadProjectExcel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadProjectExcel.fulfilled, (state) => {
        state.loading = false;
        addNotification(
          "success",
          "Excel descargado ✅",
          "Hemos descargado el Excel con éxito",
          "top-right"
        );
      })
      .addCase(downloadProjectExcel.rejected, (state, action) => {
        state.loading = false;
        addNotification(
          "error",
          "Error al descargar",
          action.error.message,
          "top-right"
        );
      });
  },
});

export default projectSlice.reducer;
