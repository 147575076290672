import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Correo electrónico no valido")
    .required("Correo electrónico es requerido"),
  password: Yup.string().required("Contraseña es requerida"),
});

function LoginV3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentCustomer } = useSelector((state) => state.customer);
  // mis-datos

  const handleSubmit = async ({ email, password }) => {
    dispatch(login({ email, password }));
  };

  // if (currentCustomer) {
  //   navigate("/mis-datos");
  // }

  return (
    <div className="login login-with-news-feed">
      <Toaster />

      <div className="news-feed">
        <div
          className="news-image"
          style={{ backgroundImage: "url(/assets/img/fondo.png)" }}
        ></div>
        <div className="news-caption">
          <h4 className="caption-title">
            <b>Ardema </b>muebles & carpintería
          </h4>
          <p>Muebles para el hogar comercio y oficina.</p>
        </div>
      </div>
      <div className="login-container">
        <div className="login-header mb-30px">
          <div className="brand">
            <div className="d-flex align-items-center">
              <i
                className="fa-solid fa-hammer"
                style={{ color: "#e80454" }}
              ></i>
              <b> Ardema</b>
            </div>
            <small>¡Bienvenido al Panel de Administración!</small>
          </div>
          <div className="icon">
            <i className="fa fa-sign-in-alt"></i>
          </div>
        </div>
        <div className="login-content">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // Aquí puedes realizar la lógica para enviar el formulario
              // values contiene los valores de los campos
              console.log(values);
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className="fs-13px">
                <div
                  className={`form-floating mt-5px ${
                    errors.email && touched.email ? "has-danger" : ""
                  }`}
                >
                  <Field
                    type="text"
                    name="email"
                    className={`form-control h-45px fs-13px ${
                      errors.email && touched.email
                        ? "border-danger form-control is-invalid"
                        : ""
                    }`}
                    placeholder="Correo electrónico"
                  />
                  <label
                    htmlFor="email"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Correo electrónico
                  </label>
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger mt-4px"
                />
                <div
                  className={`form-floating mt-15px ${
                    errors.password && touched.password ? "has-danger " : ""
                  }`}
                >
                  <Field
                    type="password"
                    name="password"
                    className={`form-control h-45px fs-13px ${
                      errors.password && touched.password
                        ? "border-danger form-control is-invalid"
                        : ""
                    }`}
                    placeholder="Contraseña"
                  />
                  <label
                    htmlFor="password"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Contraseña
                  </label>
                </div>
                <div className="mt-4px"></div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger "
                />

                <div className="mb-10px mt-25px">
                  <button
                    type="submit"
                    // className="btn-primary btn btn-theme d-block h-45px w-100 btn-lg fs-14px"
                    className="btn btn-success  d-block h-45px w-100 btn-lg fs-14px"
                    disabled={isSubmitting}
                  >
                    Inicia sesión
                  </button>
                  <div className="text-gray-500 mt-2">
                    Quieres generar una nueva sesión? Generala{" "}
                    <Link to="solicitar-token">aquí</Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <hr className="bg-gray-600 opacity-2" />
          <div className="text-gray-600 text-center text-gray-500-darker mb-0">
            &copy; Karimnot Todos los derechos reservados 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginV3;
