import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { generateToken } from "../../services/CustomerService";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

const GenerateToken = () => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [activeBg] = useState("./login-bg-12.jpg");
  const [block, setBlock] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      // email: Yup.string()
      //   .email("Correo electrónico inválido")
      //   .required("El correo electrónico es obligatorio"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "El número de teléfono debe tener 10 dígitos")
        .required("El número de teléfono es obligatorio"),
    }),
    onSubmit: (values) => {
      setBlock(true);
      dispatch(generateToken({ phone: values.phone }));
      toast.success(<b>Ya hemos enviado una nueva sesión a tu teléfono</b>, {
        duration: 4000,
        position: "top-right",
        icon: "✅",
      });
      setCounter(10);
      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(interval); // Limpia el intervalo cuando el contador llega a 0
            setBlock(false);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);
    },
  });

  return (
    <>
      <div className="login login-v2 fw-bold">
        <Toaster />
        <div className="login-cover">
          <div
            className="login-cover-img"
            style={{ backgroundImage: `url(${activeBg})` }}
          ></div>
          <div className="login-cover-bg"></div>
        </div>

        <div className="login-container">
          <div className="login-header">
            <div className="brand">
              <div className="d-flex align-items-center">
                <i
                  className="fa-solid fa-hammer"
                  style={{ color: "#e80454" }}
                ></i>

                <b style={{ marginLeft: "2%" }}>Ardema</b>
              </div>
              <small>Solicita una nueva sesión aquí</small>
            </div>
            <div className="icon">{/* <i className="fa fa-lock" ></i> */}</div>
          </div>

          <div className="login-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-floating mb-2px">
                <input
                  type="text"
                  className={`form-control fs-13px h-45px border-0 ${
                    formik.errors.phone && formik.touched.phone
                      ? "border-danger form-control is-invalid"
                      : ""
                  }`}
                  placeholder="Número de teléfono"
                  id="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                <label
                  htmlFor="phone"
                  className="d-flex align-items-center text-gray-600 fs-13px"
                >
                  Número de teléfono
                </label>
              </div>
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-danger">{formik.errors.phone}</div>
              ) : null}
              <div className="mt-20px mb-20px">
                <button
                  type="submit"
                  disabled={block}
                  className="btn btn-success btn-theme d-block w-100 h-45px btn-lg"
                >
                  {block
                    ? `Enviar nueva sesion en: ${counter}`
                    : "Enviar nueva sesión"}
                </button>
              </div>
              <div className="text-gray-500 mt-2">
                Inicia sesión <Link to="solicitar-token">aquí</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateToken;
