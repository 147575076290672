import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { useGetAppoinments } from "../../services/AppointmentService.js";
import MapApointment from "./MapApointment.js";

import DateCalendarServerRequest from "./CalendarMUI.js";
import TableApointment from "./TableApointment.js";

const AllApointments = () => {
  const [date, setDate] = useState(new Date());
  const [originalDate, setOriginalDate] = useState(new Date());

  const [currentDay, setCurrentDay] = useState(false);

  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({ lat: 17.05931, lng: -96.72709 });
  const zoom = 15;
  const [markers, setMarkers] = useState([]);
  const [data, setData] = useState({
    appointments: [],
    error: false,
    message: "",
  });

  const [citas, setCitas] = useState([]);

  useEffect(() => {
    let newDate = new Date(date);
    setOriginalDate(newDate);
  }, [date]);

  useEffect(() => {
    if (currentDay) {
      const fechaOriginal = new Date(date.toISOString().split("T")[0]);
      fechaOriginal.setDate(fechaOriginal.getDate());

      const fechaBuscada = fechaOriginal.toISOString().split("T")[0];
      const citasFiltradas = data.appointments.filter((cita) => {
        const fechaCita = new Date(cita.date).toISOString().split("T")[0];

        return fechaCita === fechaBuscada;
      });

      setCitas(citasFiltradas);
    } else {
      setCitas(data.appointments);
    }
  }, [currentDay, date, data]);

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: "92vh" }}>
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Calendario</Link>
          </li>
        </ol>
        <h1 className="page-header">Calendario de citas</h1>
        <Panel>
          <PanelBody>
            <div className="row">
              <div className="col-xl-4">
                <div className="h-100">
                  <Panel style={{ height: "370px" }}>
                    <PanelHeader>Calendario</PanelHeader>
                    <PanelBody>
                      <div className="row" style={{ margin: "0px" }}>
                        <DateCalendarServerRequest
                          setData={setData}
                          setMarkers={setMarkers}
                          setCenter={setCenter}
                          setDate={setDate}
                        />
                      </div>
                    </PanelBody>
                  </Panel>
                </div>
              </div>

              <div className="col-xl-8">
                <div className="h-100">
                  <Panel style={{ height: "370px" }}>
                    <PanelHeader>Citas</PanelHeader>
                    <PanelBody>
                      <div className="row" style={{ margin: "0px" }}>
                        {loading ? (
                          <>Cargando ...</>
                        ) : (
                          <>
                            {data.error ? (
                              <>{data.message}</>
                            ) : (
                              <div
                                className="row"
                                style={{
                                  margin: "0px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                {data.appointments.length > 0 ? (
                                  <TableApointment
                                    // appointments={data.appointments}
                                    appointments={citas}
                                    fecha={originalDate}
                                    currentDay={currentDay}
                                    setCurrentDay={setCurrentDay}
                                  />
                                ) : (
                                  <>Sin citas</>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </PanelBody>
                  </Panel>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-xl-12">
                {/* {markers.length > 0 && (
                  <MapApointment
                    center={center}
                    zoom={zoom}
                    markers={markers}
                  />
                )} */}
                <MapApointment
                  center={center}
                  zoom={zoom}
                  markers={markers || []}
                />
              </div>
            </div>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; Karimnot Todos los derechos reservados 2023
      </div>
    </div>
  );
};

export default AllApointments;
