import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./project.css";
import { Stepper, Step, StepLabel, Button, TextField } from "@mui/material";
import { ReactNotifications, Store } from "react-notifications-component";
import { getPaginatedCustomer } from "../../services/CustomerService";

import toast from "react-hot-toast";
import Requirements from "./newProject/Requirements";
import ScheduleAgend from "./newProject/ScheduleAgend";
import Confirm from "./newProject/Confirm";
import { createProjectFromZero } from "../../services/ProjectService";

const NewProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = ["Requisitos", "Agendar cita", "Confirmar datos"];
  const [activeStep, setActiveStep] = useState(0);
  const [lastStep, setLastStep] = useState(false);
  const [fases, setFases] = useState([]);

  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();

  const handleNext = () => {
    let isValid = true;

    if (activeStep === 0) {
      isValid = step1Ref.current.validate();
      if (!isValid) {
        return addNotification(
          "warning",
          "",
          "Ingresa los requisitos necesarios para tu proyecto!",
          "top-right"
        );
      }
    } else if (activeStep === 1) {
      isValid = step2Ref.current.validate();
      if (!isValid) {
        return addNotification(
          "warning",
          "",
          "Selecciona una hora para tu cita",
          "top-right"
        );
      }
    } else if (activeStep === 2) {
      isValid = step3Ref.current.validate();
    }

    if (isValid !== false) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setFases((setFases) => [...setFases, isValid]);
    } else {
      console.log("Error: Validación no pasó.");
    }

    return false;
  };

  useEffect(() => {
    dispatch(getPaginatedCustomer({ limit: 1000, offset: 0 }));
  }, []);

  const handleBack = () => {
    const nuevasFases = [...fases];

    nuevasFases.pop();

    setFases(nuevasFases);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const getStepComponent = (step) => {
    if (step === 3 && !lastStep) {
      // console.log("Enviando a la bd");
      setLastStep(true);

      dispatch(
        createProjectFromZero({
          user_id: fases[0].customer.user.id,
          projects: fases[0].output,
          day: fases[1].day,
          hour: fases[1].hour,
        })
      );

      navigate("/proyectos");
    }
    return (
      <div>
        <div
          className={
            step === 0 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Requirements ref={step1Ref} />
        </div>
        <div
          className={
            step === 1 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <ScheduleAgend fases={fases} ref={step2Ref} />
        </div>
        <div
          className={
            step === 2 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Confirm ref={step3Ref} fases={fases} />
        </div>

        <div
          className={
            step === 3 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Confirm ref={step3Ref} fases={fases} />
        </div>
      </div>
    );
  };

  return (
    <div className="tab-pane fade show active" id="profile-post">
      <ReactNotifications />
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {getStepComponent(activeStep)}

        <div
          style={{
            // position: "fixed",
            justifyContent: "space-between",
            bottom: 0,
            width: "100%",
            padding: "20px",
          }}
        >
          <div style={{ float: "left" }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Atras
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={activeStep === 3 ? true : false}
            >
              {activeStep === steps.length - 1 ? "Agendar" : "Siguiente"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProject;
