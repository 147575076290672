import { combineReducers } from "redux";
import userSlice from "./userSlice";
import employeeSlice from "./employeeSlice";
import customerSlice from "./customerSlice";
import contactMediumSlice from "./contactMediumSlice";
import projectSlice from "./projectSlice";
import phaseSlice from "./phaseSlice";
import spaceNameSlice from "./spaceNameSlice";
import materialSlice from "./materialSlice";

const rootReducer = combineReducers({
  user: userSlice,
  employee: employeeSlice,
  customer: customerSlice,
  contactMedium: contactMediumSlice,
  project: projectSlice,
  phase: phaseSlice,
  spacesName: spaceNameSlice,
  material: materialSlice,
  // ...asyncReducers,
});

export default rootReducer;
