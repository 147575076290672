import React from "react";
import { Link } from "react-router-dom";

const RowProject = ({ project }) => {
  const {
    survey_employee,
    project_manager,
    quote_sent,
    status_project,
    appointment,
    requirements,
    phases,
  } = project;

  const badgeClass = getBadgeClass(survey_employee, "primary", "danger");
  const displayText = getUserOrUnassigned(survey_employee);

  const quote_sentClass = getBadgeClass(
    status_project === "Sin enviar",
    "warning",
    "success"
  );

  const project_managerClass = getBadgeClass(
    project_manager,
    "primary",
    "danger"
  );
  const project_managerText = getUserOrUnassigned(project_manager);

  const fechaFormateada = formatDate(appointment);

  return (
    <tr key={project.id}>
      <td>
        <Link to={`/proyecto/${project.id}`} className="fw-bold">
          #{project.id.substring(0, 4).toUpperCase()}
        </Link>
      </td>
      <td>
        {project?.spaces?.map((space) => {
          return (
            <>
              {" "}
              <i
                className="fas fa-hammer fa-fw"
                style={{ color: "#e40450" }}
              ></i>{" "}
              {space.space_name.name}
            </>
          );
        })}
      </td>
      <td>{project?.customer.user?.name} </td>
      <td>{project?.customer.user?.email}</td>

      <td>
        <BadgeWithIcon
          className={quote_sentClass}
          text={project?.status_project}
        />
      </td>

      <td>
        <BadgeWithIcon
          className={quote_sentClass}
          text={
            phases.length === 2
              ? "Presupuesto"
              : phases.length === 1
              ? "Materiales"
              : phases.length === 0
              ? "Visita"
              : null
          }
        />
      </td>
      <td>
        <BadgeWithIcon className={badgeClass} text={displayText} />
      </td>
    </tr>
  );
};

// ... Aquí van las funciones auxiliares que hemos definido anteriormente ...

const getBadgeClass = (condition, primaryText, dangerText) => {
  return condition
    ? `badge border border-${primaryText} text-${primaryText} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`
    : `badge border border-${dangerText} text-${dangerText} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`;
};

const getUserOrUnassigned = (userObject) => {
  return userObject ? userObject.user.name : "Sin asignar";
};

const formatDate = (dateString) => {
  const fecha = new Date(dateString);
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  return `${fecha.getDate()} de ${
    meses[fecha.getMonth()]
  } ${fecha.getFullYear()}`;
};

const truncateAndFormat = (reqs) => {
  let fullString = reqs.map((req) => req.description).join(", ");

  if (fullString.length > 13) {
    let truncatedString = fullString.substring(0, 13).trim();
    if (truncatedString.endsWith(",") || truncatedString.endsWith(" ")) {
      truncatedString = truncatedString.substring(
        0,
        truncatedString.length - 1
      );
    }
    fullString = truncatedString + "...";
  }

  return fullString;
};

const BadgeWithIcon = ({ className, text }) => (
  <span className={className}>
    <i className="fa fa-circle fs-9px fa-fw me-5px"></i> {text}
  </span>
);

export default RowProject;
