import React from "react";
import { useSelector } from "react-redux";

function SearchForm() {
  const { rol } = useSelector((state) => state.user);
  return (
    <div className="navbar-form">
      <form action="" method="POST" name="search_form">
        {rol === "Administrador" && (
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Buscar" />
            <button type="submit" className="btn btn-search">
              <i className="fa fa-search"></i>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default SearchForm;
