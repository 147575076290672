import React from "react";
import { Box, TextField, Grid } from "@mui/material";
import { validateEmail, validatePhone } from "../../services/CustomerService";

const FormNewCustomer = ({ formik }) => {
  const handlePhoneChange = async (e) => {
    formik.handleChange(e);

    formik.setFieldError("phone", "");
    formik.setTouched({ ...formik.touched, phone: false });

    if (e.target.value.length >= 10) {
      try {
        await validatePhone(e.target.value);
      } catch (error) {
        console.log(error);

        setTimeout(() => {
          formik.setFieldError(
            "phone",
            error.message || "Ese teléfono ya existe"
          );
        }, 0);
        formik.setTouched({ ...formik.touched, phone: true });
      }
    }
  };

  const handleEmailChange = async (e) => {
    formik.handleChange(e);

    formik.setFieldError("email", "");
    formik.setTouched({ ...formik.touched, email: false });

    if (/^\S+@\S+\.\S+$/.test(e.target.value)) {
      try {
        await validateEmail(e.target.value);
      } catch (error) {
        console.log(error);

        setTimeout(() => {
          formik.setFieldError(
            "email",
            error.message || "Ese correo ya existe"
          );
        }, 0);
        formik.setTouched({ ...formik.touched, email: true });
      }
    }
  };

  return (
    <>
      <Box gap={2}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin="normal"
              name="name"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin="normal"
              name="last_name"
              label="Apellido Paterno"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin="normal"
              name="second_last_name"
              label="Apellido Materno"
              value={formik.values.second_last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.second_last_name &&
                Boolean(formik.errors.second_last_name)
              }
              helperText={
                formik.touched.second_last_name &&
                formik.errors.second_last_name
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              margin="normal"
              name="phone"
              label="Teléfono"
              value={formik.values.phone}
              onChange={(event) => handlePhoneChange(event)}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              name="email"
              label="Correo Electrónico"
              value={formik.values.email}
              // onChange={formik.handleChange}
              onChange={(event) => handleEmailChange(event)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            name="street"
            label="Calle"
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            margin="normal"
            name="number"
            label="Número"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            margin="normal"
            name="postalCode"
            label="Código Postal"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            name="neighborhood"
            label="Colonia"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
            error={
              formik.touched.neighborhood && Boolean(formik.errors.neighborhood)
            }
            helperText={
              formik.touched.neighborhood && formik.errors.neighborhood
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            name="state"
            label="Ciudad"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormNewCustomer;
