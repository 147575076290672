import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ModalDataCustomer = ({ open, handleClose, title, data, type = 0 }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          De la visita se logro recabar la siguiente informacion
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Resultado</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {type === 0 ? (
                  <>
                    {Object.entries(data[0]).map(([key, value]) => {
                      return (
                        <>
                          {value != null &&
                            key != "requirement" &&
                            key != "id" && (
                              <TableRow key={key}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  {key
                                    .replace(/_/g, " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                    key.replace(/_/g, " ").slice(1)}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {Array.isArray(value)
                                    ? value.join(", ")
                                    : value}
                                </TableCell>
                              </TableRow>
                            )}
                        </>
                      );
                    })}
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        Levantamiento
                      </TableCell>
                      <TableCell>{data[0].requirement}</TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {Object.entries(data).map(([key, value]) => {
                      if (
                        value != null &&
                        value != "" &&
                        value != "Materiales seleccionados por el cliente" &&
                        key !== "requirement" &&
                        key !== "id" &&
                        key !== "space"
                      ) {
                        return (
                          <TableRow key={key}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                                key.replace(/_/g, " ").slice(1)}
                            </TableCell>
                            <TableCell>
                              {Array.isArray(value)
                                ? value.join(", ")
                                : JSON.stringify(value)}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null; // Es importante retornar 'null' o 'undefined' si no quieres renderizar nada
                      }
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDataCustomer;
