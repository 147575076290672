import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { login } from "../services/AuthService";
import { updateUser } from "../services/UserService";
import { generateToken, validateToken } from "../services/CustomerService";

const initialState = {
  token: null,
  loading: null,
  signedIn: false,

  id: null,
  name: null,
  last_name: null,
  second_last_name: null,
  email: null,
  avatar: null,
  rol: null,
  expiration: null,
  tokenCustomer: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onSignOutSuccess: (state, _) => initialState,
    setTokenCustomer: (state, action) => {
      state.tokenCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })

      .addCase(login.rejected, (state, action) => {
        state.loading = false;

        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.signedIn = true;
        state.token = action.payload.token;

        state.id = action.payload.id;
        state.name = action.payload.name;
        state.last_name = action.payload.last_name;
        state.second_last_name = action.payload.second_last_name;
        state.email = action.payload.email;
        state.avatar = action.payload.avatar;
        state.rol = action.payload.rol;

        toast.success(<b>Bienvenido {action.payload.name}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "✅",
        });
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        toast.error(<b>{action.error.message}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "⚠️",
        });
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
      });

    builder
      .addCase(generateToken.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(generateToken.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(generateToken.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      });

    builder
      .addCase(validateToken.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(validateToken.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.error = false;

        state.loading = false;
        state.signedIn = true;
        state.token = action.payload.token;

        state.id = action.payload.id;
        state.name = action.payload.name;
        state.last_name = action.payload.last_name;
        state.second_last_name = action.payload.second_last_name;
        state.email = action.payload.email;
        state.avatar = action.payload.avatar;
        state.rol = action.payload.rol.name;
        state.expiration = action.payload.expiration;

        toast.success(<b>Bienvenido {action.payload.name}</b>, {
          duration: 4000,
          position: "top-right",
          icon: "✅",
        });
      });
  },
});

export const { onSignOutSuccess, setTokenCustomer } = userSlice.actions;

export default userSlice.reducer;
