import { createSlice } from "@reduxjs/toolkit";
import { addNotification } from "../components/Notification/Notification";
import { createPhase } from "../services/PhaseService";

const initialState = {
  phases: [],
  loading: null,
  error: null,
  currentPhase: null,
};

const phaseSlice = createSlice({
  name: "phase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPhase.rejected, (state, action) => {
        console.log({ erroPhasess: action.error.message });
        addNotification(
          "warning",
          "Ah ocurrido un error",
          "Intentalo mas tarde",
          "top-right"
        );
        state.loading = false;
        state.error = true;
      })
      .addCase(createPhase.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createPhase.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.currentPhase = action.payload;
      });
  },
});

export default phaseSlice.reducer;
