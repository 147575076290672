import React, { useState } from "react";
import { Modal, Box, Button, Alert } from "@mui/material";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import GoogleMapsComponent from "./GoogleMapsComponent";
import FormNewCustomer from "./FormNewCustomer";
import { useCreateCustomerModal } from "../../services/CustomerService";
import { useDispatch } from "react-redux";

const NewClientModal = ({
  open,
  onClose,
  setInputValue,
  setSelectedUser,
  setCustomer,
}) => {
  const dispatch = useDispatch();
  const createCustomerMutation = useCreateCustomerModal();

  const [apiKey] = useState(process.env.REACT_APP_API_MAPS);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationFound, setIsLocationFound] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: 17.0732,
    lng: -96.7266,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      street: "",
      number: "",
      neighborhood: "",
      postalCode: "",
      state: "",
      rol: "Cliente",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.phone = String(values.phone);
      values.latitude = coordinates.lat;
      values.longitude = coordinates.lng;
      setIsLoading(true);
      try {
        const response = await createCustomerMutation.mutateAsync(values);
        console.log(response);
        setCustomer(response);
        setSelectedUser(response);
        setInputValue(
          `${response.user.name} ${response?.user?.last_name || ""} ${
            response?.user?.second_last_name || ""
          }`
        );
        onClose();
      } catch (error) {
        console.log({ error });
        setError(
          error.message || "Error al crear el cliente, intentalo mas tarde"
        );
        setTimeout(() => setError(null), 3000);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const findLocation = async () => {
    setIsLoading(true);
    setError(null);
    const { street, number, neighborhood, postalCode, state } = formik.values;
    const address = `${street} ${number}, ${neighborhood}, ${postalCode}, ${state}`;

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return setCoordinates(location);
      }
      setError(" No se encontró la ubicación");
      return setCoordinates({ lat: 17.0732, lng: -96.7266 });
    } catch (error) {
      console.error("Error al buscar la ubicación", error);
      setCoordinates({ lat: 17.0732, lng: -96.7266 });
      setError(" No se encontró la ubicación");
    } finally {
      setIsLoading(false);
      setIsLocationFound(true);
      setTimeout(() => setError(null), 3000);
    }
  };

  const setUser = () => {
    dispatch(setCustomer(undefined));
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={isLoading ? null : onClose}

        onClose={
          isLoading
            ? () => {}
            : () => {
                console.log("fakio ");
                // setUser();
                onClose();
                setInputValue("");
                setSelectedUser(null);
              }
        }
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h4 id="modal-title">Registrar nuevo cliente</h4>

          {error && <Alert severity="error">{error}</Alert>}
          <FormNewCustomer formik={formik} />
          <GoogleMapsComponent
            setUbication={setCoordinates}
            ubication={coordinates}
          />
          {/* {error && <p style={{ color: "red", marginTop: "5px" }}>{error}</p>} */}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={findLocation}
              variant="contained"
              margin="normal"
              disabled={isLoading}
            >
              Buscar ubicación
            </Button>

            <Button
              onClick={() => formik.handleSubmit()}
              margin="normal"
              variant="contained"
              disabled={!isLocationFound || isLoading}
            >
              Enviar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NewClientModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // on extra small screens, use 90% of the screen width
    sm: "75%", // on small screens, use 75% of the screen width
    md: "60%", // on medium screens, use 60% of the screen width
    lg: 700, // on large screens, use 700px
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Requerido"),
  email: Yup.string().required("Requerido"),
  street: Yup.string().required("Requerido"),
  phone: Yup.number()
    .test(
      "is-decimal",
      "Ingresa un número válido",
      (value) =>
        value &&
        Math.floor(value) === value &&
        value > 0 &&
        value.toString().length === 10
    )
    .required("Requerido"),
  neighborhood: Yup.string().required("Requerido"),
  postalCode: Yup.string().required("Requerido"),
  number: Yup.string().required("Requerido"),
  state: Yup.string().required("Requerido"),
});
