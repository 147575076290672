import React from "react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";

import { useFetchAllSpaces } from "../../../services/SpacesService";

const SpacesEditProject = ({ spaces, onRemove, onAdd, setSpaces }) => {
  const { data: spacesName, isLoading, isError, error } = useFetchAllSpaces();

  const updateSpaceAtIndex = (index, key, value) => {
    const newSpaces = [...spaces];

    newSpaces[index] = {
      ...newSpaces[index],
      [key]: value,
    };

    setSpaces(newSpaces);
  };

  return (
    <>
      {spaces.map((record, index) => (
        <div
          key={index}
          style={{
            backgroundColor:
              index % 2 === 0 ? "white" : "rgb(230 230 230 / 46%)",
            padding: "20px",
            border: "1px solid #0000002e",
            margin: "5px",
          }}
        >
          <div className="d-flex">
            <div className="col-lg-11">
              <div className="form-group row mb-3">
                <label
                  className="col-lg-2 col-form-label form-label textoresponsive "
                  
                >
                  Espacio:
                </label>
                <div className="col-lg-10 d-flex">
                  <select
                    className="form-select ml-2"
                    value={spaces[index]?.space || ""}
                    name="selectBox"
                    onChange={(e) => {
                      updateSpaceAtIndex(index, "space", e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Seleccione un espacio
                    </option>

                    {spacesName &&
                      spacesName.map((space, index) => (
                        <option value={space.name}>{space.name}</option>
                      ))}
                    <option value="otro">Otro espacio</option>
                  </select>

                  {spaces[index]?.space === "otro" && (
                    <input
                      style={{ marginLeft: "2%" }}
                      className="form-control ml-2"
                      type="text"
                      value={spaces[index]?.space_text || ""}
                      onChange={(e) => {
                        updateSpaceAtIndex(index, "space_text", e.target.value);
                      }}
                      placeholder="¿Qué espacio?"
                    />
                  )}
                </div>
              </div>

              <div className="form-group row mb-3">
                <label
                  className="col-lg-2 col-form-label form-label textoresponsive "
                  
                >
                  Observaciones:{" "}
                </label>
                <div className="col-lg-10 d-flex">
                  <textarea
                    className="form-control ml-2"
                    type="text"
                    value={spaces[index]?.requirement || ""}
                    onChange={(e) =>
                      updateSpaceAtIndex(index, "requirement", e.target.value)
                    }
                    placeholder="Observaciones"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-1 d-flex align-items-center justify-content-center">
              {index === 0 ? (
                <Tooltip title="Nuevo espacio">
                  <IconButton
                    size="large"
                    onClick={() => onAdd()}
                    color="success"
                  >
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Eliminar espacio">
                  <IconButton
                    size="large"
                    onClick={() => onRemove(index)}
                    color="error"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SpacesEditProject;
