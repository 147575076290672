import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";

import LoadingPage from "../../LoadingPage";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getProjectById } from "../../../services/ProjectService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import * as Yup from "yup";
import RenderSpace from "./RenderSpace";
import KitchenForm from "./forms/KitchenForm";
import SpaceForm from "./forms/SpaceForm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";

const MaterialsSelection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentProject, loading, error } = useSelector(
    (state) => state.project
  );
  const [expandedPanels, setExpandedPanels] = useState([0]);

  const [childAccordions, setChildAccordions] = useState({});

  const addChildAccordion = (parentIndex, name) => {
    const newAccordion = { space_name: { name: `${name} (nuevo)` } };
    setChildAccordions((prev) => {
      return {
        ...prev,
        [parentIndex]: [...(prev[parentIndex] || []), newAccordion],
      };
    });
  };

  const removeChildAccordion = (parentIndex, childIndex) => {
    setChildAccordions((prev) => {
      const updatedChildren = prev[parentIndex].filter(
        (_, index) => index !== childIndex
      );
      return {
        ...prev,
        [parentIndex]: updatedChildren,
      };
    });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedPanels((prev) => [...prev, panel]);
    } else {
      setExpandedPanels((prev) => prev.filter((item) => item !== panel));
    }
  };

  useEffect(() => {
    dispatch(getProjectById(id));
  }, []);

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: true,
    // validationSchema: getValidationSchema(
    //   currentProject ? currentProject.spaces : []
    // ),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  if (error) return <p>Error, proyecto no encontrado</p>;
  if (loading) return <LoadingPage />;

  return (
    <div className="h-100 d-flex flex-column">
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Proyectos</Link>
          </li>
        </ol>
        <h1 className="page-header">
          Selección de materiales para el proyecto de{" "}
          {currentProject.customer.user.name}
        </h1>
        <Panel>
          <PanelHeader>
            Selecciona los materiales para este proyecto
          </PanelHeader>
          <PanelBody>
            <form onSubmit={formik.handleSubmit}>
              {currentProject &&
                currentProject.spaces.map((space, index) => {
                  const spaceName = space.space_name.name;

                  return (
                    <>
                      <Accordion
                        key={index}
                        expanded={expandedPanels.includes(index)}
                        onChange={handleAccordionChange(index)}
                        sx={{ boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{ backgroundColor: "rgba(220, 220, 220, 0.5)" }}
                        >
                          <Typography>
                            Espacio: {space.space_name.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RenderSpace space={space} project={currentProject} />

                          <div
                            className="form-group row mb-3"
                            style={{ paddingLeft: "20px" }}
                          >
                            {spaceName === "Cocina" ? (
                              <KitchenForm
                                formik={formik}
                                spaceName={spaceName}
                              />
                            ) : (
                              <SpaceForm
                                formik={formik}
                                spaceName={spaceName}
                              />
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {(childAccordions[index] || []).map(
                        (childSpace, childIndex) => (
                          <Accordion key={`child-${childIndex}`}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`child-panel${childIndex}-content`}
                              id={`child-panel${childIndex}-header`}
                              sx={{
                                backgroundColor: "rgba(210, 210, 210, 0.5)",
                              }}
                            >
                              <Typography>
                                Espacio: {childSpace.space_name.name}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RenderSpace
                                space={space}
                                project={currentProject}
                              />
                              <div
                                className="form-group row mb-3"
                                style={{ paddingLeft: "20px" }}
                              >
                                <SpaceForm
                                  formik={formik}
                                  spaceName={childSpace.space_name.name}
                                />
                              </div>

                              <Tooltip title="Eliminar">
                                <IconButton
                                  size="large"
                                  onClick={() =>
                                    removeChildAccordion(index, childIndex)
                                  }
                                  color="error"
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </AccordionDetails>
                          </Accordion>
                        )
                      )}

                      <Tooltip title={`Agregar nuevo ${spaceName}`}>
                        <IconButton
                          size="large"
                          onClick={() => addChildAccordion(index, spaceName)}
                          color="success"
                        >
                          <AddIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </>
                  );
                })}

              <div className="row">
                <div className="row mt-3 justify-content-center">
                  <button
                    // disabled={loadingPhase || loading}
                    type="submit"
                    style={{ maxWidth: "40%" }}
                    // onClick={() => navigate(`/proyecto/requerimientos/${id}`)}
                    className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                  >
                    Subir
                  </button>
                </div>
              </div>
            </form>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>
    </div>
  );
};

export default MaterialsSelection;

const getValidationSchema = (spaces) => {
  let schema = {};

  spaces.forEach((space) => {
    const spaceName = space.space_name.name;
    if (spaceName === "Cocina") {
      schema[spaceName] = Yup.object({
        refrigerador: Yup.string().required("¡Campo requerido!"),
        estufa: Yup.string().required("¡Campo requerido!"),
        color: Yup.string().required("¡Campo requerido!"),
        otro: Yup.string(),
      });
    } else {
      schema[spaceName] = Yup.object({
        detalles: Yup.string().required("¡Campo requerido!"),
        pared: Yup.string().required("¡Campo requerido!"),
        tipoMadera: Yup.string().when("pared", {
          is: "Madera",
          then: Yup.string().required("¡Campo requerido!"),
        }),
        piso: Yup.string().required("¡Campo requerido!"),
        otro: Yup.string(),
      });
    }
  });

  return Yup.object(schema);
};
