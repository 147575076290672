import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadCustomerExcel,
  getPaginatedCustomer,
  getPaginatedCustomerByAny,
  getPaginatedCustomerByState,
} from "../../services/CustomerService";
import { Link, useNavigate } from "react-router-dom";
import DynamicSkeletonTable from "../../components/skeleton/DynamicSkeletonTable";
import { setCustomer } from "../../features/customerSlice";
import Tooltip from "@mui/material/Tooltip";
import LoadingPage from "../LoadingPage";

const CustomerTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Filtrar por localidad");
  const [filter, setFilter] = useState("");

  const { customerList, loading, error, total } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    if (currentFilter) {
      dispatch(
        getPaginatedCustomerByState({
          limit,
          offset,
          state: {
            state: currentFilter,
          },
        })
      );
    } else if (filter) {
    } else {
      dispatch(getPaginatedCustomer({ limit, offset }));
    }
  }, [limit, offset, currentFilter, dispatch]);

  const handleFilterChange = (filterValue, name) => {
    if (name) {
      setSelectedFilter(name);
    } else {
      setSelectedFilter("Filtrar por localidad");
    }
    setFilter("");
    setCurrentFilter(filterValue);
    setOffset(0);
  };

  const filterChange = (value) => {
    setFilter(value); // Esto actualizará el valor del input instantáneamente

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      // Solo realiza la acción después de esperar.
      if (value) {
        setCurrentFilter(null);
        setOffset(0);
        dispatch(
          getPaginatedCustomerByAny({ limit, offset, term: { term: value } })
        );
      } else {
        setCurrentFilter(null);
        setOffset(0);
        dispatch(getPaginatedCustomer({ limit, offset }));
      }
    }, 1000);
  };

  const pagesCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;

  const handlePageClick = (pageNum) => {
    setOffset((pageNum - 1) * limit);
  };

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setOffset(offset + limit);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">Clientes</a>
            </li>
          </ul>
          <h1 className="page-header mb-0">Clientes</h1>
        </div>
        <div className="ms-auto">
          <Link to="/clientes/nuevo" className="fw-bold">
            <a
              href="#/"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
              Crear cliente
            </a>
          </Link>
        </div>
      </div>

      <div className="mb-3 d-md-flex fw-bold">
        <div className="mt-md-0 mt-2">
          <a
            href="#/"
            className="text-dark text-decoration-none"
            onClick={() => dispatch(downloadCustomerExcel())}
          >
            <i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Exportar
          </a>
        </div>
        <div className="ms-md-4 mt-md-0 mt-2 ">
          <a
            href="#/"
            className="text-dark text-decoration-none"
            onClick={handlePrint}
          >
            <i className="fa fa-print fa-fw me-1 text-dark text-opacity-50"></i>{" "}
            Imprimir
          </a>
        </div>
      </div>

      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-inline">{selectedFilter}</span>
                <span className="d-inline d-md-none">
                  <i className="fa fa-credit-card"></i>
                </span>
                <b className="caret"></b>
              </button>
              <div className="dropdown-menu">
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() =>
                    handleFilterChange("Valles", "Valles centrales")
                  }
                >
                  Valles centrales
                </a>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() => handleFilterChange("Reforma", "Reforma")}
                >
                  Reforma
                </a>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() => handleFilterChange("Xoxo", "Xoxocotlan")}
                >
                  Xoxocotlan
                </a>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() => handleFilterChange("Lucia", "Santa Lucia")}
                >
                  Santa Lucia
                </a>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() =>
                    handleFilterChange("Amilpas", "San Jacinto Amilpas")
                  }
                >
                  San Jacinto Amilpas
                </a>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() => handleFilterChange("Etla", "Villa de Etla")}
                >
                  Villa de Etla
                </a>
                <div role="separator" className="dropdown-divider"></div>
                <a
                  href="#/"
                  className="dropdown-item"
                  onClick={() => handleFilterChange("")}
                >
                  Limpiar filtro
                </a>
              </div>
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                    style={{ zIndex: 10 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    placeholder="Buscar cliente..."
                    value={filter}
                    onChange={(e) => filterChange(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {loading ? (
              <DynamicSkeletonTable
                columns={[
                  "No",
                  "Nombre",
                  "Correo",
                  "Número de teléfono",
                  "Domicilio",
                  "Contactado por",
                ]}
              />
            ) : (
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nombre</th>

                      <th>Correo</th>
                      <th>Número de teléfono</th>
                      <th>Domicilio</th>
                      <th>Contactado por</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerList &&
                      customerList.map((customer, index) => {
                        const {
                          street,
                          number,
                          neighborhood,
                          postalCode,
                          state,
                        } = customer;

                        let addressString = "Sin informacion";
                        if (
                          street &&
                          number &&
                          neighborhood &&
                          postalCode &&
                          state
                        ) {
                          const fullAddress = `${street} ${number}, ${neighborhood}, ${postalCode}, ${state}`;
                          const maxLength = 50;
                          addressString =
                            fullAddress.length > maxLength
                              ? fullAddress.slice(0, maxLength - 3) + "..."
                              : fullAddress;
                        }

                        return (
                          <tr key={customer.id}>
                            <td>
                              <a
                                onClick={() => {
                                  dispatch(setCustomer(customer));
                                }}
                              >
                                <Link
                                  to={`/proyectos/cliente/${customer.id}`}
                                  className="fw-bold"
                                >
                                  #{customer.id.substring(0, 4).toUpperCase()}
                                </Link>
                              </a>
                            </td>
                            <td>
                              {customer.user.name} {customer.user.last_name}{" "}
                              {customer.user.second_last_name}
                            </td>

                            <td>{customer.user.email}</td>
                            <td>{customer.phone}</td>
                            <td>{addressString}</td>
                            <td>
                              {customer?.contact_medium?.name ||
                                "Sin informacion"}
                            </td>
                            <td>
                              <div className="widget-icon ">
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    dispatch(setCustomer(customer));
                                    navigate("/proyectos/nuevo");
                                  }}
                                >
                                  <Tooltip title="Nuevo proyecto">
                                    <i
                                      className="fas fa-xs fa-fw me-10px fa-circle-plus"
                                      style={{ color: "#49b6d6" }}
                                    ></i>
                                  </Tooltip>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="widget-icon ">
                                <button
                                  style={{
                                    backgroundColor: "#fff0",
                                    borderColor: "#fff0",
                                  }}
                                  onClick={() => {
                                    dispatch(setCustomer(customer));
                                    navigate(`editar/${customer.user.id}`);
                                  }}
                                >
                                  {/* <i
                                    className="fas fa-pencil fa-fw"
                                    style={{ color: "#e40450" }}
                                  ></i> */}
                                  <Tooltip title="Editar">
                                    <i
                                      className="fas fa-xs fa-fw me-10px fa-pencil"
                                      style={{ color: "#32a932" }}
                                    ></i>
                                  </Tooltip>
                                  {/* fas fa-lg fa-fw me-10px fa-box-open */}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Clientes totales {total}
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={handlePrevClick}
                >
                  <a href="#/" className="page-link">
                    Anterior
                  </a>
                </li>
                {[...Array(pagesCount)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "" : ""
                    }`}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    <a
                      className="page-link"
                      href="#/"
                      style={{
                        backgroundColor:
                          currentPage === index + 1 ? "#348fe2" : "",
                        color: currentPage === index + 1 ? "#fff" : "",
                      }}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === pagesCount ? "disabled" : ""
                  }`}
                  onClick={handleNextClick}
                >
                  <a className="page-link" href="#/">
                    Siguiente
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
