import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";
import { useSelector } from "react-redux";

const Confirm = forwardRef((props, ref) => {
  // const { currentCustomer, loading, error } = useSelector(
  //   (state) => state.customer
  // );
  const fases = props.fases;

  const [date, setDate] = useState({ dia: "", mes: "", hora: "" });
  const [requisitos, setRequisitos] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState({});

  useEffect(() => {
    if (fases.length > 1) {
      const fecha = fases[1].day;

      const dia = capitalizeFirstLetter(
        fecha.toLocaleDateString("es-ES", {
          weekday: "long",
          day: "numeric",
        })
      );

      const mes = fecha.toLocaleDateString("es-ES", {
        month: "long",
        year: "numeric",
      });
      setDate({ hora: fases[1].hour, dia, mes });

      setRequisitos(fases[0].output);
      setCurrentCustomer(fases[0].customer);
    }
  }, [fases]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      // Aquí pones tu lógica de validación y luego retornas true o false
      return true;
    },
  }));

  return (
    <div className="mt-4">
      {fases && (
        <div>
          <h4 className="mb-2">
            Verifica que los datos y requisitos sean los correcto
          </h4>
          <em>
            Cliente: {currentCustomer?.user?.name}{" "}
            {currentCustomer?.user?.last_name}{" "}
            {currentCustomer?.user?.second_last_name}
          </em>
          <div className="row gx-1">
            <div className="row">
              <div id="formContainer">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="invoice" style={{ padding: "10px" }}>
                      <div className="invoice-header">
                        <div className="invoice-from">
                          {/* <small>from</small> */}
                          <strong>Requisitos</strong>
                          <address className="mt-5px mb-5px">
                            {requisitos &&
                              requisitos.map((requisito, index) => (
                                <div key={index}>
                                  <strong>{requisito.space}</strong>

                                  <p
                                    style={{
                                      marginLeft: "5%",
                                      marginTop: "-25px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <br />* {requisito.requirement}
                                    <br />
                                  </p>
                                </div>
                              ))}
                          </address>
                        </div>
                        <div className="invoice-to">
                          <strong>Domicilio</strong>
                          <address className="mt-5px mb-5px">
                            {currentCustomer?.street} {currentCustomer?.number},{" "}
                            {currentCustomer?.neighborhood},{" "}
                            {currentCustomer?.postalCode},{" "}
                            {currentCustomer?.state}
                            <br />
                          </address>
                        </div>
                        <div className="invoice-date">
                          <strong>Fecha de la cita</strong>
                          <div className="invoice-detail">
                            {date.dia}
                            <br />
                            {date.mes}
                            <br />
                            {date.hora} hrs
                          </div>
                        </div>
                      </div>
                      <div className="invoice-note" style={{ marginTop: "0" }}>
                        * Verifica que tus datos y requerimientos sean correctos
                        <br />
                        * Otro aviso importante
                        <br />* Segundo aviso importante que debes saber
                      </div>
                      <div className="invoice-footer">
                        <p className="text-center mb-5px fw-bold">
                          GRACIAS POR CONFIAR EN NOSOTROS
                        </p>
                        <p className="text-center">
                          <span className="me-10px">
                            <i className="fa fa-fw fa-lg fa-globe"></i>{" "}
                            ardema.com
                          </span>
                          <span className="me-10px">
                            <i className="fa fa-fw fa-lg fa-phone-volume"></i>{" "}
                            T:016-18192302
                          </span>
                          <span className="me-10px">
                            <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                            ardema@gmail.com
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="theme-panel-divider" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Confirm;
