import { Divider } from "@mui/material";
import React from "react";
import CheckboxField from "./CheckboxField";
import InputField from "./InputField";

const MaterialSelectionCustomer = ({
  formik,
  spaceName,
  disabled = false,
  originalName,
}) => {
  return (
    <>
      {originalName === "Cocina" && (
        <>
          {/* <Divider textAlign="left"> */}
          <h5>Selección de materiales para {originalName}</h5> 
          <Divider/>
        </>
      )}

      {spaceName === "Cocina_materials" && (
        <div
          className="form-group row "
          style={{
            paddingLeft: "20px",
            paddingTop: "20px",
          }}
        >
          <div className="row">
            {/* disable={disabled} */}
            <CheckboxField
              disable={disabled}
              name={`${spaceName}.cubierta`}
              value={formik.values[spaceName]?.cubierta || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.cubierta`, newValue)
              }
              error={formik.errors[spaceName]?.cubierta}
              options={[
                { value: "Sileston", label: "Sileston" },
                { value: "Dekton", label: "Dekton" },
                { value: "Xtine", label: "Xtine" },
              ]}
              label="Cubierta"
            />

            <CheckboxField
              disable={disabled}
              name={`${spaceName}.bisagras`}
              value={formik.values[spaceName]?.bisagras || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.bisagras`, newValue)
              }
              error={formik.errors[spaceName]?.bisagras}
              options={[
                { value: "Hafele", label: "Hafele" },
                { value: "Blum", label: "Blum" },
              ]}
              label="Bisagras"
            />

            {formik.values[`${spaceName}`]?.cubierta?.includes("Sileston") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.tipo_cubierta_sileston`}
                value={formik.values[`${spaceName}`]?.tipo_cubierta_sileston}
                onChange={formik.handleChange}
                label="Tipo de cubierta Sileston"
              />
            )}

            {formik.values[`${spaceName}`]?.cubierta?.includes("Dekton") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.tipo_cubierta_dekton`}
                value={formik.values[`${spaceName}`]?.tipo_cubierta_dekton}
                onChange={formik.handleChange}
                label="Tipo de cubierta Dekton"
              />
            )}
            {formik.values[`${spaceName}`]?.cubierta?.includes("Xtine") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.tipo_cubierta_xtine`}
                value={formik.values[`${spaceName}`]?.tipo_cubierta_xtine}
                onChange={formik.handleChange}
                label="Tipo de cubierta Xtine"
              />
            )}
          </div>

          <div className="row">
            <CheckboxField
              disable={disabled}
              name={`${spaceName}.corredera`}
              value={formik.values[spaceName]?.corredera || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.corredera`, newValue)
              }
              error={formik.errors[spaceName]?.corredera}
              options={[
                { value: "Hafele", label: "Hafele" },
                { value: "Blum", label: "Blum" },
              ]}
              label="Correderas"
            />

            <CheckboxField
              disable={disabled}
              name={`${spaceName}.jaladera`}
              value={formik.values[spaceName]?.jaladera || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.jaladera`, newValue)
              }
              error={formik.errors[spaceName]?.jaladera}
              options={[
                { value: "Debajo", label: "Debajo" },
                { value: "Otro", label: "Otro" },
              ]}
              label="Jaladera"
            />
            {formik.values[`${spaceName}`]?.jaladera?.includes("Otro") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.jaladera_otro`}
                value={formik.values[`${spaceName}`]?.jaladera_otro}
                onChange={formik.handleChange}
                label="Jaladera (lugar)"
                placeholder={"Especificar"}
              />
            )}
          </div>
          <div className="row">
            <InputField
              disable={disabled}
              name={`${spaceName}.iluminacion`}
              value={formik.values[`${spaceName}`]?.iluminacion}
              onChange={formik.handleChange}
              error={formik.errors[spaceName]?.iluminacion}
              placeholder="En que parte va la iluminación"
              label="Ilumincaión"
            />
          </div>

          <div className="row">
            <CheckboxField
              disable={disabled}
              name={`${spaceName}.garrafon_agua`}
              value={formik.values[spaceName]?.garrafon_agua || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.garrafon_agua`, newValue)
              }
              error={formik.errors[spaceName]?.garrafon_agua}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
              label="Garrafon de agua"
            />

            <CheckboxField
              disable={disabled}
              name={`${spaceName}.basurero`}
              value={formik.values[spaceName]?.basurero || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.basurero`, newValue)
              }
              error={formik.errors[spaceName]?.basurero}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
              label="Basurero"
            />
            {formik.values[`${spaceName}`]?.basurero?.includes("Si") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.tipo_basurero`}
                value={formik.values[`${spaceName}`]?.tipo_basurero}
                onChange={formik.handleChange}
                label="Tipo de basurero"
                placeholder={"Especificar"}
              />
            )}
          </div>

          <div className="row">
            <CheckboxField
              disable={disabled}
              name={`${spaceName}.botellero`}
              value={formik.values[spaceName]?.botellero || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.botellero`, newValue)
              }
              error={formik.errors[spaceName]?.botellero}
              options={[
                { value: "Premium", label: "Premium" },
                { value: "Standard", label: "Standard" },
              ]}
              label="Botellero"
            />

            <CheckboxField
              disable={disabled}
              name={`${spaceName}.esquinero`}
              value={formik.values[spaceName]?.esquinero || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.esquinero`, newValue)
              }
              error={formik.errors[spaceName]?.esquinero}
              options={[
                { value: "Premium", label: "Premium" },
                { value: "Standard", label: "Standard" },
              ]}
              label="Esquinero"
            />
          </div>

          <div className="row">
            <CheckboxField
              disable={disabled}
              name={`${spaceName}.bajo_tarja`}
              value={formik.values[spaceName]?.bajo_tarja || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.bajo_tarja`, newValue)
              }
              error={formik.errors[spaceName]?.bajo_tarja}
              options={[
                { value: "Premium", label: "Premium" },
                { value: "Standard", label: "Standard" },
              ]}
              label="Bajo tarja"
            />

            <CheckboxField
              disable={disabled}
              name={`${spaceName}.alacena`}
              value={formik.values[spaceName]?.alacena || []}
              onChange={(newValue) =>
                formik.setFieldValue(`${spaceName}.alacena`, newValue)
              }
              error={formik.errors[spaceName]?.alacena}
              options={[
                { value: "Premium", label: "Premium" },
                { value: "Standard", label: "Standard" },
              ]}
              label="Alacena"
            />
            {formik.values[`${spaceName}`]?.alacena?.includes("Premium") && (
              <InputField
                disable={disabled}
                name={`${spaceName}.alacena_tipo`}
                value={formik.values[`${spaceName}`]?.alacena_tipo}
                onChange={formik.handleChange}
                label="Tipo de alacena"
                placeholder={"Especificar"}
              />
            )}
          </div>

          <div className="row">
            <CheckboxField
              disable={disabled}
              big={true}
              name={`${spaceName}.accesorios_cubiertos`}
              value={formik.values[spaceName]?.accesorios_cubiertos || []}
              onChange={(newValue) =>
                formik.setFieldValue(
                  `${spaceName}.accesorios_cubiertos`,
                  newValue
                )
              }
              error={formik.errors[spaceName]?.accesorios_cubiertos}
              options={[
                { value: "Si", label: "Si" },
                { value: "No", label: "No" },
              ]}
              label="Accesorios de cubiertos"
            />
          </div>
        </div>
      )}

      {/* <Divider textAlign="left"> */}
      <h5>Materiales para el interior</h5>
      <Divider/>
      <div
        className="row"
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.interior`}
          big={true}
          value={formik.values[spaceName]?.interior || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.interior`, newValue)
          }
          error={formik.errors[spaceName]?.interior}
          options={[
            { value: "MDF Melaminico", label: "MDF Melaminico" },
            { value: "Triplay", label: "Triplay" },
            { value: "MDF Enchapado", label: "MDF Enchapado" },
            { value: "Madera", label: "Madera" },
            { value: "Otro", label: "Otro" },
          ]}
          label="Interior"
        />

        {formik.values[`${spaceName}`]?.interior?.includes("Triplay") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.interior_triplay_tipo`}
            value={formik.values[`${spaceName}`]?.interior_triplay_tipo}
            onChange={formik.handleChange}
            label="Tipo de triplay"
            placeholder={"Especificar"}
          />
        )}
        {formik.values[`${spaceName}`]?.interior?.includes("MDF Enchapado") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.interior_mdf_enchapado_tipo`}
            value={formik.values[`${spaceName}`]?.interior_mdf_enchapado_tipo}
            onChange={formik.handleChange}
            label="Tipo de mdf enchapado"
            placeholder={"Especificar tipo de mdf"}
          />
        )}
        {formik.values[`${spaceName}`]?.interior?.includes("Madera") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.interior_madera_tipo`}
            value={formik.values[`${spaceName}`]?.interior_madera_tipo}
            onChange={formik.handleChange}
            label="Tipo de madera"
            placeholder={"Especificar tipo de madera"}
          />
        )}
        {formik.values[`${spaceName}`]?.interior?.includes("Otro") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.interior_otro_tipo`}
            value={formik.values[`${spaceName}`]?.interior_madera_tipo}
            onChange={formik.handleChange}
            label="Especifica el tipo de material"
            placeholder={"Especificar tipo de material"}
          />
        )}
      </div>
      {/* <Divider textAlign="left"> */}
      <h5>Materiales para la puerta</h5>
      <Divider/>

      <div
        className="row"
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.exterior`}
          big={true}
          value={formik.values[spaceName]?.exterior || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.exterior`, newValue)
          }
          error={formik.errors[spaceName]?.exterior}
          options={[
            { value: "MDF edger", label: "MDF edger" },
            { value: "MDF matt", label: "MDF matt" },
            { value: "Madera", label: "Madera" },
            { value: "MDF natural", label: "MDF natural" },
            { value: "MDF melaminado", label: "MDF melaminado" },
            { value: "Otro", label: "Otro" },
          ]}
          label="Exterior"
        />

        {formik.values[`${spaceName}`]?.exterior?.includes("MDF edger") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_mdf_edger_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_mdf_edger_tipo}
            onChange={formik.handleChange}
            label="Tipo de MDF edger"
            placeholder={"Especificar tipo de MDF edger"}
          />
        )}
        {formik.values[`${spaceName}`]?.exterior?.includes("MDF matt") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_mdf_matt_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_mdf_matt_tipo}
            onChange={formik.handleChange}
            label="Tipo de MDF matt"
            placeholder={"Especificar tipo de MDF matt"}
          />
        )}
        {formik.values[`${spaceName}`]?.exterior?.includes("Madera") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_madera_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_madera_tipo}
            onChange={formik.handleChange}
            label="Tipo de Madera"
            placeholder={"Especificar tipo de Madera"}
          />
        )}
        {formik.values[`${spaceName}`]?.exterior?.includes("MDF natural") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_mdf_natural_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_mdf_natural_tipo}
            onChange={formik.handleChange}
            label="Tipo de MDF natural"
            placeholder={"Especificar tipo de MDF natural"}
          />
        )}
        {formik.values[`${spaceName}`]?.exterior?.includes(
          "MDF melaminado"
        ) && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_mdf_melaminado_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_mdf_melaminado_tipo}
            onChange={formik.handleChange}
            label="Tipo de MDF melaminado"
            placeholder={"Especificar tipo de MDF melaminado"}
          />
        )}
        {formik.values[`${spaceName}`]?.exterior?.includes("Otro") && (
          <InputField
            disable={disabled}
            name={`${spaceName}.exterior_madera_tipo`}
            value={formik.values[`${spaceName}`]?.exterior_madera_tipo}
            onChange={formik.handleChange}
            label="Tipo de Madera"
            placeholder={"Especificar tipo de Madera"}
          />
        )}
      </div>
    </>
  );
};

export default MaterialSelectionCustomer;
