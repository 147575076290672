import React from "react";
import CheckboxField from "./CheckboxField";

const SpaceAccessoriesForm = ({ formik, spaceName, disabled = false }) => {
  return (
    <div
      className="form-group row "
      style={{
        paddingLeft: "20px",
        paddingTop: "20px",
      }}
    >
      <div className="row">
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.refrigerador`}
          value={formik.values[spaceName]?.refrigerador || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.refrigerador`, newValue)
          }
          error={formik.errors[spaceName]?.refrigerador}
          options={[
            { value: "1 Puerta", label: "1 Puerta" },
            { value: "2 Puertas", label: "2 Puertas" },
          ]}
          label="Refrigerador"
        />

        <CheckboxField
          disable={disabled}
          name={`${spaceName}.coccion`}
          value={formik.values[spaceName]?.coccion || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.coccion`, newValue)
          }
          error={formik.errors[spaceName]?.coccion}
          options={[
            { value: "Parrilla", label: "Parrilla" },
            {
              value: "Estufa fija",
              label: "Estufa fija",
            },
          ]}
          label="Cocción"
        />
      </div>

      <div
        className="row"
        style={{
          marginTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.tarja`}
          value={formik.values[spaceName]?.tarja || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.tarja`, newValue)
          }
          error={formik.errors[spaceName]?.tarja}
          options={[
            { value: "1 Tina", label: "1 Tina" },
            {
              value: "2 Tinas",
              label: "2 Tinas",
            },
          ]}
          label="Tarja"
        />

        <CheckboxField
          disable={disabled}
          name={`${spaceName}.monomando`}
          value={formik.values[spaceName]?.monomando || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.monomando`, newValue)
          }
          error={formik.errors[spaceName]?.monomando}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Monomando"
        />
      </div>

      <div
        className="row"
        style={{
          marginTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          big={true}
          name={`${spaceName}.campana`}
          value={formik.values[spaceName]?.campana || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.campana`, newValue)
          }
          error={formik.errors[spaceName]?.campana}
          options={[
            {
              value: "Decorativa",
              label: "Decorativa",
            },
            {
              value: "Empotre visible",
              label: "Empotre visible",
            },
            {
              value: "Empotre oculta",
              label: "Empotre oculta",
            },
            {
              value: "Tradicional",
              label: "Tradicional",
            },
          ]}
          label="Campana"
        />
      </div>
      <div
        className="row"
        style={{
          marginTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.lavavajillas`}
          value={formik.values[spaceName]?.lavavajillas || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.lavavajillas`, newValue)
          }
          error={formik.errors[spaceName]?.lavavajillas}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Lavavajillas"
        />

        <CheckboxField
          disable={disabled}
          name={`${spaceName}.horno_microondas`}
          value={formik.values[spaceName]?.horno_microondas || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.horno_microondas`, newValue)
          }
          error={formik.errors[spaceName]?.horno_microondas}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Horno de Microondas"
        />
      </div>

      <div
        className="row"
        style={{
          marginTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.horno_convencional`}
          value={formik.values[spaceName]?.horno_convencional || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.horno_convencional`, newValue)
          }
          error={formik.errors[spaceName]?.horno_convencional}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Horno Convencional"
        />

        <CheckboxField
          disable={disabled}
          name={`${spaceName}.cava_vino`}
          value={formik.values[spaceName]?.cava_vino || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.cava_vino`, newValue)
          }
          error={formik.errors[spaceName]?.cava_vino}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Cava Vino"
        />
      </div>

      <div
        className="row"
        style={{
          marginTop: "20px",
        }}
      >
        <CheckboxField
          disable={disabled}
          name={`${spaceName}.isla`}
          value={formik.values[spaceName]?.isla || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.isla`, newValue)
          }
          error={formik.errors[spaceName]?.isla}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Isla"
        />

        <CheckboxField
          disable={disabled}
          name={`${spaceName}.barra`}
          value={formik.values[spaceName]?.barra || []}
          onChange={(newValue) =>
            formik.setFieldValue(`${spaceName}.barra`, newValue)
          }
          error={formik.errors[spaceName]?.barra}
          options={[
            { value: "Si", label: "Si" },
            {
              value: "No",
              label: "No",
            },
          ]}
          label="Barra"
        />
      </div>

      {/* Add line break here */}
      <div className="row">
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          
        >
          Color:
        </label>
        <div className="col-lg-10 d-flex flex-column">
          <input
            disabled={disabled}
            type="text"
            name={`${spaceName}.color`}
            value={formik.values[`${spaceName}`]?.color}
            onChange={formik.handleChange}
            className={`form-control mb-2 mt-2 ${
              formik.errors[spaceName]?.color ? " border-danger is-invalid" : ""
            }`}
          />
          {formik.errors[spaceName]?.color && (
            <div className="text-danger">{formik.errors[spaceName].color}</div>
          )}
        </div>
      </div>

      {/* Add line break here */}
      <div className="row">
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          
        >
          Color Encimera
        </label>
        <div className="col-lg-10 d-flex flex-column">
          <input
            type="text"
            disabled={disabled}
            name={`${spaceName}.color_encinera`}
            value={formik.values[`${spaceName}`]?.color_encinera}
            onChange={formik.handleChange}
            className={`form-control mb-2 mt-2 ${
              formik.errors[spaceName]?.color_encinera
                ? " border-danger is-invalid"
                : ""
            }`}
          />
          {formik.errors[spaceName]?.color_encinera && (
            <div className="text-danger">
              {formik.errors[spaceName].color_encinera}
            </div>
          )}
        </div>
      </div>

      {/* Add line break here */}
      <div className="row">
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          
        >
          Otro:
        </label>
        <div className="col-lg-10 d-flex flex-column">
          <input
            disabled={disabled}
            type="text"
            name={`${spaceName}.otro`}
            value={formik.values[`${spaceName}`]?.otro}
            onChange={formik.handleChange}
            className={`form-control mb-2 mt-2 ${
              formik.errors[spaceName]?.otro ? " border-danger is-invalid" : ""
            }`}
          />
          {formik.errors[spaceName]?.otro && (
            <div className="text-danger">{formik.errors[spaceName].otro}</div>
          )}
        </div>
      </div>
      <div className="row">
        <label
          className="col-lg-2 col-form-label form-label textoresponsive "
          
        >
          Levantamiento
        </label>
        <div className="col-lg-10 flex-column">
          <textarea
            disabled={disabled}
            name={`${spaceName}.requirement`}
            onChange={formik.handleChange}
            value={formik.values[`${spaceName}`]?.requirement}
            className={`form-control ml-2 mb-2 mt-2  ${
              formik.errors[spaceName]?.requirement
                ? " border-danger form-control is-invalid"
                : ""
            }`}
          ></textarea>
          {formik.errors[spaceName]?.requirement && (
            <div className="text-danger">
              {formik.errors[spaceName].requirement}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpaceAccessoriesForm;
