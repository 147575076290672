import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Particles from "react-tsparticles";

const ErrorPage = ({ errorType, message, button, link }) => {
  const navigate = useNavigate();
  require("./error.css");

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <div className="elbody">
      <main className="container">
        <div className="content">
          <div className="header">
            <div className="header__title">
              <h1 className="header__h1">
                {errorType ? errorType : "Error 404"}
              </h1>
              <img
                className="header__ilustration"
                src="./compass.svg"
                alt="Minimalist compass color gray"
              />
            </div>
            <p className="header__text">
              {message ? message : "El contenido que buscas no fue encontrado"}
            </p>
            <a className="header__button" href={`${link ? link : "/login"}`}>
              {button ? button : "Volver al inicio"}
            </a>
          </div>
          <div className="ilustration-container">
            <img
              className="ilustration"
              src="./routes.svg"
              alt="random figures pattern"
            />
          </div>
        </div>

        <Particles
          id="tsparticles"
          options={{
            preset: "fountain",
          }}
        />
      </main>
    </div>
  );
};

export default ErrorPage;
