import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

const Marker = ({ text }) => (
  <div
    style={{
      position: "absolute",
      transform: "translate(-50%, -100%)",
    }}
  >
    <img
      src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
      alt={text}
    />
  </div>
);

function GoogleMapsComponent({ setUbication, ubication }) {
  const apiKey = process.env.REACT_APP_API_MAPS;
  const [center, setCenter] = useState({ lat: 17.0732, lng: -96.7266 });
  const [zoom, setZoom] = useState(15);

  const handleMapClick = ({ lat, lng }) => {
    setCenter({ lat, lng });
    setUbication({ lat, lng });
    setZoom(15);
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={ubication}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onClick={handleMapClick}
      >
        <Marker lat={ubication.lat} lng={ubication.lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMapsComponent;
