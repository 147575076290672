import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProject } from "../../../services/ProjectService";

const SelectSurvey = () => {
  const dispatch = useDispatch();
  const { currentProject, loading: loadingProject } = useSelector(
    (state) => state.project
  );
  const { employeeList, loading: loadingEmployee } = useSelector(
    (state) => state.employee
  );
  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      id_survey_employee: currentProject?.survey_employee?.id || "",
    },
    validationSchema: Yup.object({
      id_survey_employee: Yup.string().required(
        "Selecciona una opción válida."
      ),
    }),
    onSubmit: async (values) => {
      setEdit(false);
      await assignSurvey(values);
    },
  });

  const assignSurvey = async (values) => {
    dispatch(
      updateProject({
        id: currentProject.id,
        body: { id_survey_employee: values.id_survey_employee },
      })
    );
  };

  if (loadingEmployee || loadingProject || !currentProject) {
    return <> Cargando</>;
  }
  return (
    <>
      {edit ? (
        <div className="timeline-header">
          <div className="username">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <a href="javascript:;" style={{ maxWidth: "50%" }}>
                Asignar a un trabajador para la visita
              </a>
              <select
                className={`form-control ${
                  formik.touched.id_survey_employee &&
                  formik.errors.id_survey_employee
                    ? "is-invalid"
                    : ""
                }`}
                id="id_survey_employee"
                name="id_survey_employee"
                style={{ maxWidth: "50%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.id_survey_employee}
              >
                <option value="" disabled>
                  Seleccione una opción
                </option>

                {employeeList &&
                  employeeList.map((employe, index) => (
                    <option key={index} value={employe.id}>
                      {employe.user.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="row mt-3 justify-content-center">
              <button
                style={{ maxWidth: "40%" }}
                type="submit"
                className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                onClick={formik.handleSubmit}
              >
                Asignar
              </button>
            </div>
          </div>
        </div>
      ) : currentProject?.survey_employee ? (
        <div className="timeline-header">
          <div className="userimage">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt=""
            />
          </div>
          <div className="username">
            <a href="javascript:;">
              {currentProject.survey_employee.user.name}{" "}
              {currentProject.survey_employee.user.last_name}
              <i className="fa fa-check-circle text-blue ms-1" />
            </a>
            <div className="text-muted fs-12px">
              Arquitecto encargado de levantar los requisitos
            </div>
          </div>
          <button
            className="btn btn-white me-1 mb-1"
            onClick={() => {
              setEdit(true);
            }}
          >
            <i className="bi bi-pencil"></i>
          </button>
        </div>
      ) : (
        <div className="timeline-header">
          <div className="username">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <a href="javascript:;" style={{ maxWidth: "50%" }}>
                Asignar a un trabajador para la visita
              </a>
              <select
                className={`form-control ${
                  formik.touched.id_survey_employee &&
                  formik.errors.id_survey_employee
                    ? "is-invalid"
                    : ""
                }`}
                id="id_survey_employee"
                name="id_survey_employee"
                style={{ maxWidth: "50%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.id_survey_employee}
              >
                <option value="" disabled>
                  Seleccione una opción
                </option>

                {employeeList &&
                  employeeList.map((employe, index) => (
                    <option key={index} value={employe.id}>
                      {employe.user.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="row mt-3 justify-content-center">
              <button
                disabled={loadingProject || loadingEmployee}
                style={{ maxWidth: "40%" }}
                type="submit"
                className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                onClick={formik.handleSubmit}
              >
                Asignar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectSurvey;
