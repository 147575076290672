import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { formatDate } from "./TableApointment";

const MapApointment = ({ center, zoom, markers }) => {
  const ref = useRef(null);
  const [mapOptions, setMapOptions] = useState({
    center: center,
    zoom: zoom,
  });

  useEffect(() => {
    setMapOptions({
      ...mapOptions,
      center: center,
      zoom: 15,
    });
  }, [center]);

  useEffect(() => {
    if (ref.current ) {
      const mapita = new window.google.maps.Map(ref.current, {
        center: mapOptions.center,
        zoom: mapOptions.zoom,
        styles: mapStyles,
      });

      // addSingleMarkers({ locations: markers, map: mapita });
      // addClusterMarkers({ locations: markers, map: mapita });
      if (markers && markers.length > 0) {
        addClusterMarkers({ locations: markers, map: mapita });
      }
    }
  }, [ref, markers, mapOptions]);

  return (
    <GoogleMapsWrapper>
      <div ref={ref} style={{ height: "50vh", width: "100%" }} />
    </GoogleMapsWrapper>
  );
};

export default MapApointment;

const GoogleMapsWrapper = ({ children }) => {
  const apiKey = "AIzaSyBoktBhWGLw64r3A5N6pImj3sG0aoCjI-w";

  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={apiKey}>{children}</Wrapper>;
};

// const addSingleMarkers = ({ locations, map }) =>
//   locations.map(
//     (position) =>
//       new window.google.maps.Marker({
//         position,
//         map,
//       })
//   );

// const addSingleMarkers = ({ locations, map }) => {
//   return locations.map((position) => {
//     const marker = new window.google.maps.Marker({
//       position,
//       map,
//     });

//     // Crear un InfoWindow
//     const infoWindow = new window.google.maps.InfoWindow({
//       content: `<div><a href="/proyecto/${position.id}" target="_blank" style="color: black; text-decoration: none;">${position.description}</a></div>`,
//     });

//     // // Añadir un evento de clic al marcador
//     // marker.addListener("click", () => {
//     //   infoWindow.open(map, marker);
//     // });

//     marker.addListener("click", () => {
//       infoWindow.open({
//         anchor: marker,
//         map,
//         shouldFocus: false,
//       });
//     });

//     return marker;
//   });
// };

let currentInfoWindow = null;

const addSingleMarkers = ({ locations, map }) =>

  locations.map((position) => {
    const marker = new window.google.maps.Marker({
      position,
      map,
    });

    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div><a href="/proyecto/${position.id}" target="_blank" style="color: black; text-decoration: none;">${position.description}<br/>${formatDate(position.date)}</a></div>`,
    });

    marker.addListener("click", () => {
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
      currentInfoWindow = infoWindow;
    });

    return marker;
  });

export const addClusterMarkers = ({ locations, map }) => {
  console.log(locations)
  const markers = addSingleMarkers({ locations, map });

  new MarkerClusterer({
    markers,
    map,
    algorithm: new SuperClusterAlgorithm({ radius: 200 }),
  });
};

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  // Aquí puedes agregar más estilos según necesites
];
