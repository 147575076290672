// import { configureStore } from "@reduxjs/toolkit";
// import userSlice from "../features/userSlice";

// export const store = configureStore({
//   reducer: {
//     user: userSlice
//   },
// });

import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Importa tus reducers
import rootReducer from "../features/rootReducer";

const middlewares = [];
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});
const persistor = persistStore(store);

export { store, persistor };
