import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";

const QuoteSchema = Yup.object().shape({
  pieces: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("El nombre es requerido"),
      description: Yup.string().required("La descripción es requerida"),
      quantity: Yup.number()
        .typeError("La cantidad debe ser un número")
        .required("La cantidad es requerida"),
      price: Yup.number()
        .typeError("El precio debe ser un número")
        .required("El precio es requerido"),
    })
  ),
});

const Quote = () => {
  const { currentProject } = useSelector((state) => state.project);
  const formikRef = useRef();

  const addPiece = () => {
    if (formikRef.current) {
      const currentPieces = formikRef.current.values.pieces;
      formikRef.current.setFieldValue("pieces", [
        ...currentPieces,
        {
          name: "",
          description: "",
          quantity: "",
          price: "",
        },
      ]);
    }
  };

  return (
    <Formik
      initialValues={{
        pieces: [
          {
            name: "",
            description: "",
            quantity: "",
            price: "",
          },
        ],
      }}
      innerRef={formikRef}
      validationSchema={QuoteSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values }) => {
        const grandTotal = values.pieces.reduce(
          (acc, piece) => acc + piece.quantity * piece.price,
          0
        );

        return (
          <Form>
            <div className="timeline-item">
              <div className="timeline-time">
                <span className="date">Hoy</span>

                <span className="time">Presupuesto</span>
              </div>
              <div className="timeline-icon">
                <a href="javascript:;">&nbsp;</a>
              </div>
              <div className="timeline-content">
                <div className="timeline-header">
                  <div className="userimage">
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      alt=""
                    />
                  </div>
                  <div className="username">
                    <a href="javascript:;">
                      {currentProject?.project_manager?.user?.name}{" "}
                      {currentProject?.project_manager?.user?.last_name}
                      <i className="fa fa-check-circle text-blue ms-1" />
                    </a>
                    <div className="text-muted fs-12px">
                      Arquitecto encargado del proyecto
                    </div>
                  </div>
                </div>

                <div className="timeline-body">
                  <div className="mb-3">
                    <div className="mb-2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Presupuesto</h5>
                        <button
                          type="button"
                          className="btn btn-success h-35px w-50 "
                          style={{ maxWidth: "20%" }}
                          onClick={addPiece}
                        >
                          Otra pieza
                        </button>
                      </div>
                      <div className="invoice-content">
                        <div className="table-responsive">
                          <table className="table table-invoice">
                            <thead>
                              <tr>
                                <th width="50%">Pieza</th>
                                <th className="text-center" width="15%">
                                  Cantidad
                                </th>
                                <th className="text-center" width="15%">
                                  Precio
                                </th>
                                <th className="text-end" width="20%">
                                  Total
                                </th>
                                <th width="5%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name="pieces">
                                {({ push, remove, form }) => (
                                  <>
                                    {values.pieces.map((piece, index) => (
                                      <tr key={index}>
                                        <td>
                                          <Field
                                            name={`pieces.${index}.name`}
                                            placeholder="Nombre"
                                            className={`form-control fs-13px ${
                                              form.errors.pieces &&
                                              form.errors.pieces[index] &&
                                              form.errors.pieces[index].name &&
                                              "border-danger form-control is-invalid"
                                            }`}
                                          />
                                          <Field
                                            name={`pieces.${index}.description`}
                                            placeholder="Descripcion"
                                            className={`form-control fs-13px mt-2 ${
                                              form.errors.pieces &&
                                              form.errors.pieces[index] &&
                                              form.errors.pieces[index]
                                                .description &&
                                              "border-danger form-control is-invalid"
                                            }`}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <Field
                                            name={`pieces.${index}.quantity`}
                                            placeholder="0"
                                            min={1}
                                            type="number"
                                            className={`form-control fs-13px ${
                                              form.errors.pieces &&
                                              form.errors.pieces[index] &&
                                              form.errors.pieces[index]
                                                .quantity &&
                                              "border-danger form-control is-invalid"
                                            }`}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <Field
                                            name={`pieces.${index}.price`}
                                            placeholder="0"
                                            min={1}
                                            type="number"
                                            className={`form-control fs-13px ${
                                              form.errors.pieces &&
                                              form.errors.pieces[index] &&
                                              form.errors.pieces[index].price &&
                                              "border-danger form-control is-invalid"
                                            }`}
                                          />
                                        </td>
                                        <td className="text-end">
                                          $ {piece.quantity * piece.price} MXN
                                          <br />
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => remove(index)}
                                              style={{ marginTop: "5px" }}
                                            >
                                              Eliminar
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                              <tr>
                                <td>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    Generar presupuesto
                                  </button>
                                </td>
                                <td />
                                <td className="text-end">
                                  <strong>Total general:</strong>
                                </td>
                                <td className="text-end">$ {grandTotal} MXN</td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="row mt-3 justify-content-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Quote;
