import React, { useEffect } from "react";
import HeaderCustomer from "../CustomerDashboard/header/HeaderCustomer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../LoadingPage";
import { getProjectById } from "../../services/ProjectService";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import "lity";
import "lity/dist/lity.min.css";
import MapPicker from "../../components/Map/MapPicker";

const ProjectDetailsCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentProject, loading } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getProjectById(id));
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <HeaderCustomer active={"PROYECTOS"} />
      {/* <div style={{ padding: "40px" }}> */}
      <div
        className="profile-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="tab-content p-0" style={{ width: "70%" }}>
          <ol className="breadcrumb float-xl-end">
            <li className="breadcrumb-item">
              <a onClick={() => navigate("/")}>Inicio</a>
            </li>
            <li className="breadcrumb-item">
              <a onClick={() => navigate("/proyectos")}>Proyectos</a>
            </li>
          </ol>

          <h1 className="page-header">
            Proyecto{" "}
            <small>
              #{currentProject?.id.substring(0, 4).toUpperCase()}{" "}
              {currentProject?.space} de {currentProject?.customer?.user?.name}
            </small>
          </h1>
          <div className="timeline">
            <div className="timeline-item">
              {/* BEGIN timeline-time */}
              <div className="timeline-time">
                <span className="date">
                  {format(
                    new Date(currentProject?.createdAt || Date.now()),
                    "EEEE d 'de' MMMM",
                    {
                      locale: es,
                    }
                  )}
                </span>
                <span className="time">
                  {format(
                    new Date(currentProject?.createdAt || Date.now()),
                    "H:mm 'hrs'"
                  )}
                </span>
              </div>
              {/* END timeline-time */}
              {/* BEGIN timeline-icon */}
              <div className="timeline-icon">
                <a href="javascript:;">&nbsp;</a>
              </div>
              {/* END timeline-icon */}
              {/* BEGIN timeline-content */}
              <div className="timeline-content">
                <div className="timeline-header">
                  <div className="userimage">
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      alt=""
                    />
                  </div>
                  <div className="username">
                    <a href="javascript:;">
                      {currentProject?.customer?.user?.name}{" "}
                      {currentProject?.customer?.user?.last_name}
                      <i className="fa fa-check-circle text-blue ms-1" />
                    </a>
                    <div className="text-muted fs-12px">
                      Agendo una cita en nuestro portal
                    </div>
                  </div>
                  <button
                    className="btn btn-white me-1 mb-1"
                    onClick={() =>
                      navigate(`/proyecto/editar/${currentProject?.id}`)
                    }
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                </div>
                <div className="timeline-body">
                  <div className="mb-1">
                    <div className="mb-2">
                      Quiero que me ayuden a con mi
                      <div style={{ marginLeft: "2%", marginBottom: "2%" }} />
                      {currentProject?.spaces?.map((space) => {
                        return (
                          <>
                            <div
                              style={{ marginLeft: "2%", marginBottom: "2%" }}
                            >
                              <i
                                className="fas fa-hammer fa-fw"
                                style={{ color: "#e40450" }}
                              ></i>{" "}
                              {space.space_name.name}
                              <div style={{ marginLeft: "2%" }}>
                                <i
                                  className="fas fa-tag"
                                  style={{ color: "#2596be" }}
                                ></i>{" "}
                                {space.requirement}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {!currentProject?.customer.neighborhood ||
                    !currentProject?.customer.postalCode ||
                    !currentProject?.customer.street ||
                    !currentProject?.customer.state ? (
                      <div className="row">
                        <h5 className="col-md-6" style={{ color: "red" }}>
                          Sin informacion del domicilio
                        </h5>
                      </div>
                    ) : (
                      <div className="row">
                        <p className="col-md-6">Aqui esta mi casa: </p>
                        <div
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/?q=${currentProject?.customer?.latitude},${currentProject?.customer?.longitude}`,
                              "_blank"
                            )
                          }
                        >
                          <MapPicker
                            style={{ marginTop: "-3%", height: "200px" }}
                            onChange={() => {}}
                            disabled={true}
                            initialPosition={{
                              lat: currentProject?.customer?.latitude,
                              lng: currentProject?.customer?.longitude,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* END timeline-content */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailsCustomer;
