import React, { useEffect, useContext, useRef } from "react";
import { AppSettings } from "../../config/app-settings.js";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import "lity";
import "lity/dist/lity.min.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewProject from "./tabs/NewProject";
import Timeline from "../projects/Timeline.js";
import TimeLineSummary from "./TimeLineSummary.js";

function SummaryProject() {
  const navigate = useNavigate();

  const { currentCustomer, loading, error } = useSelector(
    (state) => state.customer
  );
  const { id, name, last_name, second_last_name } = useSelector(
    (state) => state.user
  );

  const context = useContext(AppSettings);
  let lightboxRef = useRef(null);

  useEffect(() => {
    context.handleSetAppContentClass("p-0");

    if (!lightboxRef.current) {
      lightboxRef.current = new PhotoSwipeLightbox({
        gallery: ".gallery-v2",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightboxRef.current.init();
    }

    return () => {
      context.handleSetAppContentClass("");
      if (lightboxRef.current) {
        lightboxRef.current.destroy();
        lightboxRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img src="/assets/img/user/user-13.jpg" alt="" />
            </div>
            <div className="profile-header-info">
              <h4 className="mt-0 mb-1">
                {name} {last_name} {second_last_name}
              </h4>
              <p className="mb-2">{currentCustomer?.address}</p>
              <a
                onClick={() => navigate("/mis-datos")}
                href="#"
                className="btn btn-xs btn-yellow"
              >
                Editar datos
              </a>
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <a
                href="#"
                className="nav-link "
                data-bs-toggle="tab"
                onClick={() => navigate("/")}
              >
                NUEVO
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-link active"
                onClick={() => navigate("/proyectos")}
              >
                PROYECTOS
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#/"
                className="nav-link"
                onClick={() => navigate("/mis-datos")}
              >
                DATOS
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="profile-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="tab-content p-0" style={{ width: "70%" }}>
          <TimeLineSummary />
        </div>
      </div>
    </div>
  );
}

export default SummaryProject;
