import React from "react";
import "lity";
import "lity/dist/lity.min.css";

const TimeLineSummary = () => {
  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <a href="javascript:;">Inicio</a>
        </li>
        <li className="breadcrumb-item">
          <a href="javascript:;">Proyectos</a>
        </li>
      </ol>
      {/* END breadcrumb */}
      {/* BEGIN page-header */}
      <h1 className="page-header">
        Proyectos <small>#1LKQ0 Recamara Juan</small>
      </h1>
      {/* END page-header */}
      {/* BEGIN timeline */}
      <div className="timeline">
        {/* BEGIN timeline-item */}
        <div className="timeline-item">
          {/* BEGIN timeline-time */}
          <div className="timeline-time">
            <span className="date">Miercoles</span>
            <span className="time">09:20</span>
          </div>
          {/* END timeline-time */}
          {/* BEGIN timeline-icon */}
          <div className="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          {/* END timeline-icon */}
          {/* BEGIN timeline-content */}
          <div className="timeline-content">
            {/* BEGIN timeline-header */}
            <div className="timeline-header">
              <div className="userimage">
                <img src="assets/img/user/user-1.jpg" alt="" />
              </div>
              <div className="username">
                <a href="javascript:;">
                  Pepe Perez <i className="fa fa-check-circle text-blue ms-1" />
                </a>
                <div className="text-muted fs-12px">
                  Agendo una cita en nuestro portal
                </div>
              </div>
            </div>
            {/* END timeline-header */}
            {/* BEGIN timeline-body */}
            <div className="timeline-body">
              {/* timeline-post */}
              <div className="mb-3">
                <div className="mb-2">
                  Necesito remodelar la habitacion de mi hijo <br />
                  Quiero que tengan en cuenta que necesito
                  <ul>
                    <li>1 Pantalla de 80 in</li>
                    <li>Espacio para 3 consolas</li>
                  </ul>
                  Aqui esta mi casa
                </div>
                <div className="ratio ratio-21x9">
                  <iframe
                    title="location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.5242683789525!2d-84.36843278503017!3d33.772957280682846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50410c04ff6d9%3A0x2710fc547d72ec83!2sTwitter%20Headquarters!5e0!3m2!1sen!2smy!4v1623766084359!5m2!1sen!2smy"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            {/* END timeline-body */}
          </div>
          {/* END timeline-content */}
        </div>
        <div className="timeline-item">
          <div className="timeline-icon">
            <a href="#/">&nbsp;</a>
          </div>
          <div className="timeline-content">
            <div className="timeline-body">
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                >
                  <span className="visually-hidden">
                    En espera de la visita de nuestro arquitecto
                  </span>
                </div>
                En espera de la visita de nuestro arquitecto
              </div>
            </div>
          </div>
        </div>
        {/* END timeline-item */}
      </div>
      {/* END timeline */}
    </div>
  );
};

export default TimeLineSummary;
