import React from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { es } from "date-fns/locale";

const MaterialsView = () => {
  const { currentProject } = useSelector((state) => state.project);

  return (
    <div className="timeline-item">
      <div className="timeline-time">
        <span className="date">
          {format(
            new Date(currentProject.phases[1].createdAt || Date.now()),
            "EEEE d 'de' MMMM",
            {
              locale: es,
            }
          )}
        </span>
        <span className="date">
          {format(
            new Date(currentProject.phases[1].createdAt || Date.now()),
            "H:mm 'hrs'"
          )}
        </span>
        <span className="time">Selección de materiales</span>
      </div>
      <div className="timeline-icon">
        <a href="javascript:;">&nbsp;</a>
      </div>
      <div className="timeline-content">
        <div className="timeline-header">
          <div className="userimage">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt=""
            />
          </div>
          <div className="username">
            <a href="javascript:;">
              {currentProject?.project_manager?.user?.name}{" "}
              {currentProject?.project_manager?.user?.last_name}
              <i className="fa fa-check-circle text-blue ms-1" />
            </a>
            <div className="text-muted fs-12px">
              Arquitecto encargado del proyecto
            </div>
          </div>
        </div>

        <div className="timeline-body">
          <div className="mb-3">
            <div className="mb-2">
              <h5>{currentProject.phases[1].name}</h5>

              <textarea
                name="description"
                className="form-control"
                rows="3"
                placeholder="Coloca los materiales que se van a ocupar"
                value={currentProject.phases[1].description}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialsView;
