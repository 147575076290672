import React, { useState } from "react";
import { useSelector } from "react-redux";
import S3Service from "../../../services/FileService";
import Loading from "../../../components/Loading";
import LoadingPage from "../../LoadingPage";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SurveyData = () => {
  const { currentProject } = useSelector((state) => state.project);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fileIconMappings = {
    jpg: () => <i className="fas fa-image h3 d-block"></i>,
    png: () => <i className="fas fa-image h3 d-block"></i>,
    jpeg: () => <i className="fas fa-image h3 d-block"></i>,
    pdf: () => <i className="fas fa-file-pdf h3 d-block"></i>,
    docx: () => <i className="fas fa-file-word h3 d-block"></i>,
    doc: () => <i className="fas fa-file-word h3 d-block"></i>,
    xlsx: () => <i className="fas fa-file-excel h3 d-block"></i>,
    xls: () => <i className="fas fa-file-excel h3 d-block"></i>,
    default: () => <i className="fas fa-file h3 d-block"></i>,
  };

  const renderPreviews = (values) => {
    return values.map((file, index) => {
      const fileExtension = file.extension;
      const FileIconComponent =
        fileIconMappings[fileExtension] || fileIconMappings.default;
      return (
        <div
          // className="alert alert-info alert-dismissible fade show h-100 mb-0"
          key={index}
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          <div className="col-1">
            <FileIconComponent />
          </div>
          <div className="col-10" style={{ display: "flex" }}>
            <p
              className="text-center text-gray-600"
              style={{ margin: "0px", marginLeft: "1%" }}
            >
              {file.name}.{file.extension}
            </p>
          </div>
          <div className="col-1">
            <button
              className="btn btn-success"
              onClick={() => downloadFile(file.id)}
            >
              <i className="fas fa-eye"></i>
            </button>
          </div>
        </div>
      );
    });
  };

  const downloadFile = async (id) => {
    setLoading(true);
    try {
      const response = await S3Service.getFile(id);
      const downloadUrl = response.url;

      // Crear un elemento <a> para disparar la descarga
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = response.name + "." + response.extension;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="timeline-body"
        onClick={() => navigate(`/proyecto/requerimientos-detalle/${id}`)}
      >
        {loading && <LoadingPage />}
        <div className="mb-3">
          <div className="mb-2">
            <h5>
              {currentProject.phases[0].name} a la casa de{" "}
              {currentProject.customer.user.name}
            </h5>
            <p className="text-gray-600">
              Presiona aquí para ver los detalles de la visita
            </p>
          </div>
          <div className="row gx-1">
            <div className="col-12">
              {renderPreviews(currentProject.phases[0].files)}
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Detalles de la visita
          </Typography>
          {currentProject.spaces.map((record, index) => {
            return (
              <>
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                  Espacio: {record.space_name.name}
                  {/* {JSON.stringify(record)} */}
                </Typography>
                <Typography
                  id="keep-mounted-modal-description"
                  sx={{ mt: 2 }}
                  variant="body2"
                  gutterBottom
                >
                  Espacio: {record.space_name.name}
                  {/* {JSON.stringify(record)} */}
                </Typography>
              </>
            );
          })}
        </Box>
      </Modal>
    </>
  );
};

export default SurveyData;
