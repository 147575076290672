import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { validateToken } from "../../services/CustomerService";
import { setTokenCustomer } from "../../features/userSlice";
import ErrorPage from "../error/ErrorPage";

const ValidateToken = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const { error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(validateToken({ token }));
  }, []);

  if (error)
    return (
      <ErrorPage
        message={error}
        button={"Solicitar token"}
        link={"/solicitar-token"}
      />
    );

  return (
    <div>
      ValidateToken
      {/* <ErrorPage /> */}
    </div>
  );
};

export default ValidateToken;
