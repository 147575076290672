import React from "react";
import InputField from "./InputField";
import RadioField from "./RadioField";
import { Chip, Divider } from "@mui/material";

const SpaceForm = ({ formik, spaceName }) => {
  return (
    <>
      {/* <Divider textAlign="left"> */}
        <h5>Selección de materiales para el interior</h5>
      <Divider/>

      <div className="row">
        <RadioField
          label={"Interior"}
          name={`${spaceName}.interior`}
          value={formik.values[`${spaceName}.interior`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.interior}
          options={[
            { label: "MDF Melaminico", value: "MDF Melaminico" },
            { label: "Triplay", value: "Triplay" },
            { label: "MDF Enchapado", value: "MDF Enchapado" },
            { label: "Madera", value: "Madera" },
            { label: "Otro", value: "Otro" },
          ]}
          big={true}
        />
      </div>
      {(formik.values[`${spaceName}`]?.interior === "Triplay" ||
        formik.values[`${spaceName}`]?.interior === "MDF Enchapado" ||
        formik.values[`${spaceName}`]?.interior === "Otro" ||
        formik.values[`${spaceName}`]?.interior === "Madera") && (
        <InputField
          name={`${spaceName}.tipo_interior`}
          value={formik.values[`${spaceName}.tipo_interior`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.tipo_interior}
          label="Tipo de material"
        />
      )}

      {/* <Divider textAlign="left"> */}
        <h5>Selección de materiales para la puerta</h5>
      <Divider/>

      <div className="row">
        <RadioField
          label={"Puertas"}
          name={`${spaceName}.puerta`}
          value={formik.values[`${spaceName}.puerta`]}
          onChange={formik.handleChange}
          error={formik.errors[spaceName]?.puerta}
          options={[
            { label: "MDF edger", value: "MDF edger" },
            { label: "MDF matt", value: "MDF matt" },
            { label: "Madera", value: "Madera" },
            { label: "MDF natural", value: "MDF natural" },
            { label: "MDF melaminado", value: "MDF melaminado" },
            { label: "Otro", value: "Otro" },
          ]}
          big={true}
        />
      </div>
      {(formik.values[`${spaceName}`]?.puerta === "MDF edger" ||
        formik.values[`${spaceName}`]?.puerta === "MDF matt" ||
        formik.values[`${spaceName}`]?.puerta === "Madera" ||
        formik.values[`${spaceName}`]?.puerta === "MDF natural" ||
        formik.values[`${spaceName}`]?.puerta === "MDF melaminado" ||
        formik.values[`${spaceName}`]?.puerta === "Otro") && (
        <InputField
          name={`${spaceName}.tipo_puerta`}
          value={formik.values[`${spaceName}.tipo_puerta`]}
          onChange={formik.handleChange}
          placeholder={"Especifica color o material"}
          error={formik.errors[spaceName]?.tipo_puerta}
          label="Especifica"
        />
      )}
    </>
  );
};

export default SpaceForm;
