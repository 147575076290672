import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import NewClientModal from "./NewClientModal";
import { useGetUserByName } from "../../services/CustomerService";
import { useSelector } from "react-redux";

const SelectUserDropdown = ({ setCustomer }) => {
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [options, setOptions] = useState([]);

  const { currentCustomer } = useSelector((state) => state.customer);

  useEffect(() => {
    if (!currentCustomer) {
      setSelectedUser(null);
      setInputValue("");
    }
    if (currentCustomer) {
      setSelectedUser(currentCustomer);
      setInputValue(
        `${currentCustomer.user.name} ${currentCustomer.user.last_name || ""} ${
          currentCustomer.user.second_last_name || ""
        }`
      );
    }
  }, [currentCustomer]);

  useEffect(() => {
    setCustomer(selectedUser);
  }, [selectedUser]);

  const { data: users, refetch: refetchUsers } = useGetUserByName(inputValue);

  useEffect(() => {
    if (inputValue) {
    }
    refetchUsers();
  }, [inputValue, refetchUsers]);

  useEffect(() => {
    if (users) {
      const newOptions = users.map((user) => ({
        label: `${user.user.name} ${user?.user?.last_name || ""} ${
          user?.user?.second_last_name || ""
        }`,
        id: user.id,
        ...user,
      }));
      // Agrega la opción para crear un nuevo cliente
      newOptions.unshift({ label: "Crear cliente", street: " " });
      setOptions(newOptions);
    }
  }, [users]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleOptionSelected = (event, newValue) => {
    setSelectedUser(newValue);
    if (newValue && newValue.label === "Crear cliente") {
      setOpenModal(true);
    } else {
      setInputValue(newValue ? newValue.label : "");
    }
  };

  return (
    <>
      <Autocomplete
        freeSolo
        fullWidth
        size="small"
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={options}
        getOptionDisabled={(option) => !option.street}
        getOptionLabel={(option) => option?.label || ""}
        onChange={handleOptionSelected}
        renderInput={(params) => (
          <TextField {...params} label="Buscar usuario" variant="outlined" />
        )}
      />

      <NewClientModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        setCustomer={setCustomer}
        setInputValue={setInputValue}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
};

export default SelectUserDropdown;
