import React from "react";
import "./loading.css";
const LoadingPage = ({ tittle = "Cargando" }) => {
  return (
    <main>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <div class="drawing" id="loading">
          <div class="loading-dot"></div>
        </div>
      </div>
    </main>
  );
};

export default LoadingPage;
