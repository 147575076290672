import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getPaginatedByCustomer } from "../../services/ProjectService";
import TabTable from "./../projects/table/TabTable";
import DynamicSkeletonTable from "../../components/skeleton/DynamicSkeletonTable";
// import RowProject from "../../projects/table/RowProject";
import RowProject from "./../projects/table/RowProject";
import Pagination from "./../projects/table/Pagination";
import { getCustomer } from "../../services/CustomerService";
import "photoswipe/style.css";
import "lity";
import "lity/dist/lity.min.css";
import HeaderCustomer from "../CustomerDashboard/header/HeaderCustomer.js";

const ProyectByUser = () => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const { projectList, loading, error, total } = useSelector(
    (state) => state.project
  );
  const { currentCustomer, loading: loadingCustomer } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    if (currentCustomer) {
      dispatch(getCustomer(currentCustomer?.id));
    }
  }, [currentCustomer]);

  useEffect(() => {
    dispatch(
      getPaginatedByCustomer({
        limit: 10,
        offset: 0,
        customer_id: { customer_id: currentCustomer.id },
      })
    );
  }, [limit, offset, dispatch]);

  const pagesCount = Math.ceil(total / limit);
  const currentPage = offset / limit + 1;

  const handlePageClick = (pageNum) => {
    setOffset((pageNum - 1) * limit);
  };

  const handlePrevClick = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setOffset(offset + limit);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <HeaderCustomer active={"PROYECTOS"} />
      <div
        className="profile-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="tab-content p-0" style={{ width: "80%" }}>
          <div>
            <div className="d-flex align-items-center mb-3">
              <div>
                <h1 className="page-header mb-0">Proyectos</h1>
                <small className="text-muted">
                  {currentCustomer?.user?.name}{" "}
                  {currentCustomer?.user?.last_name}{" "}
                  {currentCustomer?.user?.second_last_name}
                </small>
              </div>
            </div>

            <div className="card border-0">
              <div className="tab-content p-3">
                <div className="tab-pane fade show active" id="allTab">
                  <TabTable disabledTab={true} />

                  {loading && loadingCustomer && projectList.length === 0 ? (
                    <DynamicSkeletonTable
                      columns={[
                        "No",
                        "Nombre",
                        "Correo",
                        "Número de teléfono",
                        "Domicilio",
                        "Contactado por",
                      ]}
                    />
                  ) : (
                    <div className="table-responsive mb-3">
                      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Espacio</th>
                            <th>Cliente</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Encargado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectList &&
                            projectList.map((project) => (
                              <RowProject key={project.id} project={project} />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <Pagination
                    total={total}
                    currentPage={currentPage}
                    pagesCount={pagesCount}
                    handlePageClick={handlePageClick}
                    handlePrevClick={handlePrevClick}
                    handleNextClick={handleNextClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProyectByUser;
