import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProject } from "../../../services/ProjectService";
import Dropzone from "../../../components/dropzone/Dropzone";
import MaterialSelection from "./MaterialSelection";
import { useNavigate, useParams } from "react-router-dom";

const AssignProjectManager = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentProject, loading: loadingProject } = useSelector(
    (state) => state.project
  );
  const { employeeList, loading: loadingEmployee } = useSelector(
    (state) => state.employee
  );
  const [edit, setEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      id_project_manager: currentProject?.project_manager?.id || "",
      files: [],
    },
    validationSchema: Yup.object({
      id_project_manager: Yup.string().required(
        "Selecciona una opción válida."
      ),
    }),
    onSubmit: async (values) => {
      setEdit(false);
      await assingProjectManager(values);
    },
  });

  const assingProjectManager = async (values) => {
    dispatch(
      updateProject({
        id: currentProject.id,
        body: { id_project_manager: values.id_project_manager },
      })
    );
  };

  if (loadingEmployee || loadingProject || !currentProject) {
    return <> Cargando</>;
  }

  const onFilesAdded = (acceptedFiles) => {
    formik.setFieldValue("files", [...formik.values.files, ...acceptedFiles]);
  };

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fileIconMappings = {
    jpg: () => <i className="fas fa-image h3 d-block"></i>,
    png: () => <i className="fas fa-image h3 d-block"></i>,
    jpeg: () => <i className="fas fa-image h3 d-block"></i>,
    pdf: () => <i className="fas fa-file-pdf h3 d-block"></i>,
    docx: () => <i className="fas fa-file-word h3 d-block"></i>,
    doc: () => <i className="fas fa-file-word h3 d-block"></i>,
    xlsx: () => <i className="fas fa-file-excel h3 d-block"></i>,
    xls: () => <i className="fas fa-file-excel h3 d-block"></i>,
    default: () => <i className="fas fa-file h3 d-block"></i>,
  };

  const renderPreviews = () => {
    return formik.values.files.map((file, index) => {
      const fileExtension = getFileExtension(file.name).toLowerCase();
      const FileIconComponent =
        fileIconMappings[fileExtension] || fileIconMappings.default;

      return (
        <div
          // className="alert alert-info alert-dismissible fade show h-100 mb-0"
          key={index}
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          <div className="col-1">
            <FileIconComponent />
          </div>
          <div className="col-10" style={{ display: "flex" }}>
            <p
              className="text-center text-gray-600"
              style={{ margin: "0px", marginLeft: "1%" }}
            >
              {file.name}
            </p>
          </div>
          <div className="col-1">
            <button
              className="btn btn-danger"
              onClick={() => handleFileRemove(index)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      );
    });
  };

  const handleFileRemove = (indexToRemove) => {
    const updatedFiles = formik.values.files.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("files", updatedFiles);
  };

  return (
    <div className="timeline-item">
      <div className="timeline-time">
        <span className="date">Hoy</span>
        <span className="time">Selección de materiales</span>
      </div>
      <div className="timeline-icon">
        <a href="javascript:;">&nbsp;</a>
      </div>
      <div className="timeline-content">
        <>
          {edit ? (
            <div className="timeline-header">
              <div className="username">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <a href="javascript:;" style={{ maxWidth: "50%" }}>
                    Asignar a un trabajador para ser el encargado del proyecto
                  </a>
                  <select
                    className={`form-control ${
                      formik.touched.id_project_manager &&
                      formik.errors.id_project_manager
                        ? "is-invalid"
                        : ""
                    }`}
                    id="id_project_manager"
                    name="id_project_manager"
                    style={{ maxWidth: "50%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id_project_manager}
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>

                    {employeeList &&
                      employeeList.map((employe, index) => (
                        <option key={index} value={employe.id}>
                          {employe.user.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="row mt-3 justify-content-center">
                  <button
                    style={{ maxWidth: "40%" }}
                    type="submit"
                    className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                    onClick={formik.handleSubmit}
                  >
                    Asignar
                  </button>
                </div>
              </div>
            </div>
          ) : currentProject?.project_manager ? (
            <div className="timeline-header">
              <div className="userimage">
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  alt=""
                />
              </div>
              <div className="username">
                <a href="javascript:;">
                  {currentProject.project_manager.user.name}{" "}
                  {currentProject.project_manager.user.last_name}
                  <i className="fa fa-check-circle text-blue ms-1" />
                </a>
                <div className="text-muted fs-12px">
                  Arquitecto encargado del proyecto
                </div>
              </div>
              <button
                className="btn btn-white me-1 mb-1"
                onClick={() => {
                  setEdit(true);
                }}
              >
                <i className="bi bi-pencil"></i>
              </button>
            </div>
          ) : (
            <div className="timeline-header">
              <div className="username">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <a href="javascript:;" style={{ maxWidth: "50%" }}>
                    Asignar a un trabajador para ser el encargado del proyecto
                  </a>
                  <select
                    className={`form-control ${
                      formik.touched.id_project_manager &&
                      formik.errors.id_project_manager
                        ? "is-invalid"
                        : ""
                    }`}
                    id="id_project_manager"
                    name="id_project_manager"
                    style={{ maxWidth: "50%" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id_project_manager}
                  >
                    <option value="" disabled>
                      Seleccione una opción
                    </option>

                    {employeeList &&
                      employeeList.map((employe, index) => (
                        <option key={index} value={employe.id}>
                          {employe.user.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="row mt-3 justify-content-center">
                  <button
                    style={{ maxWidth: "40%" }}
                    type="submit"
                    className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                    onClick={formik.handleSubmit}
                  >
                    Asignar
                  </button>
                </div>
              </div>
            </div>
          )}
        </>

        {/* {currentProject?.project_manager && <MaterialSelection />} */}
        {currentProject?.project_manager && (
          <center>
            <button
              style={{ maxWidth: "40%" }}
              type="submit"
              className="btn btn-success d-block h-45px w-100 btn-lg fs-14px mb-2"
              // onClick={formik.handleSubmit}
              onClick={() => navigate(`/proyecto/seleccion-materiales/${id}`)}
            >
              Seleccionar materiales
            </button>
          </center>
        )}
      </div>
    </div>
  );
};

export default AssignProjectManager;
