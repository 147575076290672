import { Camera } from "react-camera-pro";

import React, { useState, useCallback, useRef, useEffect } from "react";
import Dropzone from "../../../components/dropzone/Dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createPhase } from "../../../services/PhaseService";
import S3Service from "../../../services/FileService";
import LoadingPage from "../../LoadingPage";
import { useNavigate, useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Las observaciones son requeridas."),
  files: Yup.array().min(1, "Se requiere al menos un archivo."),
});

const GatheringRequirements = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { currentProject } = useSelector((state) => state.project);
  const { currentPhase, loading: loadingPhase } = useSelector(
    (state) => state.phase
  );

  const [loading, setLoading] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);
  const [cameraImage, setCameraImage] = useState(null);

  const dispatch = useDispatch();
  const cameraRef = useRef(null);

  const onFilesAdded = (acceptedFiles) => {
    formik.setFieldValue("files", [...formik.values.files, ...acceptedFiles]);
    if (cameraImage) {
      formik.setFieldValue("files", [...formik.values.files, cameraImage]);
      setCameraImage(null);
    }
  };

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fileIconMappings = {
    jpg: () => <i className="fas fa-image h3 d-block"></i>,
    png: () => <i className="fas fa-image h3 d-block"></i>,
    jpeg: () => <i className="fas fa-image h3 d-block"></i>,
    pdf: () => <i className="fas fa-file-pdf h3 d-block"></i>,
    docx: () => <i className="fas fa-file-word h3 d-block"></i>,
    doc: () => <i className="fas fa-file-word h3 d-block"></i>,
    xlsx: () => <i className="fas fa-file-excel h3 d-block"></i>,
    xls: () => <i className="fas fa-file-excel h3 d-block"></i>,
    default: () => <i className="fas fa-file h3 d-block"></i>,
  };

  const formik = useFormik({
    initialValues: {
      id_project: currentProject.id,
      name: "Visita",
      description: "",
      files: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      // return;
      setLoading(true);
      const { files, ...data } = values;

      const actionResult = await dispatch(createPhase(data));
      const newPhase = actionResult.payload;

      if (newPhase && newPhase.id) {
        try {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
          }

          if (cameraImage) {
            formData.append("files", cameraImage);
          }

          const images = await S3Service.uploadFiles({
            id: newPhase.id,
            files: formData,
          });
          console.log(images);
          window.location.reload();
        } catch (error) {
          console.error("Error uploading images:", error);
        } finally {
          // setLoading(false);
        }
      } else {
        console.error("Failed to create phase");
      }
    },
  });

  const renderPreviews = () => {
    return formik.values.files.map((file, index) => {
      const fileExtension = getFileExtension(file.name).toLowerCase();
      const FileIconComponent =
        fileIconMappings[fileExtension] || fileIconMappings.default;

      return (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          <div className="col-1">
            <FileIconComponent />
          </div>
          <div className="col-10" style={{ display: "flex" }}>
            <p
              className="text-center text-gray-600"
              style={{ margin: "0px", marginLeft: "1%" }}
            >
              {file.name}
            </p>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleFileRemove(index)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      );
    });
  };

  const handleFileRemove = (indexToRemove) => {
    const updatedFiles = formik.values.files.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("files", updatedFiles);
  };

  useEffect(() => {
    // handleCameraClick();
  }, []);

  const handleCameraClick = () => {
    setCameraActive(true);
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        cameraRef.current.srcObject = stream;
        cameraRef.current.play();
      })
      .catch(handleCameraError);
  };

  const handleCameraCapture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = cameraRef.current.videoWidth;
    canvas.height = cameraRef.current.videoHeight;
    canvas.getContext("2d").drawImage(cameraRef.current, 0, 0);
    canvas.toBlob(
      (blob) => {
        const newImage = new File([blob], `camera-image-${Date.now()}.jpg`);
        formik.setFieldValue("files", [...formik.values.files, newImage]);
        // setCameraActive(false);
      },
      "image/jpeg",
      1
    );
  };

  const handleCameraError = (error) => {
    console.error("Error accessing camera:", error);
  };

  // return (
  //   <form onSubmit={formik.handleSubmit} className="timeline-body">
  //     {loading && <LoadingPage />}
  //     <div className="timeline-body">
  //       <div className="mb-3">
  //         <h5 className="mb-1">Levantamiento de requermientos</h5>
  //         <div className="mb-2">
  //           <textarea
  //             name="description"
  //             className="form-control"
  //             rows="3"
  //             placeholder="Coloca tus observaciones"
  //             onChange={formik.handleChange}
  //             value={formik.values.description}
  //           />
  //           {formik.touched.description && formik.errors.description ? (
  //             <div className="text-danger">{formik.errors.description}</div>
  //           ) : null}
  //         </div>
  //         <div className="row gx-1">
  //           <div className="col-12">
  //             <Dropzone style={{ width: "100%" }} onFilesAdded={onFilesAdded} />
  //           </div>
  //           {/* <div className="col-6">
  //             {cameraActive ? (
  //               <div style={{ position: "relative" }}>
  //                 <video
  //                   ref={cameraRef}
  //                   style={{ width: "100%", height: "auto" }}
  //                   capture="environment"
  //                   onError={handleCameraError}
  //                 />
  //                 <button
  //                   type="button"
  //                   className="btn btn-success"
  //                   style={{
  //                     position: "absolute",
  //                     bottom: "10px",
  //                     left: "50%",
  //                     transform: "translateX(-50%)",
  //                   }}
  //                   onClick={handleCameraCapture}
  //                 >
  //                   Tomar foto
  //                 </button>
  //               </div>
  //             ) : (
  //               <button
  //                 type="button"
  //                 className="btn btn-primary"
  //                 style={{ width: "100%" }}
  //                 onClick={handleCameraClick}
  //               >
  //                 Tomar foto
  //               </button>
  //             )}
  //             {cameraImage && (
  //               <div style={{ marginTop: "10px" }}>
  //                 <p className="text-center text-gray-600">
  //                   {cameraImage.name}
  //                 </p>
  //               </div>
  //             )}
  //           </div> */}
  //           <div className="col-12">{renderPreviews()}</div>
  //         </div>
  //         <div className="row mt-3 justify-content-center">
  //           <button
  //             disabled={loadingPhase || loading}
  //             style={{ maxWidth: "40%" }}
  //             type="submit"
  //             className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
  //           >
  //             Subir
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </form>
  // );

  return (
    <div className="timeline-body">
      {loading && <LoadingPage />}
      <div className="timeline-body">
        <div className="mb-3">
          <h5 className="mb-1">Levantamiento de requermientos</h5>

          <div className="row mt-3 justify-content-center">
            <button
              disabled={loadingPhase || loading}
              style={{ maxWidth: "40%" }}
              onClick={() => navigate(`/proyecto/requerimientos/${id}`)}
              className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
            >
              Subir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatheringRequirements;
