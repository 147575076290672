import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, TextField } from "@mui/material";
import Requirements from "./Requirements";
import ScheduleAgend from "./ScheduleAgend";
import Confirm from "./Confirm";
// import "./tab.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ReactNotifications, Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { createProjectFromZero } from "../../../services/ProjectService";

const NewProject = () => {
  const dispatch = useDispatch();
  const { id, rol } = useSelector((state) => state.user);
  const [alreadyLogged, setAlreadyLogged] = useState(false);

  useEffect(() => {
    if (rol === "Cliente") {
      import("./tab.css");
    }
  }, [rol]);

  const navigate = useNavigate();
  const [fases, setFases] = useState([]);
  const step1Ref = React.useRef();
  const step2Ref = React.useRef();
  const step3Ref = React.useRef();

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    favoriteColor: "",
  });

  const handleNext = () => {
    let isValid = true;

    if (activeStep === 0) {
      isValid = step1Ref.current.validate();
      if (!isValid) {
        return addNotification(
          "warning",
          "",
          "Ingresa los requisitos necesarios para tu proyecto!",
          "top-right"
        );
      }
    } else if (activeStep === 1) {
      isValid = step2Ref.current.validate();
      if (!isValid) {
        return addNotification(
          "warning",
          "",
          "Selecciona una hora para tu cita",
          "top-right"
        );
      }
    } else if (activeStep === 2) {
      isValid = step3Ref.current.validate();
    }

    if (isValid !== false) {
      // avanza al siguiente paso si isValid es verdadero.
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setFases((setFases) => [...setFases, isValid]);
    } else {
      console.log("Error: Validación no pasó.");
    }

    return false;
  };

  const handleBack = () => {
    const nuevasFases = [...fases];

    // Eliminar el último elemento de la copia
    nuevasFases.pop();

    // Actualizar el estado con la copia modificada
    setFases(nuevasFases);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <img
            src="../assets/img/user/user-12.jpg"
            width="52"
            alt=""
            className="rounded"
          />
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }
  const steps = ["Requisitos", "Agendar cita", "Confirmar datos"];

  const getStepComponent = (step) => {
    if (step === 3 && !alreadyLogged) {
      // if (step === 3) {
      console.log("Enviando a la bd");
      setAlreadyLogged(true);

      dispatch(
        createProjectFromZero({
          user_id: id,
          projects: fases[0],
          day: fases[1].day,
          hour: fases[1].hour,
        })
      );

      navigate("/proyecto-nuevo");
      // console.log({
      //   projects: fases[0],
      //   day: fases[1].day,
      //   hour: fases[1].hour,
      // });
    }
    return (
      <div>
        <div
          className={
            step === 0 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Requirements ref={step1Ref} />
        </div>
        <div
          className={
            step === 1 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <ScheduleAgend fases={fases} ref={step2Ref} />
        </div>
        <div
          className={
            step === 2 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Confirm ref={step3Ref} fases={fases} />
        </div>

        <div
          className={
            step === 3 ? "stepper-active-step" : "stepper-inactive-step"
          }
        >
          <Confirm ref={step3Ref} fases={fases} />
        </div>
      </div>
    );
  };

  return (
    <div className="tab-pane fade show active" id="profile-post">
      <ReactNotifications />
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {getStepComponent(activeStep)}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Atras
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={activeStep === 3 ? true : false}
          >
            {activeStep === steps.length - 1 ? "Agendar" : "Siguiente"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewProject;
