import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSignOutSuccess } from "../../../features/userSlice";

function DropdownProfile() {
  const dispatch = useDispatch();
  const { name, last_name } = useSelector((state) => state.user);

  const logOut = () => {
    dispatch(onSignOutSuccess());
    window.location.reload();
  };

  return (
    <div className="navbar-item navbar-user dropdown">
      <a
        href="#/"
        className="navbar-link dropdown-toggle d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <img
          src="https://static.thenounproject.com/png/3324336-200.png"
          alt=""
        />
        <span>
          <span className="d-none d-md-inline">
            {name} {last_name}
          </span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        {/*  <a href="#/" className="dropdown-item">
          Editar perfil
        </a>
        <a href="#/" className="dropdown-item d-flex align-items-center">
          Inbox
          <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
        </a>
        <a href="#/" className="dropdown-item">
          Calendar
        </a> */}
        <a href="#/" className="dropdown-item">
          Configuración
        </a>
        <div className="dropdown-divider"></div>
        <a href="#/" className="dropdown-item" onClick={() => logOut()}>
          Salir
        </a>
      </div>
    </div>
  );
}

export default DropdownProfile;
