import React, { useImperativeHandle, forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useCheckAvailability,
  useCheckAvailabilityFirst,
} from "../../../services/AppointmentService.js";

const ScheduleAgend = forwardRef((props, ref) => {
  require("../../projects/newProject/facl.css");

  const checkAvailabilityMutation = useCheckAvailability();
  const checkAvailabilityFirstMutation = useCheckAvailabilityFirst();

  const fases = props.fases;
  const [data, setData] = useState([]);
  const [customer_id, setCustomer_id] = useState(null);
  const [dates, setDates] = useState({ list: [], message: "", error: false });
  const [loading, setLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [selectedHour, setSelectedHour] = useState(null);
  const { currentCustomer } = useSelector((state) => state.customer);

  useEffect(() => {
    if (fases?.length > 0) {
      setData(fases[0]);
    }
    setCustomer_id(currentCustomer?.id);
  }, [fases]);

  const cambiarHora = (hora) => {
    setSelectedHour(hora);
  };

  const renderButton = (hour) => {
    let variant = "outlined";

    let partes = hour.split(":");
    let fecha = new Date();
    fecha.setHours(parseInt(partes[0]), parseInt(partes[1]), 0);
    fecha.setHours(fecha.getHours() + 1);
    function pad(number) {
      return number < 10 ? "0" + number : number;
    }
    let tiempoActualizado =
      pad(fecha.getHours()) + ":" + pad(fecha.getMinutes());

    const horaString = `${hour} - ${tiempoActualizado}`;

    if (selectedHour === horaString) {
      variant = "seleccionado";
    }

    return (
      <button
        variant={variant}
        className="btn btn-sm btn-white"
        style={{
          backgroundColor: `${variant === "seleccionado" ? "#348fe2" : ""}`,
        }}
        onClick={() => cambiarHora(horaString)}
      >
        {horaString}
      </button>
    );
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const [date, setDate] = useState(tomorrow); // Por default seleccionamos el día de mañana

  const generateDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startDate = new Date(2000, 0, 1);
  const disabledDates = generateDatesBetween(startDate, today);

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (date && selectedHour) {
        return {
          day: date,
          hour: selectedHour,
        };
      } else {
        return false;
      }
    },
  }));

  const handleChange = (date) => {
    console.log(date);
  };

  const obtenerFechaPorDefecto = () => {
    const manana = new Date();
    manana.setDate(manana.getDate() + 1);

    // Si mañana es sábado (6), añadir 2 días para que sea lunes
    if (manana.getDay() === 6) {
      manana.setDate(manana.getDate() + 2);
    }

    return manana;
  };

  const esFechaHabilitada = (date) => {
    return date.getDay() !== 0; // Devolver falso para domingo, de modo que esté deshabilitado
  };
  const fechaPorDefecto = obtenerFechaPorDefecto();

  const handleCheckAvailability = async () => {
    console.log({ date: new Date(date), customer_id });
    setLoading(true);
    try {
      const { citas } = await checkAvailabilityMutation.mutateAsync({
        date: new Date(date),
        customer_id,
      });
      setDates({ list: citas });
    } catch (error) {
      console.log(error);
      setDates({ message: error.message, error: true });

      if (firstTime) {
        setDate(new Date(error?.citas_cerca));
      }
      setSelectedHour(null);
    } finally {
      setLoading(false);
      setFirstTime(false);
    }

    return false;
  };

  const handleCheckAvailabilityFirst = async () => {
    setLoading(true);
    try {
      const response = await checkAvailabilityFirstMutation.mutateAsync({
        date,
        customer_id,
      });

      // console.log({ response });
      setDates({ list: response.citas });
    } catch (error) {
      console.log({ puto: error });
      // console.log(error);
      setDates({ message: error.message, error: true });
      // if (firstTime) {
      setDate(new Date(error?.citas_cerca));
      // }
      setSelectedHour(null);
    } finally {
      setLoading(false);
      setFirstTime(false);
    }

    return false;
  };

  useEffect(() => {
    if (customer_id && firstTime) {
      handleCheckAvailabilityFirst();
    } else if (customer_id) {
      handleCheckAvailability();
    }
  }, [customer_id, data, date]);

  return (
    <div>
      <br />

      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/">Inicio</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/proyectos">Proyectos</Link>
        </li>
        <li className="breadcrumb-item active">Agenda tu cita</li>
      </ol>
      <h1 className="page-header">
        Agenda tu cita{" "}
        <small>Selecciona un dia y verifica su disponibilidad</small>
      </h1>
      <div className="row">
        <div className="col-xl-6">
          <div className="h-100">
            <Panel style={{ marginTop: "2%", height: "100%" }}>
              <PanelHeader>Calendario</PanelHeader>
              <PanelBody>
                <div className="row">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Calendario
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <div className="table-responsive">
                      <DatePicker
                        inline
                        filterDate={esFechaHabilitada}
                        minDate={fechaPorDefecto}
                        selected={date}
                        onChange={(date) => {
                          setDate(new Date(date));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Horario
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0 align-middle">
                        <tbody>
                          {loading ? (
                            <>Cargando ...</>
                          ) : (
                            <>
                              {dates.error ? (
                                <>{dates.message} </>
                              ) : (
                                <>
                                  {dates?.list?.length > 0 &&
                                    dates.list.map((record, index) => (
                                      <tr key={index}>
                                        {/* {renderButton(record.time)} */}

                                        {dates.list
                                          .slice(index * 4, index * 4 + 4)
                                          .map((record) => (
                                            <td key={record.time}>
                                              {renderButton(record.time)}
                                            </td>
                                          ))}
                                      </tr>
                                    ))}
                                </>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="h-100">
            <Panel style={{ marginTop: "2%", height: "100%" }}>
              <PanelHeader>Informacion</PanelHeader>
              <PanelBody>
                <div className="row">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Nombre
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <input
                      type="text"
                      className="form-control fs-13px h-45px"
                      disabled
                      value={`${currentCustomer?.user?.name} ${currentCustomer?.user?.last_name} ${currentCustomer?.user?.second_last_name}`}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Direccion
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <input
                      type="text"
                      className="form-control fs-13px h-45px"
                      disabled
                      value={`${currentCustomer?.street} ${currentCustomer?.number}, ${currentCustomer?.neighborhood}, ${currentCustomer?.postalCode}, ${currentCustomer?.state}`}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Espacios
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <input
                      type="text"
                      className="form-control fs-13px h-45px"
                      disabled
                      value={`${data?.map((output) => `🔖 ${output.space}`)}`}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Fecha
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <input
                      type="text"
                      className="form-control fs-13px h-45px"
                      disabled
                      value={`${date?.getDate()}/${
                        date?.getMonth() + 1
                      }/${date?.getFullYear()}`}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-xl-2">
                    <label className="form-label col-form-label col-md-3">
                      Hora
                    </label>
                  </div>
                  <div className="col-xl-10">
                    <input
                      type="text"
                      className="form-control fs-13px h-45px"
                      disabled
                      value={`${
                        selectedHour ? selectedHour : "Selecciona una hora"
                      }`}
                    />
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ScheduleAgend;
