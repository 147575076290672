import React, { useState } from "react";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useNavigation } from "react-router-dom";

const TableAppointment = ({
  appointments,
  fecha,
  currentDay: botonSeleccionado,
  setCurrentDay: setBotonSeleccionado,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: botonSeleccionado ? "" : "grey",
            }}
            onClick={() => setBotonSeleccionado(true)}
          >
            {fecha.toLocaleDateString("es-ES", {
              day: "numeric",
              month: "short",
            })}
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: "10px",
              backgroundColor: !botonSeleccionado ? "" : "grey",
            }}
            onClick={() => setBotonSeleccionado(false)}
          >
            {fecha.toLocaleDateString("es-ES", { month: "long" })}
          </Button>
        </Box>

        <a href="/proyectos/nuevo" target="_blank" rel="noopener noreferrer">
          <Button variant="contained">Nueva cita</Button>
        </a>
      </Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer style={{ maxHeight: 250 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Fecha y hora</TableCell>
                <TableCell>Ubicación</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.length > 0 &&
                appointments.map((appointment) => {
                  console.log(appointment)
                  return(
                    <>
                      <TableRow key={appointment.id}>
                        <TableCell
                          style={{
                            borderLeft: `5px solid ${appointment?.color}`,
                          }}
                        >
                          {appointment?.customer?.user?.name}{" "}
                          {appointment?.customer?.user?.last_name}{" "}
                          {appointment?.customer?.user?.second_last_name}
                        </TableCell>
                        <TableCell>{formatDate(appointment?.date)}</TableCell>
                        <TableCell>
                          {appointment?.customer.street}{" "}
                          {appointment?.customer.number} Col.
                          {appointment?.customer.neighborhood}{" "}
                          {appointment?.customer?.state}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Editar">
                            <IconButton onClick={() => navigate(`/proyecto/editar/${appointment?.project[0]?.id}`)} >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default TableAppointment;

export function formatDate(dateString = new Date()) {
  const date = new Date(dateString);
  const day = date.getDate();
  const year = date.getFullYear();

  const tiempo = String(date.toISOString()).split("T")[1];
  const hora = tiempo.split(":")[0];
  const minutos = tiempo.split(":")[1];

  const monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const monthName = monthNames[date.getMonth()];

  return `${monthName}, ${day} ${year} ${hora}:${minutos} hrs`;
}
