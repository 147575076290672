import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { locale, addLocale } from "primereact/api";

addLocale("es", {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  // dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  // dayNamesMin: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  dayNamesMin: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  today: "Hoy",
  clear: "Limpiar",
  dateFormat: "dd/mm/yy",
  weekHeader: "Sm",
});

function CalendarCita() {
  const [currentDate, setCurrentDate] = useState(new Date());

  const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const getStartOfWeek = (date) => {
    const start = new Date(date);
    while (start.getDay() !== 1) {
      start.setDate(start.getDate() - 1);
    }
    return start;
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const handlePreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);

    setCurrentDate(newDate);
  };

  const startOfWeek = getStartOfWeek(currentDate);

  const getWeekOfMonth = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayOfWeek = getStartOfWeek(date);

    // Restamos el primer día del mes con el primer día de la semana
    // Dividimos por 7 para obtener la diferencia en semanas
    return Math.ceil(
      (firstDayOfWeek.getDate() - firstDayOfMonth.getDate() + 1) / 7
    );
  };

  const [date, setDate] = useState(null);

  const isBeforeToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const generateDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startDate = new Date(2000, 0, 1); // Puedes ajustar esta fecha según tus necesidades
  const disabledDates = generateDatesBetween(
    startDate,
    new Date(today.setDate(today.getDate() - 1))
  );

  // const es = ;

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: "92vh" }}>
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Cita</Link>
          </li>
        </ol>
        <h1 className="page-header">Agenda una cita</h1>
        <Panel>
          <PanelHeader>
            Selecciona un dia y consulta los horarios disponibles para tu cita
          </PanelHeader>

          <PanelBody>
            <div className="row">
              <div className="col-md-8">
                <Calendar
                  style={{ width: "100%" }}
                  value={date}
                  onChange={(e) => setDate(e.value)}
                  inline
                  disabledDates={disabledDates}
                  disabledDays={[0]} // Deshabilita los domingos
                  locale="es"
                />
              </div>

              <div className="col-md-4">
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th nowrap="true">
                          <h4>Horas disponibles</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <button
                            style={{
                              width: "50%",
                              height: "100%",
                              padding: "12px 30px",
                              margin: "5px",
                            }}
                            className="btn btn-primary"
                          >
                            <h5>9:00 - 10:00</h5>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button
                            style={{
                              width: "50%",
                              height: "100%",
                              padding: "12px 30px",
                              margin: "5px",
                            }}
                            className="btn btn-primary"
                          >
                            <h5>10:00 - 11:00</h5>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button
                            style={{
                              width: "50%",
                              height: "100%",
                              padding: "12px 30px",
                              margin: "5px",
                            }}
                            disabled
                            className="btn btn-default"
                          >
                            <h5>12:00 - 13:00</h5>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button
                            style={{
                              width: "50%",
                              height: "100%",
                              padding: "12px 30px",
                              margin: "5px",
                            }}
                            className="btn btn-primary"
                          >
                            <h5>13:00 - 14:00</h5>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; Karimnot Todos los derechos reservados 2023
      </div>
    </div>
  );
}

export default CalendarCita;

// // import "@fullcalendar/core/main.css";
// // import "@fullcalendar/daygrid/main.css";

// const CalendarCita = () => {
//   const [date, setDate] = useState(null);

//   const handleDateClick = (arg) => {
//     console.log(arg.dateStr); // Imprime el día seleccionado
//   };

//   return (
//     <Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
//   );
// };

// export default CalendarCita;
