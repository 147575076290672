import React, { useState } from "react";
import { Link } from "react-router-dom";

function Gallery() {
  const [gallery, setGallery] = useState({
    all: true,
    group1: false,
    group2: false,
    group3: false,
    group4: false,
  });

  const toggle = (value) => {
    const newGallery = {
      all: false,
      group1: false,
      group2: false,
      group3: false,
      group4: false,
    };

    switch (value) {
      case "all":
        newGallery.all = true;
        break;
      case "group1":
        newGallery.group1 = true;
        break;
      case "group2":
        newGallery.group2 = true;
        break;
      case "group3":
        newGallery.group3 = true;
        break;
      case "group4":
        newGallery.group4 = true;
        break;
      default:
        break;
    }

    setGallery(newGallery);
  };

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/galeria">Inicio</Link>
        </li>
        <li className="breadcrumb-item active">Galeria</li>
      </ol>
      <h1 className="page-header">Galeria de nuestros trabajos</h1>

      <div id="options" className="mb-3">
        <div className="d-flex flex-wrap text-nowrap mb-n1" id="filter">
          <button
            onClick={() => toggle("all")}
            className={
              "btn btn-white btn-sm border-0 me-1 mb-1 " +
              (gallery.all ? "active" : "")
            }
          >
            Todos
          </button>
          <button
            onClick={() => toggle("group1")}
            className={
              "btn btn-white btn-sm border-0 me-1 mb-1 " +
              (gallery.group1 ? "active" : "")
            }
          >
            Closets
          </button>
          <button
            onClick={() => toggle("group2")}
            className={
              "btn btn-white btn-sm border-0 me-1 mb-1 " +
              (gallery.group2 ? "active" : "")
            }
          >
            Recamaras
          </button>
          <button
            onClick={() => toggle("group3")}
            className={
              "btn btn-white btn-sm border-0 me-1 mb-1 " +
              (gallery.group3 ? "active" : "")
            }
          >
            Salas
          </button>
          <button
            onClick={() => toggle("group4")}
            className={
              "btn btn-white btn-sm border-0 me-1 mb-1 " +
              (gallery.group4 ? "active" : "")
            }
          >
            Comedores
          </button>
        </div>
      </div>
      <div id="gallery" className="gallery row gx-0">
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group1 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/1.jpg" alt="" />
              </Link>
              <p className="image-caption">#1382 - Closet</p>
            </div>
            <div className="image-info">
              <h5 className="title">Closet de Madera de Pino</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>Por</small> <Link to="/galeria">Arq. Juan</Link>
                </div>
              </div>
              <div className="desc">
                Nunc velit urna, aliquam at interdum sit amet, lacinia sit amet
                ligula. Quisque et erat eros. Aenean auctor metus in tortor
                placerat, non luctus justo blandit.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group1 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/2.jpg" alt="" />
              </Link>
              <p className="image-caption">#2343 - Madness Arch</p>
            </div>
            <div className="image-info">
              <h5 className="title">Fusce aliquet ac quam at tincidunt</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Camryn Wong</Link>
                </div>
              </div>
              <div className="desc">
                Fusce eu rutrum nisi, ut pretium mi. Sed mollis nisi sed auctor
                molestie. Vestibulum dictum pharetra leo sed euismod.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group1 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/3.jpg" alt="" />
              </Link>
              <p className="image-caption">#3452 - Scottwills Arch</p>
            </div>
            <div className="image-info">
              <h5 className="title">Etiam lobortis egestas nisl</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Lelouch Wong</Link>
                </div>
              </div>
              <div className="desc">
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia Curae; Vivamus eget ultricies arcu.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group1 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/4.jpg" alt="" />
              </Link>
              <p className="image-caption">#4123 - Scottwills Pinecone</p>
            </div>
            <div className="image-info">
              <h5 className="title">Donec mi quis volutpat ornare</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Richard Leong</Link>
                </div>
              </div>
              <div className="desc">
                Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut
                et augue luctus libero dignissim sodales, sapien consequat
                lacinia fringilla.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group2 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/5.jpg" alt="" />
              </Link>
              <p className="image-caption">#9200 Kariminal Rider</p>
            </div>
            <div className="image-info">
              <h5 className="title">Donec pretium volutpat ornare</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Derrick Wong</Link>
                </div>
              </div>
              <div className="desc">
                Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut
                et augue luctus libero, feugiat sapien consequat lacinia
                fringilla.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group3 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/6.jpg" alt="" />
              </Link>
              <p className="image-caption">#1832 Scottwills Autumn</p>
            </div>
            <div className="image-info">
              <h5 className="title">Mi quis volutpat ornare sodales</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Apple Tong</Link>
                </div>
              </div>
              <div className="desc">
                Ut et augue luctus libero dignissim sodales. Fusce feugiat
                sapien consequat lacinia fringilla. Vivamus eget ultricies arcu.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group3 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/7.jpg" alt="" />
              </Link>
              <p className="image-caption">#0229 Scottwills Autumn 2</p>
            </div>
            <div className="image-info">
              <h5 className="title">Vestibulum ante ipsum primis</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Thomas Wong</Link>
                </div>
              </div>
              <div className="desc">
                Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut
                et augue luctus libero dignissim sodales, sapien consequat
                lacinia fringilla.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group4 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/8.jpg" alt="" />
              </Link>
              <p className="image-caption">#5721 Scottwills Snow</p>
            </div>
            <div className="image-info">
              <h5 className="title">Nunc eget hendrerit nisi sodales</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">Andy Wong</Link>
                </div>
              </div>
              <div className="desc">
                Ut et augue nisi sodales luctus libero dignissim sodales. Fusce
                feugiat nisi sodales sapien consequat lacinia fringilla.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group4 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/9.jpg" alt="" />
              </Link>
              <p className="image-caption">#9921 Scottwills Riverbank</p>
            </div>
            <div className="image-info">
              <h5 className="title">Nunc eget hendrerit nisi dignissim</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">William Tan</Link>
                </div>
              </div>
              <div className="desc">
                Ut et augue luctus libero dignissim sodales. Fusce feugiat
                sapien consequat libero dignissim lacinia fringilla.
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "col-lg-3 col-md-2 " +
            (gallery.group4 || gallery.all ? "" : "d-none ")
          }
        >
          <div className="image w-100">
            <div className="image-inner">
              <Link to="/galeria">
                <img src="/assets/img/gallery/10.jpg" alt="" />
              </Link>
              <p className="image-caption">#6436 Scottwills Buss</p>
            </div>
            <div className="image-info">
              <h5 className="title">Sed mollis nisi sed auctor</h5>
              <div className="d-flex align-items-center mb-2">
                <div className="rating">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                </div>
                <div className="ms-auto">
                  <small>by</small> <Link to="/galeria">David King</Link>
                </div>
              </div>
              <div className="desc">
                Vestibulum dictum pharetra leo sed euismod. Lorem ipsum dolor
                sit amet, consectetur adipiscing feugiat sapien elit.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
