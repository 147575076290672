const InputField = ({
  name,
  value,
  onChange,
  error,
  label,
  placeholder,
  disable = false,
}) => {
  return (
    <div className="row">
      <label
        className="col-lg-2 col-form-label form-label textoresponsive "
        
      >
        {label}
      </label>
      <div className="col-lg-10 d-flex flex-column mt-2 mb-2 ">
        <input
          disabled={disable}
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder || label}
          className={`form-control ${error ? " border-danger is-invalid" : ""}`}
        />
        {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
};

export default InputField;
