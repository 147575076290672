import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({
  acceptedFiles,
  maxFiles = undefined,
  onFilesAdded,
  frase,
  error,
  styles,
}) => {
  const [files, setFiles] = useState(null);
  const onDrop = useCallback(
    (files) => {
      const filteredFiles = maxFiles ? files.slice(0, maxFiles) : files;
      onFilesAdded(filteredFiles);
      setFiles(files);
    },
    [maxFiles, onFilesAdded]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    maxFiles,
  });

  return (
    <div
      id="dropzone"
      className="mt-2 mb-2"
      style={{
        ...{
          borderColor: error ? "red" : "transparent",
          // margin: "10px",
          borderStyle: error ? "dashed" : "none",
        },
        ...styles,
      }}
    >
      <form
        action="/upload"
        className={`dropzone needsclick ${isDragActive ? "active" : ""}`}
        id="demo-upload"
        // style={{ width: "100%" }}
      >
        <div className="dz-message needsclick" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>
            Suelta archivos <b>aqui</b> o haz <b>click</b> para subirlos.
          </p>
          <p className="dz-note needsclick">{frase}</p>
        </div>
      </form>
    </div>
  );
};
export default Dropzone;
