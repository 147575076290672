import { httpClient } from "./httpClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

const prefix = "/employee";

export const createEmployee = createAsyncThunk(
  "createEmployee",
  async (values) => {
    return (await httpClient.post(`${prefix}/zero`, values)).data;
  }
);

export const getPaginatedEmployee = createAsyncThunk(
  "getPaginatedEmployee",
  async ({ limit, offset }) => {
    return (await httpClient.get(`${prefix}?limit=${limit}&offset=${offset}`))
      .data;
  }
);

export const getEmployees = createAsyncThunk("getEmployees", async () => {
  return (await httpClient.get(`${prefix}/all`)).data;
});
