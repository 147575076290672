import React, { useEffect, useContext, useRef } from "react";
import { AppSettings } from "../../config/app-settings.js";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import "lity";
import "lity/dist/lity.min.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MisDatos from "../Cita/MisDatos.js";
import HeaderCustomer from "../CustomerDashboard/header/HeaderCustomer.js";

function MyProfile() {
  const navigate = useNavigate();

  const { currentCustomer, loading, error } = useSelector(
    (state) => state.customer
  );
  const { id, name, last_name, second_last_name } = useSelector(
    (state) => state.user
  );

  const context = useContext(AppSettings);
  let lightboxRef = useRef(null);

  useEffect(() => {
    context.handleSetAppContentClass("p-0");

    if (!lightboxRef.current) {
      lightboxRef.current = new PhotoSwipeLightbox({
        gallery: ".gallery-v2",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightboxRef.current.init();
    }

    return () => {
      context.handleSetAppContentClass("");
      if (lightboxRef.current) {
        lightboxRef.current.destroy();
        lightboxRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      <HeaderCustomer active={"MIS DATOS"} />
      <div
        className="profile-content"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="tab-content p-0" style={{ width: "70%" }}>
          <MisDatos />
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
