import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { getProjectById } from "../../../services/ProjectService.js";
import LoadingPage from "../../LoadingPage.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone from "../../../components/dropzone/Dropzone";
import RequirementService from "../../../services/RequirementService.js";
import { createPhase } from "../../../services/PhaseService.js";
import S3Service from "../../../services/FileService.js";
import CameraField from "./forms/CameraField.js";
import { Chip, IconButton, Tooltip } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Divider from "@mui/material/Divider";
import SpaceAccessoriesForm from "./forms/SpaceAccessoriesForm.js";
import MaterialSelectionCustomer from "./forms/MaterialSelectionCustomer.js";
import MaterialService from "../../../services/MaterialService.js";

const UploadGatheringRequirements = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loadingUpload, setLoading] = useState(false);
  const [camara, setCamara] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);
  const { currentProject, loading, error } = useSelector(
    (state) => state.project
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectById(id));
  }, []);

  useEffect(() => {
    if (currentProject?.phases?.length > 0) {
      navigate(`/proyecto/${id}/`);
    }
  }, []);

  const formik = useFormik({
    initialValues: getInitialValues(
      currentProject ? currentProject.spaces : []
    ),
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: getValidationSchema(
      currentProject ? currentProject.spaces : []
    ),
    onSubmit: async (values) => {
      // setData(values);
      // console.log(values);
      setLoading(true);
      const { files, ...data } = values;

      const phase = {
        id_project: currentProject.id,
        name: "Visita",
        description: "",
      };

      const actionResult = await dispatch(createPhase(phase));

      const newPhase = actionResult.payload;

      try {
        for (const record of currentProject.spaces) {
          const space = record.space_name;
          const name = space.name;

          await RequirementService.create({
            ...data[name],
            space_id: record.id,
          });

          const spaceMaterial = `${name}_materials`;
          const material = {
            ...data[spaceMaterial],
            space_id: record.id,
            phase_id: newPhase.id,
            tipo: "Materiales seleccionados por el cliente",
          };
          await MaterialService.create(material);

          console.log(material);

          delete data[name];
        }
      } catch (error) {
        console.log(error);
      }

      if (newPhase && newPhase.id) {
        try {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
          }

          const images = await S3Service.uploadFiles({
            id: newPhase.id,
            files: formData,
          });
          console.log(images);
        } catch (error) {
          console.error("Error uploading images:", error);
        }
      }

      setLoading(false);
      navigate(`/proyecto/${id}/`);
    },
  });

  useEffect(() => {
    formik.setValues(
      getInitialValues(currentProject ? currentProject.spaces : [])
    );

    console.log(getInitialValues(currentProject ? currentProject.spaces : []));
  }, [currentProject]);

  if (error) return <p>Error, proyecto no encontrado</p>;
  if (loading) return <LoadingPage />;

  const onFilesAdded = (acceptedFiles) => {
    formik.setFieldValue("files", [...formik.values.files, ...acceptedFiles]);
  };

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fileIconMappings = {
    jpg: () => <i className="fas fa-image h3 d-block"></i>,
    png: () => <i className="fas fa-image h3 d-block"></i>,
    jpeg: () => <i className="fas fa-image h3 d-block"></i>,
    pdf: () => <i className="fas fa-file-pdf h3 d-block"></i>,
    docx: () => <i className="fas fa-file-word h3 d-block"></i>,
    doc: () => <i className="fas fa-file-word h3 d-block"></i>,
    xlsx: () => <i className="fas fa-file-excel h3 d-block"></i>,
    xls: () => <i className="fas fa-file-excel h3 d-block"></i>,
    default: () => <i className="fas fa-file h3 d-block"></i>,
  };

  const renderPreviews = () => {
    return formik.values.files.map((file, index) => {
      const fileExtension = getFileExtension(file.name).toLowerCase();
      const FileIconComponent =
        fileIconMappings[fileExtension] || fileIconMappings.default;

      return (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
        >
          <div className="col-1">
            <FileIconComponent />
          </div>
          <div className="col-10" style={{ display: "flex" }}>
            <p
              className="text-center text-gray-600"
              style={{ margin: "0px", marginLeft: "1%" }}
            >
              {file.name}
            </p>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleFileRemove(index)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      );
    });
  };
  const handleFileRemove = (indexToRemove) => {
    const updatedFiles = formik.values.files.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("files", updatedFiles);
  };

  return (
    <div className="h-100 d-flex flex-column">
      {loadingUpload && <LoadingPage />}
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Proyectos</Link>
          </li>
        </ol>
        <h1 className="page-header">Requerimientos del cliente en la visita</h1>
        <Panel>
          <PanelHeader>
            Sube los requerimientos de acuerdo a los espacios
          </PanelHeader>

          <PanelBody>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  {currentProject &&
                    currentProject.spaces.map((space, index) => {
                      const spaceName = space.space_name.name;
                      const spaceNameMaterial = `${spaceName}_materials`;

                      return (
                        <>
                          <div
                            style={{
                              border: "1px solid #e4e5e5",
                              marginBottom: "10px",
                              padding: "10px",
                            }}
                          >
                            <RequirementRow
                              space={space}
                              no={index}
                              name={spaceName}
                            />

                            {spaceName === "Cocina" ? (
                              <>
                                {/* <Divider textAlign="left"> */}
                                  <h5>Selección de accesorios</h5>
                                <Divider/>
                                <SpaceAccessoriesForm
                                  formik={formik}
                                  spaceName={spaceName}
                                />
                                <MaterialSelectionCustomer
                                  formik={formik}
                                  spaceName={spaceNameMaterial}
                                  originalName={spaceName}
                                />
                              </>
                            ) : (
                              <>
                                {/* <Divider textAlign="left"> */}
                                  <h5>Levantamiento del arquitecto</h5>
                                <Divider/>
                                <div
                                  className="form-group row mb-3"
                                  style={{ paddingLeft: "20px" }}
                                >
                                  <div className="row">
                                    <label
                                      className="col-lg-2 col-form-label form-label textoresponsive "
                                      style={{
                                        textAlign: "end",
                                        color: "gray",
                                      }}
                                    >
                                      Levantamiento
                                    </label>
                                    <div className="col-lg-10 flex-column">
                                      <textarea
                                        name={`${spaceName}.requirement`}
                                        onChange={formik.handleChange}
                                        className={`form-control ml-2${
                                          formik.errors[spaceName]?.requirement
                                            ? " border-danger form-control is-invalid"
                                            : ""
                                        }`}
                                      ></textarea>
                                      {formik.errors[spaceName]
                                        ?.requirement && (
                                        <div className="text-danger">
                                          {formik.errors[spaceName].requirement}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <MaterialSelectionCustomer
                                  formik={formik}
                                  spaceName={spaceNameMaterial}
                                  originalName={spaceName}
                                />
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>

              <div className="row gx-1" style={{ padding: "20px" }}>
                <div className="row">
                  <Divider textAlign="center">Archivos o fotos</Divider>
                  <div className="col-11">
                    {camara ? (
                      <CameraField onFilesAdded={onFilesAdded} />
                    ) : (
                      <Dropzone
                        style={{ width: "100%" }}
                        onFilesAdded={onFilesAdded}
                      />
                    )}
                  </div>
                  <div className="col-1 d-flex justify-content-center align-items-center">
                    <Tooltip title={camara ? "Subir archivos" : "Abrir camara"}>
                      <IconButton
                        size="large"
                        onClick={() => setCamara(!camara)}
                        color="error"
                      >
                        {camara ? (
                          <CloudUploadIcon fontSize="inherit" />
                        ) : (
                          <CameraAltIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <div className="col-12">{renderPreviews()}</div>
              </div>

              {/* {JSON.stringify(data)} */}
              <div className="row">
                <button
                  type="submit"
                  className="btn btn-success  d-block h-45px w-100 btn-lg fs-14px"
                >
                  Subir
                </button>
              </div>
            </form>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>
    </div>
  );
};

export default UploadGatheringRequirements;

export const RequirementRow = ({ space, style, no = 0, name }) => {
  return (
    <>
      {/* {no != 0 && <hr />} */}
      {/* <Divider textAlign="left"> */}
        {/* <Chip label={<h4>{name}</h4>} /> */}
        <h5>{name}</h5>
      <Divider/>
      <div
        className="form-group row mb-3 mt-3 "
        style={{ paddingLeft: "20px" }}
      >
        <div className="row">
          <label
            className="col-lg-2 col-form-label form-label textoresponsive "
            
          >
            Espacio
          </label>
          <div className="col-lg-10 d-flex">
            <input
              className="form-control ml-2"
              type="text"
              value={space.space_name.name}
              disabled
            />
          </div>
        </div>
        <div className="row mt-2">
          <label
            className="col-lg-2 col-form-label form-label textoresponsive "
            
          >
            Observaciones
          </label>
          <div className="col-lg-10 d-flex">
            <textarea
              className="form-control ml-2"
              type="text"
              value={space.requirement}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

const getValidationSchema = (spaces) => {
  let schema = {};
  spaces.forEach((space) => {
    const spaceName = space.space_name.name;
    if (spaceName === "Cocina") {
      schema[spaceName] = Yup.object({
        refrigerador: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),

        coccion: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        tarja: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),

        campana: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        monomando: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        lavavajillas: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        horno_microondas: Yup.array()
          .min(0, "¡Campo requerido!")
          .of(Yup.string()),
        horno_convencional: Yup.array()
          .min(0, "¡Campo requerido!")
          .of(Yup.string()),
        cava_vino: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        isla: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        barra: Yup.array().min(0, "¡Campo requerido!").of(Yup.string()),
        color: Yup.string(),
        color_encinera: Yup.string(),
        requirement: Yup.string().required("¡Campo requerido!"),
        otro: Yup.string(),
      });
    } else {
      schema[spaceName] = Yup.object({
        requirement: Yup.string().required("¡Campo requerido!"),
      });
    }
  });

  return Yup.object(schema);
};

export const getInitialValues = (spaces) => {
  // console.log(spaces);
  let initialValues = { files: [] };

  spaces.forEach((space) => {
    const spaceName = space.space_name.name;
    if (spaceName === "Cocina" || spaceName === "cocina") {
      initialValues[spaceName] = {
        refrigerador: [],
        coccion: [],
        tarja: [],
        campana: [],
        monomando: [],
        lavavajillas: [],
        horno_microondas: [],
        horno_convencional: [],
        cava_vino: [],
        isla: [],
        barra: [],
        color: "",
        color_encinera: "",
        otro: "",
        requirement: "",
      };
    } else {
      initialValues[spaceName] = {
        requirement: "",
      };
    }
  });

  spaces.forEach((space) => {
    const spaceName = space.space_name.name;
    const finalName = `${spaceName}_materials`;
    if (spaceName === "Cocina" || spaceName === "cocina") {
      initialValues[finalName] = {
        cubierta: [],
        bisagras: [],
        correderas: [],
        jaladera: [],
        ilumincaion: "",
        garrafon_agua: [],
        basurero: [],
        botellero: [],
        esquinero: [],
        bajo_tarja: [],
        alacena: [],
        accesorios_cubiertos: [],
        interior: [],
        exterior: [],
      };
    } else {
      initialValues[finalName] = {
        interior: [],
        exterior: [],
      };
    }
  });

  return initialValues;
};
