import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { pink } from "@mui/material/colors";

const CheckboxField = ({
  name,
  value,
  onChange,
  error,
  options,
  label,
  big,
  disable = false,
}) => {
  const handleChange = (event) => {
    if (event.target.checked) {
      onChange([...value, event.target.value]);
    } else {
      onChange(value.filter((item) => item !== event.target.value));
    }
  };

  return (
    <>
      {/* <label className={`col-lg-1 col-form-label form-label`} /> */}
      <label
        className="col-lg-2 col-form-label form-label textoresponsive "
        
      >
        {label}
      </label>

      <div className={`col-lg-${big ? "10" : "4"} d-flex flex-column`}>
        <FormGroup
          className={`ml-2 ${error ? " border-danger is-invalid" : ""}`}
          row={true}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={value.includes(option.value)}
                  onChange={handleChange}
                  disabled={disable}
                  name={name}
                  value={option.value}
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                  }}
                />
              }
              label={option.label}
              className="d-flex"
            />
          ))}
        </FormGroup>
        {error && <div className="text-danger">{error}</div>}
      </div>
    </>
  );
};

export default CheckboxField;
