import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import Dropzone from "../../components/dropzone/Dropzone";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";

import * as Yup from "yup";
import S3Service from "../../services/FileService.js";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee } from "../../services/EmployeeService.js";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  last_name: Yup.string().required("Campo requerido"),
  second_last_name: Yup.string().required("Campo requerido"),
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("Campo requerido"),
  avatar: Yup.mixed()
    .test("file", "Debes añadir una imagen", (value) => value !== null)
    .required("Sube la foto del trabajador"),
  permissions: Yup.object().test(
    "one-checkbox-selected",
    "Se requiere al menos un permiso",
    (value) => {
      return value.isAdmin || value.clients || value.projects || value.workers;
    }
  ),
});

const NewEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const { loading, error, createdEmployee } = useSelector(
    (state) => state.employee
  );

  const handleFilesAdded = (files) => {
    setFile(files[0]);
    formik.setFieldValue("avatar", files[0]);
  };

  useEffect(() => {
    if (!loading && !error && createdEmployee) {
      navigate("/trabajadores");
    }
  }, [loading, error, createdEmployee]);

  const formik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      second_last_name: "",
      email: "",
      title_job: "Arquitecto",
      rol: "Empleado",
      avatar: file,
      permissions: {
        isAdmin: false,
        clients: false,
        projects: false,
        workers: false,
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const values2 = values;
      delete values2.avatar;
      setFileLoading(true);
      try {
        let permissionsArray = [];

        if (values.permissions.clients) {
          permissionsArray.push("clientes");
        }
        if (values.permissions.projects) {
          permissionsArray.push("proyectos");
        }
        if (values.permissions.workers) {
          permissionsArray.push("trabajadores");
        }

        let objectToSend = { ...values2, permissions: permissionsArray };

        if (file) {
          const formData = new FormData();
          formData.append("file", file);

          const response = await S3Service.upload(formData);
          // avatar = response.avatar;
          // console.log(response);
          objectToSend.avatar = response.avatar;
        }

        // console.log(values.permissions);

        if (values.permissions.isAdmin) {
          objectToSend = {
            ...values,
            title_job: "Administrador",
            rol: "Administrador",
            permissions: [],
          };
        }

        console.log(objectToSend);

        dispatch(createEmployee(objectToSend));
      } catch (error) {
        console.log(error);
      } finally {
        setFileLoading(false);
      }

      // console.log(values);
    },
  });

  function handleAdminChange(event, formik) {
    const isChecked = event.target.checked;
    formik.setFieldValue("permissions.isAdmin", isChecked);
    if (isChecked) {
      formik.setFieldValue("permissions.clients", true);
      formik.setFieldValue("permissions.projects", true);
      formik.setFieldValue("permissions.workers", true);
    }
    formik.setFieldTouched("permissions", true, false); // Marca el campo como "tocado"
  }

  function handleCheckboxChange(event, formik) {
    const { name } = event.target;
    const isChecked = event.target.checked;

    formik.setFieldValue(name, isChecked);

    const allChecked = [
      "permissions.clients",
      "permissions.projects",
      "permissions.workers",
    ].every((key) => formik.values[key]);

    formik.setFieldValue("permissions.isAdmin", allChecked);
    formik.setFieldTouched("permissions", true, false); // Marca el campo como "tocado"
  }

  return (
    <div className="h-100 d-flex flex-column">
      <PerfectScrollbar
        className="app-content-padding flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/">Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/trabajadores">Trabajadores</Link>
          </li>
          <li className="breadcrumb-item active">Nuevo</li>
        </ol>
        <h1 className="page-header">Registar un nuevo trabajador</h1>
        <Panel>
          <PanelHeader>Formulario de registro</PanelHeader>
          <PanelBody>
            <p>
              Rellena el siguiente formulario para poder registrar al nuevo
              trabajador
            </p>
            <div>
              <div className="container fs-13px">
                <div className="row" style={{ margin: "1%" }}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row mb-3">
                      <label className="col-lg-3 col-form-label form-label">
                        Nombre Completo :
                      </label>
                      <div className="col-lg-3">
                        <input
                          className={`form-control ${
                            formik.touched.name && formik.errors.name
                              ? " border-danger form-control is-invalid"
                              : ""
                          }`}
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Nombre completo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3">
                        <input
                          className={`form-control ${
                            formik.touched.last_name && formik.errors.last_name
                              ? " border-danger form-control is-invalid"
                              : ""
                          }`}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Apellido paterno"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <div className="text-danger">
                            {formik.errors.last_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3">
                        <input
                          className={`form-control ${
                            formik.touched.second_last_name &&
                            formik.errors.second_last_name
                              ? " border-danger form-control is-invalid"
                              : ""
                          }`}
                          type="text"
                          id="second_last_name"
                          name="second_last_name"
                          placeholder="Apellido materno"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.second_last_name}
                        />
                        {formik.touched.second_last_name &&
                        formik.errors.second_last_name ? (
                          <div className="text-danger">
                            {formik.errors.second_last_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label className="col-lg-3 col-form-label form-label">
                        Correo electrónico :
                      </label>
                      <div className="col-lg-9 ">
                        <input
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? " border-danger form-control is-invalid"
                              : ""
                          }`}
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Correo electrónico"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label className="col-lg-3 col-form-label form-label">
                        Permisos :
                      </label>
                      <div className="col-lg-3 ">
                        <input
                          className="form-check-input"
                          style={{ backgroundColor: "gray" }}
                          type="checkbox"
                          id="isAdmin"
                          name="permissions.isAdmin"
                          checked={formik.values.permissions.isAdmin}
                          onChange={(event) => handleAdminChange(event, formik)}
                        />

                        <label
                          className="form-check-label"
                          style={{ marginLeft: "3%" }}
                          htmlFor="flexCheckDefault"
                        >
                          ¿Es administrador?
                        </label>
                      </div>

                      <div className="col-lg-2 ">
                        <input
                          className="form-check-input"
                          style={{ backgroundColor: "gray" }}
                          type="checkbox"
                          id="clients"
                          name="permissions.clients"
                          checked={formik.values.permissions.clients}
                          onChange={(event) =>
                            handleCheckboxChange(event, formik)
                          }
                          disabled={formik.values.permissions.isAdmin}
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "3%" }}
                          htmlFor="clients"
                        >
                          Clientes
                        </label>
                      </div>

                      <div className="col-lg-2 ">
                        <input
                          className="form-check-input"
                          style={{ backgroundColor: "gray" }}
                          type="checkbox"
                          id="projects"
                          name="permissions.projects"
                          checked={formik.values.permissions.projects}
                          onChange={(event) =>
                            handleCheckboxChange(event, formik)
                          }
                          disabled={formik.values.permissions.isAdmin}
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "3%" }}
                          htmlFor="projects"
                        >
                          Proyectos
                        </label>
                      </div>

                      <div className="col-lg-2 ">
                        <input
                          className="form-check-input"
                          style={{ backgroundColor: "gray" }}
                          type="checkbox"
                          id="workers"
                          name="permissions.workers"
                          checked={formik.values.permissions.workers}
                          onChange={(event) =>
                            handleCheckboxChange(event, formik)
                          }
                          disabled={formik.values.permissions.isAdmin}
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "3%" }}
                          htmlFor="workers"
                        >
                          Trabajadores
                        </label>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 "> </div>
                        <div className="col-lg-9 ">
                          {(formik.touched.permissions?.isAdmin ||
                            formik.touched.permissions?.clients ||
                            formik.touched.permissions?.projects ||
                            formik.touched.permissions?.workers) &&
                          formik.errors.permissions ? (
                            <div className="text-danger">
                              {formik.errors.permissions}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating mb-20px col-md-10">
                        <Dropzone
                          acceptedFiles={[
                            "image/png",
                            "image/jpeg",
                            "image/jpg",
                          ]}
                          maxFiles={1}
                          onFilesAdded={handleFilesAdded}
                          frase={
                            "Si no tienes una imagen pondremo una por default"
                          }
                          error={formik.errors.avatar}
                        />
                      </div>
                      <div className="form-floating mb-20px col-md-2 d-flex align-items-center justify-content-center">
                        {file ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Preview`}
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2ug8ZaPulAPsPhZ5M3d5rPG9TZtxPW0qaslaX7Ts&s"
                            }
                            alt={`Preview`}
                            className="img-fluid"
                          />
                        )}
                      </div>
                      {formik.touched.avatar && formik.errors.avatar ? (
                        <div
                          className="text-danger"
                          style={{ marginTop: "-2%" }}
                        >
                          {formik.errors.avatar}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label form-label">
                        &nbsp;
                      </label>
                      <div className="col-lg-8 d-flex justify-content-between">
                        {/* <button className="btn btn-danger" type="button">
                    Cancelar
                  </button> */}
                        <span></span>
                        {/* <Link to="/agendar-cita"> */}
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled={fileLoading || loading}
                        >
                          Guardar
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </PanelBody>
          <div></div>
        </Panel>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; Karimnot Todos los derechos reservados 2023
      </div>
    </div>
  );
};

export default NewEmployee;
