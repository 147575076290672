import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProjectById } from "../../../services/ProjectService";
import LoadingPage from "../../LoadingPage";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RequirementRow } from "./UploadGatheringRequirements";
import { Divider, Radio, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
import SpaceAccessoriesForm from "./forms/SpaceAccessoriesForm";
import MaterialSelectionCustomer from "./forms/MaterialSelectionCustomer";

const DetailsSurveyData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentProject, loading, error } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    dispatch(getProjectById(id));
  }, []);

  const formik = useFormik({
    // initialValues: getInitialValues(
    //   currentProject ? currentProject.spaces : []
    // ),
    initialValues: getInitialValues(
      currentProject ? { spaces: currentProject.spaces, currentProject } : {}
    ),

    onSubmit: async (values) => {
      // console.log(values);
    },
  });

  useEffect(() => {
    if (currentProject) {
      formik.setValues(
        getInitialValues({ spaces: currentProject.spaces, currentProject })
      );
    }
  }, [currentProject]);

  if (error) return <p>Error, proyecto no encontrado</p>;
  if (loading) return <LoadingPage />;

  return (
    <div className="h-100 d-flex flex-column">
      <PerfectScrollbar
        className="flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link>Inicio</Link>
          </li>
          <li className="breadcrumb-item">
            <Link>Proyectos</Link>
          </li>
        </ol>
        <h1 className="page-header">
          Detalles de la visita a la casa de {currentProject.customer.user.name}
        </h1>
        <Panel>
          <PanelHeader>Detalles de la visita</PanelHeader>
          <PanelBody>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {currentProject &&
                currentProject.spaces.map((space) => {
                  const spaceName = space.space_name.name;
                  const spaceNameMaterial = `${spaceName}_materials`;
                  return (
                    <>
                      <RequirementRow space={space} name={spaceName} />

                      {spaceName === "Cocina" ? (
                        <>
                          <Divider textAlign="center">
                            Selección de accesorios
                          </Divider>
                          <SpaceAccessoriesForm
                            formik={formik}
                            spaceName={spaceName}
                            disabled={true}
                          />
                          <MaterialSelectionCustomer
                            disabled={true}
                            formik={formik}
                            spaceName={spaceNameMaterial}
                            originalName={spaceName}
                          />
                        </>
                      ) : (
                        <>
                          {/* <Divider textAlign="left"> */}
                            <h5>Levantamiento del arquitecto</h5>
                          <Divider/>
                          <div
                            className="form-group row mb-3"
                            style={{ paddingLeft: "20px" }}
                          >
                            <div className="row">
                              <label
                                className="col-lg-2 col-form-label form-label textoresponsive "
                                style={{
                                  textAlign: "end",
                                  color: "gray",
                                }}
                              >
                                Levantamiento
                              </label>
                              <div className="col-lg-10 flex-column">
                                <textarea
                                  name={`${spaceName}.requirement`}
                                  onChange={formik.handleChange}
                                  value={
                                    formik.values[`${spaceName}`]?.requirement
                                  }
                                  disabled
                                  className={`form-control ml-2${
                                    formik.errors[spaceName]?.requirement
                                      ? " border-danger form-control is-invalid"
                                      : ""
                                  }`}
                                ></textarea>
                                {formik.errors[spaceName]?.requirement && (
                                  <div className="text-danger">
                                    {formik.errors[spaceName].requirement}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <MaterialSelectionCustomer
                            disabled={true}
                            formik={formik}
                            spaceName={spaceNameMaterial}
                            originalName={spaceName}
                          />
                        </>
                      )}

                      {/* {spaceName === "Cocina" ? ( */}

                      {/* <div className="row" style={{ paddingLeft: "20px" }}>
                      <label
                        className="col-lg-2 col-form-label form-label textoresponsive "
                        style={{  textAlign:"rigth" }}
                      >
                        Ver la selección del cliente
                      </label>
                    </div> */}

                      <div
                        className="form-group row mb-3"
                        style={{ paddingLeft: "20px" }}
                      >
                        <div className="row">
                          <label
                            className="col-lg-2 col-form-label form-label textoresponsive "
                            
                          >
                            Levantamiento
                          </label>
                          <div className="col-lg-10 flex-column">
                            <textarea
                              disabled
                              className={`form-control ml-2$`}
                              value={space.requirements[0].requirement}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              <div className="form-group row mb-3">
                <div className="col-lg-4 flex-column"></div>
                <div className="col-lg-4 flex-column">
                  <button
                    onClick={() => navigate(`/proyecto/${id}/`)}
                    className="btn btn-success  d-block h-45px w-100 btn-lg fs-14px"
                  >
                    Regresar
                  </button>
                </div>
                <div className="col-lg-4 flex-column"></div>
              </div>
            </form>
          </PanelBody>
        </Panel>
      </PerfectScrollbar>
    </div>
  );
};

export default DetailsSurveyData;

export const getInitialValues = ({ spaces, currentProject }) => {
  let initialValues = { files: [] };

  spaces?.forEach((space) => {
    const spaceName = space?.space_name.name;
    const requirements = space?.requirements[0];
    if (spaceName === "Cocina" || spaceName === "cocina") {
      initialValues[spaceName] = {
        ...requirements,
      };
    } else {
      initialValues[spaceName] = {
        ...requirements,
      };
    }
  });

  spaces?.forEach((space) => {
    const spaceName = space.space_name.name;
    const requirements = currentProject?.phases[0].materials[0];

    const finalName = `${spaceName}_materials`;
    if (spaceName === "Cocina" || spaceName === "cocina") {
      initialValues[finalName] = {
        ...requirements,
      };
    } else {
      initialValues[finalName] = {
        ...requirements,
      };
    }
  });

  return initialValues;
};
